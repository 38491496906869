:root {
  --primary-color: rgb(28, 157, 135);
  --dark-color: rgb(20, 44, 31);
  --paragraph-color: rgb(68, 85, 76);
  --border-color: rgb(209, 219, 217);
  --placeholder-color: rgb(240, 244, 243);
  --background-color: rgb(250, 250, 250);
  --secondary-color: rgb(46, 207, 150);
  --light-color: rgb(238, 238, 238);
  --paragraph-alt-color: rgb(165, 181, 175);
  --border-alt-color: rgb(124, 232, 214);
  --placeholder-alt-color: rgb(81, 224, 200);
  --background-alt-color: rgb(229, 245, 242);
  --tertiary-color: rgb(70, 27, 156);
  --black-color: rgb(13, 17, 16);
  --white-color: rgb(255, 255, 255);
  --success-color: rgb(19, 112, 50);
  --warning-color: rgb(156, 104, 27);
  --error-color: rgb(156, 27, 27);
  --success-light-color: rgb(234, 255, 241);
  --warning-light-color: rgb(255, 246, 234);
  --error-light-color: rgb(255, 234, 234);
  --extra-color-1: rgb(254, 215, 102);
  --extra-color-2: rgb(254, 138, 113);
  --extra-color-3: rgb(14, 154, 167);
  --extra-color-4: rgb(83, 104, 120);
  --primary-hover-color: rgba(19, 112, 97, 1);
  --secondary-hover-color: rgba(36, 167, 121, 1);
  --primary-alt-color: rgba(220, 248, 244, 1);
  --secondary-alt-color: rgba(221, 247, 238, 1);
  --primary-alt-hover-color: rgba(178, 239, 230, 1);
  --secondary-alt-hover-color: rgba(180, 237, 217, 1);
  --primary-rgb-vals: 28, 157, 135;
  --secondary-rgb-vals: 46, 207, 150;
  --transparent-color: transparent;
  --dark-rgb-vals: 20, 44, 31;
  --paragraph-rgb-vals: 68, 85, 76;
  --tertiary-rgb-vals: 70, 27, 156;
  --black-rgb-vals: 13, 17, 16;
  --success-rgb-vals: 19, 112, 50;
  --warning-rgb-vals: 156, 104, 27;
  --error-rgb-vals: 156, 27, 27;
  --extra-color-1-rgb-vals: 254, 215, 102;
  --extra-color-2-rgb-vals: 254, 138, 113;
  --extra-color-3-rgb-vals: 14, 154, 167;
  --extra-color-4-rgb-vals: 83, 104, 120;
}
html {
  font-size: 62.5%;
}
body {
  font-size: 1.7rem;
}
:root {
  --desktop-text-base: 1.7;
  --responsive-spacing-ratio: 70;
  --step-gap-px: 4;
  --mobile-text-base: 1.6;
  --responsive-text-ratio: 0.7;
  --smallest-font-size: 1.5;
  --h-font-weight: 700;
  --desktop-type-scale-ratio: 1.25;
  --mobile-type-scale-ratio: 1.2;
  --viewport-min: 48;
  --viewport-max: 112;
  --lh-65-150: 0.98;
  --lh-49-64: 1;
  --lh-37-48: 1.1;
  --lh-31-36: 1.2;
  --lh-25-30: 1.33;
  --lh-21-24: 1.45;
  --lh-17-20: 1.54;
  --lh-13-16: 1.68;
  --desktop-h6: 2.125;
  --desktop-h5: 2.65625;
  --desktop-h4: 3.3203125;
  --desktop-h3: 4.150390625;
  --desktop-h2: 5.18798828125;
  --desktop-h1: 6.4849853515625;
  --desktop-hero: 8.1062316894531;
  --mobile-h6: 1.92;
  --mobile-h5: 2.304;
  --mobile-h4: 2.7648;
  --mobile-h3: 3.31776;
  --mobile-h2: 3.981312;
  --mobile-h1: 4.7775744;
  --mobile-hero: 5.73308928;
  --desktop-xs: 1.275;
  --desktop-sm: 1.4875;
  --desktop-base: 1.7;
  --desktop-lg: 1.9125;
  --desktop-xl: 2.125;
  --desktop-2xl: 2.55;
  --desktop-3xl: 2.975;
  --desktop-4xl: 3.825;
  --desktop-5xl: 4.675;
  --desktop-6xl: 5.95;
  --desktop-7xl: 7.225;
  --desktop-8xl: 9.775;
  --desktop-9xl: 11.9;
  --mobile-xs: 0.8925;
  --mobile-sm: 1.04125;
  --mobile-base: 1.19;
  --mobile-lg: 1.33875;
  --mobile-xl: 1.4875;
  --mobile-2xl: 1.785;
  --mobile-3xl: 2.0825;
  --mobile-4xl: 2.6775;
  --mobile-5xl: 3.2725;
  --mobile-6xl: 4.165;
  --mobile-7xl: 5.0575;
  --mobile-8xl: 6.8425;
  --mobile-9xl: 8.33;
  --lh-hero: 0.99;
  --lh-h1: 1.04;
  --lh-h2: 1.05;
  --lh-h3: 1.15;
  --lh-h4: 1.265;
  --lh-h5: 1.39;
  --lh-h6: 1.495;
  --lh-xs: 1.68;
  --lh-sm: 1.68;
  --lh-base: 1.61;
  --lh-lg: 1.61;
  --lh-xl: 1.565;
  --lh-2xl: 1.435;
  --lh-3xl: 1.39;
  --lh-4xl: 1.215;
  --lh-5xl: 1.15;
  --lh-6xl: 1.05;
  --lh-7xl: 0.99;
  --lh-8xl: 0.98;
  --lh-9xl: 0.98;
}

:root {
  --responsive-text-base: calc(
    var(--desktop-text-base) * var(--responsive-text-ratio)
  );
  /* Viewport Calculation for fluid typography */
  --viewport-calc: calc(
    (100vw - calc(var(--viewport-min) * 1rem)) /
      (var(--viewport-max) - var(--viewport-min))
  );

  /* Fluid Heading type scale */

  --h1: min(
    max(
      calc(var(--mobile-h1) * 1rem),
      calc(
        var(--mobile-h1) * 1rem + (var(--desktop-h1) - var(--mobile-h1)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--desktop-h1) * 1rem)
  );

  --h2: min(
    max(
      calc(var(--mobile-h2) * 1rem),
      calc(
        var(--mobile-h2) * 1rem + (var(--desktop-h2) - var(--mobile-h2)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--desktop-h2) * 1rem)
  );

  --h3: min(
    max(
      calc(var(--mobile-h3) * 1rem),
      calc(
        var(--mobile-h3) * 1rem + (var(--desktop-h3) - var(--mobile-h3)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--desktop-h3) * 1rem)
  );

  --h4: min(
    max(
      calc(var(--mobile-h4) * 1rem),
      calc(
        var(--mobile-h4) * 1rem + (var(--desktop-h4) - var(--mobile-h4)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--desktop-h4) * 1rem)
  );

  --h5: min(
    max(
      calc(var(--mobile-h5) * 1rem),
      calc(
        var(--mobile-h5) * 1rem + (var(--desktop-h5) - var(--mobile-h5)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--desktop-h5) * 1rem)
  );

  --h6: min(
    max(
      calc(var(--mobile-h6) * 1rem),
      calc(
        var(--mobile-h6) * 1rem + (var(--desktop-h6) - var(--mobile-h6)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--desktop-h6) * 1rem)
  );

  --hero: min(
    max(
      calc(var(--mobile-hero) * 1rem),
      calc(
        var(--mobile-hero) * 1rem + (var(--desktop-hero) - var(--mobile-hero)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--desktop-hero) * 1rem)
  );

  /* Fluid text type scale */

  --smallest-mobile-xs: max(var(--mobile-xs), var(--smallest-font-size));
  --smallest-mobile-sm: max(var(--mobile-sm), var(--smallest-font-size));
  --smallest-mobile-base: max(var(--mobile-base), var(--smallest-font-size));
  --smallest-mobile-lg: max(var(--mobile-lg), var(--smallest-font-size));
  --smallest-mobile-xl: max(var(--mobile-xl), var(--smallest-font-size));
  --smallest-mobile-2xl: max(var(--mobile-2xl), var(--smallest-font-size));
  --smallest-mobile-3xl: max(var(--mobile-3xl), var(--smallest-font-size));
  --smallest-mobile-4xl: max(var(--mobile-4xl), var(--smallest-font-size));

  --smallest-desktop-xs: max(var(--desktop-xs), var(--smallest-font-size));
  --smallest-desktop-sm: max(var(--desktop-sm), var(--smallest-font-size));
  --smallest-desktop-base: max(var(--desktop-base), var(--smallest-font-size));

  --text-xs: min(
    max(
      calc(var(--smallest-mobile-xs) * 1rem),
      calc(
        var(--smallest-mobile-xs) * 1rem +
          (var(--smallest-desktop-xs) - var(--smallest-mobile-xs)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--smallest-desktop-xs) * 1rem)
  );
  --text-sm: min(
    max(
      calc(var(--smallest-mobile-sm) * 1rem),
      calc(
        var(--smallest-mobile-sm) * 1rem +
          (var(--smallest-desktop-sm) - var(--smallest-mobile-sm)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--smallest-desktop-sm) * 1rem)
  );
  --text-base: min(
    max(
      calc(var(--smallest-mobile-base) * 1rem),
      calc(
        var(--smallest-mobile-base) * 1rem +
          (var(--smallest-desktop-base) - var(--smallest-mobile-base)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--smallest-desktop-base) * 1rem)
  );
  --text-lg: min(
    max(
      calc(var(--smallest-mobile-lg) * 1rem),
      calc(
        var(--smallest-mobile-lg) * 1rem +
          (var(--desktop-lg) - var(--smallest-mobile-lg)) * var(--viewport-calc)
      )
    ),
    calc(var(--desktop-lg) * 1rem)
  );
  --text-xl: min(
    max(
      calc(var(--smallest-mobile-xl) * 1rem),
      calc(
        var(--smallest-mobile-xl) * 1rem +
          (var(--desktop-xl) - var(--smallest-mobile-xl)) * var(--viewport-calc)
      )
    ),
    calc(var(--desktop-xl) * 1rem)
  );
  --text-2xl: min(
    max(
      calc(var(--smallest-mobile-2xl) * 1rem),
      calc(
        var(--smallest-mobile-2xl) * 1rem +
          (var(--desktop-2xl) - var(--smallest-mobile-2xl)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--desktop-2xl) * 1rem)
  );
  --text-3xl: min(
    max(
      calc(var(--smallest-mobile-3xl) * 1rem),
      calc(
        var(--smallest-mobile-3xl) * 1rem +
          (var(--desktop-3xl) - var(--smallest-mobile-3xl)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--desktop-3xl) * 1rem)
  );
  --text-4xl: min(
    max(
      calc(var(--smallest-mobile-4xl) * 1rem),
      calc(
        var(--smallest-mobile-4xl) * 1rem +
          (var(--desktop-4xl) - var(--smallest-mobile-4xl)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--desktop-4xl) * 1rem)
  );
  --text-5xl: min(
    max(
      calc(var(--mobile-5xl) * 1rem),
      calc(
        var(--mobile-5xl) * 1rem + (var(--desktop-5xl) - var(--mobile-5xl)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--desktop-5xl) * 1rem)
  );
  --text-6xl: min(
    max(
      calc(var(--mobile-6xl) * 1rem),
      calc(
        var(--mobile-6xl) * 1rem + (var(--desktop-6xl) - var(--mobile-6xl)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--desktop-6xl) * 1rem)
  );
  --text-7xl: min(
    max(
      calc(var(--mobile-7xl) * 1rem),
      calc(
        var(--mobile-7xl) * 1rem + (var(--desktop-7xl) - var(--mobile-7xl)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--desktop-7xl) * 1rem)
  );
  --text-8xl: min(
    max(
      calc(var(--mobile-8xl) * 1rem),
      calc(
        var(--mobile-8xl) * 1rem + (var(--desktop-8xl) - var(--mobile-8xl)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--desktop-8xl) * 1rem)
  );
  --text-9xl: min(
    max(
      calc(var(--mobile-9xl) * 1rem),
      calc(
        var(--mobile-9xl) * 1rem + (var(--desktop-9xl) - var(--mobile-9xl)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--desktop-9xl) * 1rem)
  );

  --responsive-spacing-ratio: 70;
  --minmax-quo: 0.375;
  --step-gap-px: 4;
  --step-gap-rem: calc(var(--step-gap-px) / 10);

  /* Formula */

  --min-space-1: calc(
    var(--step-gap-rem) * var(--responsive-spacing-ratio) / 100 * 1
  );
  --min-space-2: calc(
    var(--step-gap-rem) * var(--responsive-spacing-ratio) / 100 * 2
  );
  --min-space-3: calc(
    var(--step-gap-rem) * var(--responsive-spacing-ratio) / 100 * 3
  );
  --min-space-4: calc(
    var(--step-gap-rem) * var(--responsive-spacing-ratio) / 100 * 4
  );
  --min-space-5: calc(
    var(--step-gap-rem) * var(--responsive-spacing-ratio) / 100 * 5
  );
  --min-space-6: calc(
    var(--step-gap-rem) * var(--responsive-spacing-ratio) / 100 * 6
  );
  --min-space-8: calc(
    var(--step-gap-rem) * var(--responsive-spacing-ratio) / 100 * 8
  );
  --min-space-10: calc(
    var(--step-gap-rem) * var(--responsive-spacing-ratio) / 100 * 10
  );
  --min-space-12: calc(
    var(--step-gap-rem) * var(--responsive-spacing-ratio) / 100 * 12
  );
  --min-space-14: calc(
    var(--step-gap-rem) * var(--responsive-spacing-ratio) / 100 * 14
  );
  --min-space-16: calc(
    var(--step-gap-rem) * var(--responsive-spacing-ratio) / 100 * 16
  );
  --min-space-20: calc(
    var(--step-gap-rem) * var(--responsive-spacing-ratio) / 100 * 20
  );
  --min-space-24: calc(
    var(--step-gap-rem) * var(--responsive-spacing-ratio) / 100 * 24
  );
  --min-space-28: calc(
    var(--step-gap-rem) * var(--responsive-spacing-ratio) / 100 * 28
  );
  --min-space-32: calc(
    var(--step-gap-rem) * var(--responsive-spacing-ratio) / 100 * 32
  );
  --min-space-36: calc(
    var(--step-gap-rem) * var(--responsive-spacing-ratio) / 100 * 36
  );
  --min-space-40: calc(
    var(--step-gap-rem) * var(--responsive-spacing-ratio) / 100 * 40
  );

  --max-space-1: calc(var(--step-gap-rem) * 1);
  --max-space-2: calc(var(--step-gap-rem) * 2);
  --max-space-3: calc(var(--step-gap-rem) * 3);
  --max-space-4: calc(var(--step-gap-rem) * 4);
  --max-space-5: calc(var(--step-gap-rem) * 5);
  --max-space-6: calc(var(--step-gap-rem) * 6);
  --max-space-8: calc(var(--step-gap-rem) * 8);
  --max-space-10: calc(var(--step-gap-rem) * 10);
  --max-space-12: calc(var(--step-gap-rem) * 12);
  --max-space-14: calc(var(--step-gap-rem) * 14);
  --max-space-16: calc(var(--step-gap-rem) * 16);
  --max-space-20: calc(var(--step-gap-rem) * 20);
  --max-space-24: calc(var(--step-gap-rem) * 24);
  --max-space-28: calc(var(--step-gap-rem) * 28);
  --max-space-32: calc(var(--step-gap-rem) * 32);
  --max-space-36: calc(var(--step-gap-rem) * 36);
  --max-space-40: calc(var(--step-gap-rem) * 40);

  /* --minmax-multiplier: calc(var(--minmax-quo) * var(--step-gap-rem)); */

  /* Actual fluid spacing variables */

  --s-1: min(
    max(
      calc(var(--min-space-1) * 1rem),
      calc(
        var(--min-space-1) * 1rem + (var(--max-space-1) - var(--min-space-1)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--max-space-1) * 1rem)
  );

  --s-2: min(
    max(
      calc(var(--min-space-2) * 1rem),
      calc(
        var(--min-space-2) * 1rem + (var(--max-space-2) - var(--min-space-2)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--max-space-2) * 1rem)
  );

  --s-3: min(
    max(
      calc(var(--min-space-3) * 1rem),
      calc(
        var(--min-space-3) * 1rem + (var(--max-space-3) - var(--min-space-3)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--max-space-3) * 1rem)
  );

  --s-4: min(
    max(
      calc(var(--min-space-4) * 1rem),
      calc(
        var(--min-space-4) * 1rem + (var(--max-space-4) - var(--min-space-4)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--max-space-4) * 1rem)
  );

  --s-5: min(
    max(
      calc(var(--min-space-5) * 1rem),
      calc(
        var(--min-space-5) * 1rem + (var(--max-space-5) - var(--min-space-5)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--max-space-5) * 1rem)
  );

  --s-6: min(
    max(
      calc(var(--min-space-6) * 1rem),
      calc(
        var(--min-space-6) * 1rem + (var(--max-space-6) - var(--min-space-6)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--max-space-6) * 1rem)
  );

  --s-8: min(
    max(
      calc(var(--min-space-8) * 1rem),
      calc(
        var(--min-space-8) * 1rem + (var(--max-space-8) - var(--min-space-8)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--max-space-8) * 1rem)
  );

  --s-10: min(
    max(
      calc(var(--min-space-10) * 1rem),
      calc(
        var(--min-space-10) * 1rem + (var(--max-space-10) - var(--min-space-10)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--max-space-10) * 1rem)
  );

  --s-12: min(
    max(
      calc(var(--min-space-12) * 1rem),
      calc(
        var(--min-space-12) * 1rem + (var(--max-space-12) - var(--min-space-12)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--max-space-12) * 1rem)
  );

  --s-14: min(
    max(
      calc(var(--min-space-14) * 1rem),
      calc(
        var(--min-space-14) * 1rem + (var(--max-space-14) - var(--min-space-14)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--max-space-14) * 1rem)
  );

  --s-16: min(
    max(
      calc(var(--min-space-16) * 1rem),
      calc(
        var(--min-space-16) * 1rem + (var(--max-space-16) - var(--min-space-16)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--max-space-16) * 1rem)
  );

  --s-20: min(
    max(
      calc(var(--min-space-20) * 1rem),
      calc(
        var(--min-space-20) * 1rem + (var(--max-space-20) - var(--min-space-20)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--max-space-20) * 1rem)
  );

  --s-24: min(
    max(
      calc(var(--min-space-24) * 1rem),
      calc(
        var(--min-space-24) * 1rem + (var(--max-space-24) - var(--min-space-24)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--max-space-24) * 1rem)
  );

  --s-28: min(
    max(
      calc(var(--min-space-28) * 1rem),
      calc(
        var(--min-space-28) * 1rem + (var(--max-space-28) - var(--min-space-28)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--max-space-28) * 1rem)
  );

  --s-32: min(
    max(
      calc(var(--min-space-32) * 1rem),
      calc(
        var(--min-space-32) * 1rem + (var(--max-space-32) - var(--min-space-32)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--max-space-32) * 1rem)
  );

  --s-36: min(
    max(
      calc(var(--min-space-36) * 1rem),
      calc(
        var(--min-space-36) * 1rem + (var(--max-space-36) - var(--min-space-36)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--max-space-36) * 1rem)
  );

  --s-40: min(
    max(
      calc(var(--min-space-40) * 1rem),
      calc(
        var(--min-space-40) * 1rem + (var(--max-space-40) - var(--min-space-40)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--max-space-40) * 1rem)
  );
}

body {
  font-size: min(
    max(
      calc(var(--mobile-text-base) * 1rem),
      calc(
        var(--mobile-text-base) * 1rem +
          (var(--desktop-text-base) - var(--mobile-text-base)) *
          var(--viewport-calc)
      )
    ),
    calc(var(--desktop-text-base) * 1rem)
  );
}

body {
  --wp--preset--color--black: #000000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #ffffff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(
    135deg,
    rgba(6, 147, 227, 1) 0%,
    rgb(155, 81, 224) 100%
  );
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(
    135deg,
    rgb(122, 220, 180) 0%,
    rgb(0, 208, 130) 100%
  );
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(
    135deg,
    rgba(252, 185, 0, 1) 0%,
    rgba(255, 105, 0, 1) 100%
  );
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(
    135deg,
    rgba(255, 105, 0, 1) 0%,
    rgb(207, 46, 46) 100%
  );
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(
    135deg,
    rgb(238, 238, 238) 0%,
    rgb(169, 184, 195) 100%
  );
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(
    135deg,
    rgb(74, 234, 220) 0%,
    rgb(151, 120, 209) 20%,
    rgb(207, 42, 186) 40%,
    rgb(238, 44, 130) 60%,
    rgb(251, 105, 98) 80%,
    rgb(254, 248, 76) 100%
  );
  --wp--preset--gradient--blush-light-purple: linear-gradient(
    135deg,
    rgb(255, 206, 236) 0%,
    rgb(152, 150, 240) 100%
  );
  --wp--preset--gradient--blush-bordeaux: linear-gradient(
    135deg,
    rgb(254, 205, 165) 0%,
    rgb(254, 45, 45) 50%,
    rgb(107, 0, 62) 100%
  );
  --wp--preset--gradient--luminous-dusk: linear-gradient(
    135deg,
    rgb(255, 203, 112) 0%,
    rgb(199, 81, 192) 50%,
    rgb(65, 88, 208) 100%
  );
  --wp--preset--gradient--pale-ocean: linear-gradient(
    135deg,
    rgb(255, 245, 203) 0%,
    rgb(182, 227, 212) 50%,
    rgb(51, 167, 181) 100%
  );
  --wp--preset--gradient--electric-grass: linear-gradient(
    135deg,
    rgb(202, 248, 128) 0%,
    rgb(113, 206, 126) 100%
  );
  --wp--preset--gradient--midnight: linear-gradient(
    135deg,
    rgb(2, 3, 129) 0%,
    rgb(40, 116, 252) 100%
  );
  --wp--preset--duotone--dark-grayscale: url("#wp-duotone-dark-grayscale");
  --wp--preset--duotone--grayscale: url("#wp-duotone-grayscale");
  --wp--preset--duotone--purple-yellow: url("#wp-duotone-purple-yellow");
  --wp--preset--duotone--blue-red: url("#wp-duotone-blue-red");
  --wp--preset--duotone--midnight: url("#wp-duotone-midnight");
  --wp--preset--duotone--magenta-yellow: url("#wp-duotone-magenta-yellow");
  --wp--preset--duotone--purple-green: url("#wp-duotone-purple-green");
  --wp--preset--duotone--blue-orange: url("#wp-duotone-blue-orange");
  --wp--preset--font-size--small: 13px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 36px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--spacing--20: 0.44rem;
  --wp--preset--spacing--30: 0.67rem;
  --wp--preset--spacing--40: 1rem;
  --wp--preset--spacing--50: 1.5rem;
  --wp--preset--spacing--60: 2.25rem;
  --wp--preset--spacing--70: 3.38rem;
  --wp--preset--spacing--80: 5.06rem;
}
:where(.is-layout-flex) {
  gap: 0.5em;
}
body .is-layout-flow > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}
body .is-layout-flow > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}
body .is-layout-flow > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}
body .is-layout-constrained > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}
body .is-layout-constrained > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}
body .is-layout-constrained > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}
body
  .is-layout-constrained
  > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
  max-width: var(--wp--style--global--content-size);
  margin-left: auto !important;
  margin-right: auto !important;
}
body .is-layout-constrained > .alignwide {
  max-width: var(--wp--style--global--wide-size);
}
body .is-layout-flex {
  display: flex;
}
body .is-layout-flex {
  flex-wrap: wrap;
  align-items: center;
}
body .is-layout-flex > * {
  margin: 0;
}
:where(.wp-block-columns.is-layout-flex) {
  gap: 2em;
}
.has-black-color {
  color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-color {
  color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-color {
  color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-color {
  color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-color {
  color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-color {
  color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-color {
  color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-color {
  color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-color {
  color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-color {
  color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-color {
  color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-color {
  color: var(--wp--preset--color--vivid-purple) !important;
}
.has-black-background-color {
  background-color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-background-color {
  background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-background-color {
  background-color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-background-color {
  background-color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-background-color {
  background-color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-background-color {
  background-color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-background-color {
  background-color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-background-color {
  background-color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-background-color {
  background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-background-color {
  background-color: var(--wp--preset--color--vivid-purple) !important;
}
.has-black-border-color {
  border-color: var(--wp--preset--color--black) !important;
}
.has-cyan-bluish-gray-border-color {
  border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}
.has-white-border-color {
  border-color: var(--wp--preset--color--white) !important;
}
.has-pale-pink-border-color {
  border-color: var(--wp--preset--color--pale-pink) !important;
}
.has-vivid-red-border-color {
  border-color: var(--wp--preset--color--vivid-red) !important;
}
.has-luminous-vivid-orange-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}
.has-luminous-vivid-amber-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}
.has-light-green-cyan-border-color {
  border-color: var(--wp--preset--color--light-green-cyan) !important;
}
.has-vivid-green-cyan-border-color {
  border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}
.has-pale-cyan-blue-border-color {
  border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}
.has-vivid-cyan-blue-border-color {
  border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}
.has-vivid-purple-border-color {
  border-color: var(--wp--preset--color--vivid-purple) !important;
}
.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
  background: var(
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple
  ) !important;
}
.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
  background: var(
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan
  ) !important;
}
.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
  background: var(
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange
  ) !important;
}
.has-luminous-vivid-orange-to-vivid-red-gradient-background {
  background: var(
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red
  ) !important;
}
.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
  background: var(
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray
  ) !important;
}
.has-cool-to-warm-spectrum-gradient-background {
  background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}
.has-blush-light-purple-gradient-background {
  background: var(--wp--preset--gradient--blush-light-purple) !important;
}
.has-blush-bordeaux-gradient-background {
  background: var(--wp--preset--gradient--blush-bordeaux) !important;
}
.has-luminous-dusk-gradient-background {
  background: var(--wp--preset--gradient--luminous-dusk) !important;
}
.has-pale-ocean-gradient-background {
  background: var(--wp--preset--gradient--pale-ocean) !important;
}
.has-electric-grass-gradient-background {
  background: var(--wp--preset--gradient--electric-grass) !important;
}
.has-midnight-gradient-background {
  background: var(--wp--preset--gradient--midnight) !important;
}
.has-small-font-size {
  font-size: var(--wp--preset--font-size--small) !important;
}
.has-medium-font-size {
  font-size: var(--wp--preset--font-size--medium) !important;
}
.has-large-font-size {
  font-size: var(--wp--preset--font-size--large) !important;
}
.has-x-large-font-size {
  font-size: var(--wp--preset--font-size--x-large) !important;
}

.ct-section {
  width: 100%;
  background-size: cover;
  background-repeat: repeat;
}
.ct-section > .ct-section-inner-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ct-div-block {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
}
.ct-new-columns {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
}
.ct-link-text {
  display: inline-block;
}
.ct-link {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  text-decoration: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ct-link-button {
  display: inline-block;
  text-align: center;
  text-decoration: none;
}
.ct-link-button {
  background-color: #1e73be;
  border: 1px solid #1e73be;
  color: #ffffff;
  padding: 10px 16px;
}
.ct-image {
  max-width: 100%;
}
.ct-fancy-icon > svg {
  width: 55px;
  height: 55px;
}
.ct-inner-content {
  width: 100%;
}
.ct-slide {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ct-nestable-shortcode {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
}
.oxy-comments {
  width: 100%;
  text-align: left;
}
.oxy-comment-form {
  width: 100%;
}
.oxy-login-form {
  width: 100%;
}
.oxy-search-form {
  width: 100%;
}
.oxy-tabs-contents {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
}
.oxy-tab {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
}
.oxy-tab-content {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
}
.oxy-testimonial {
  width: 100%;
}
.oxy-icon-box {
  width: 100%;
}
.oxy-pricing-box {
  width: 100%;
}
.oxy-posts-grid {
  width: 100%;
}
.oxy-gallery {
  width: 100%;
}
.ct-slider {
  width: 100%;
}
.oxy-tabs {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: stretch;
}
.ct-modal {
  flex-direction: column;
  align-items: flex-start;
}
.ct-span {
  display: inline-block;
  text-decoration: inherit;
}
.ct-widget {
  width: 100%;
}
.oxy-dynamic-list {
  width: 100%;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ct-div-block,
  .oxy-post-content,
  .ct-text-block,
  .ct-headline,
  .oxy-rich-text,
  .ct-link-text {
    max-width: 100%;
  }
  img {
    flex-shrink: 0;
  }
  body * {
    min-height: 1px;
  }
}
.oxy-testimonial {
  flex-direction: row;
  align-items: center;
}
.oxy-testimonial .oxy-testimonial-photo-wrap {
  order: 1;
}

.oxy-testimonial .oxy-testimonial-photo {
  width: 125px;
  height: 125px;
  margin-right: 20px;
}

.oxy-testimonial .oxy-testimonial-photo-wrap,
.oxy-testimonial .oxy-testimonial-author-wrap,
.oxy-testimonial .oxy-testimonial-content-wrap {
  align-items: flex-start;
  text-align: left;
}

.oxy-testimonial .oxy-testimonial-text {
  margin-bottom: 8px;
  font-size: 21px;
  line-height: 1.4;
  -webkit-font-smoothing: subpixel-antialiased;
}

.oxy-testimonial .oxy-testimonial-author {
  font-size: 18px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.oxy-testimonial .oxy-testimonial-author-info {
  font-size: 12px;
  -webkit-font-smoothing: subpixel-antialiased;
}

.oxy-icon-box {
  text-align: left;
  flex-direction: column;
}

.oxy-icon-box .oxy-icon-box-icon {
  margin-bottom: 12px;
  align-self: flex-start;
}

.oxy-icon-box .oxy-icon-box-heading {
  font-size: 21px;
  margin-bottom: 12px;
}

.oxy-icon-box .oxy-icon-box-text {
  font-size: 16px;
  margin-bottom: 12px;
  align-self: flex-start;
}

.oxy-icon-box .oxy-icon-box-link {
  margin-top: 20px;
}

/* GLOBALS */

.oxy-pricing-box .oxy-pricing-box-section {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  text-align: center;
}

.oxy-pricing-box .oxy-pricing-box-section.oxy-pricing-box-price {
  justify-content: center;
}

/* IMAGE */
.oxy-pricing-box .oxy-pricing-box-section.oxy-pricing-box-graphic {
  justify-content: center;
}

/* TITLE */

.oxy-pricing-box .oxy-pricing-box-title-title {
  font-size: 48px;
}

.oxy-pricing-box .oxy-pricing-box-title-subtitle {
  font-size: 24px;
}

/* PRICE */
.oxy-pricing-box .oxy-pricing-box-section.oxy-pricing-box-price {
  flex-direction: row;
}

.oxy-pricing-box .oxy-pricing-box-currency {
  font-size: 28px;
}

.oxy-pricing-box .oxy-pricing-box-amount-main {
  font-size: 80px;
  line-height: 0.7;
}

.oxy-pricing-box .oxy-pricing-box-amount-decimal {
  font-size: 13px;
}

.oxy-pricing-box .oxy-pricing-box-term {
  font-size: 16px;
}

.oxy-pricing-box .oxy-pricing-box-sale-price {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}

/* CONTENT */

.oxy-pricing-box .oxy-pricing-box-section.oxy-pricing-box-content {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
}

/* CTA */

.oxy-pricing-box .oxy-pricing-box-section.oxy-pricing-box-cta {
  justify-content: center;
}

.oxy-progress-bar .oxy-progress-bar-background {
  background-color: #000000;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.12) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.12) 50%,
    rgba(255, 255, 255, 0.12) 75%,
    transparent 75%,
    transparent
  );
  animation: none 0s paused;
}

.oxy-progress-bar .oxy-progress-bar-progress-wrap {
  width: 85%;
}

.oxy-progress-bar .oxy-progress-bar-progress {
  background-color: #66aaff;
  padding: 40px;
  animation: none 0s paused, none 0s paused;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.12) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.12) 50%,
    rgba(255, 255, 255, 0.12) 75%,
    transparent 75%,
    transparent
  );
}

.oxy-progress-bar .oxy-progress-bar-overlay-text {
  font-size: 30px;
  font-weight: 900;
  -webkit-font-smoothing: subpixel-antialiased;
}

.oxy-progress-bar .oxy-progress-bar-overlay-percent {
  font-size: 12px;
}

.ct-slider .unslider-nav ol li {
  border-color: #ffffff;
}
.ct-slider .unslider-nav ol li.unslider-active {
  background-color: #ffffff;
}
.ct-slider .ct-slide {
  padding: 0px;
}

.oxy-superbox .oxy-superbox-secondary,
.oxy-superbox .oxy-superbox-primary {
  transition-duration: 0.5s;
}

.oxy-shape-divider {
  width: 0px;
  height: 0px;
}

.oxy_shape_divider svg {
  width: 100%;
}
.oxy-pro-menu
  .oxy-pro-menu-container:not(.oxy-pro-menu-open-container):not(
    .oxy-pro-menu-off-canvas-container
  )
  .sub-menu {
  box-shadow: px px px px;
}

.oxy-pro-menu
  .oxy-pro-menu-show-dropdown
  .oxy-pro-menu-list
  .menu-item-has-children
  > a
  svg {
  transition-duration: 0.4s;
}

.oxy-pro-menu
  .oxy-pro-menu-show-dropdown
  .oxy-pro-menu-list
  .menu-item-has-children
  > a
  div {
  margin-left: 0px;
}

.oxy-pro-menu .oxy-pro-menu-mobile-open-icon svg {
  width: 30px;
  height: 30px;
}

.oxy-pro-menu .oxy-pro-menu-mobile-open-icon {
  padding-top: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
}

.oxy-pro-menu .oxy-pro-menu-mobile-open-icon,
.oxy-pro-menu .oxy-pro-menu-mobile-open-icon svg {
  transition-duration: 0.4s;
}

.oxy-pro-menu .oxy-pro-menu-mobile-close-icon {
  top: 20px;
  left: 20px;
}

.oxy-pro-menu .oxy-pro-menu-mobile-close-icon svg {
  width: 24px;
  height: 24px;
}

.oxy-pro-menu .oxy-pro-menu-mobile-close-icon,
.oxy-pro-menu .oxy-pro-menu-mobile-close-icon svg {
  transition-duration: 0.4s;
}

.oxy-pro-menu .oxy-pro-menu-container.oxy-pro-menu-off-canvas-container,
.oxy-pro-menu .oxy-pro-menu-container.oxy-pro-menu-open-container {
  background-color: #ffffff;
}

.oxy-pro-menu .oxy-pro-menu-off-canvas-container,
.oxy-pro-menu .oxy-pro-menu-open-container {
  background-image: url();
}

.oxy-pro-menu
  .oxy-pro-menu-off-canvas-container
  .oxy-pro-menu-list
  .menu-item-has-children
  > a
  svg,
.oxy-pro-menu
  .oxy-pro-menu-open-container
  .oxy-pro-menu-list
  .menu-item-has-children
  > a
  svg {
  font-size: 24px;
}

.oxy-pro-menu
  .oxy-pro-menu-dropdown-links-toggle.oxy-pro-menu-open-container
  .menu-item-has-children
  ul,
.oxy-pro-menu
  .oxy-pro-menu-dropdown-links-toggle.oxy-pro-menu-off-canvas-container
  .menu-item-has-children
  ul {
  background-color: rgba(0, 0, 0, 0.2);
  border-top-style: solid;
}

.oxy-pro-menu
  .oxy-pro-menu-container:not(.oxy-pro-menu-open-container):not(
    .oxy-pro-menu-off-canvas-container
  )
  .oxy-pro-menu-list {
  flex-direction: row;
}
.oxy-pro-menu .oxy-pro-menu-container .menu-item a {
  text-align: left;
  justify-content: flex-start;
}
.oxy-pro-menu .oxy-pro-menu-container.oxy-pro-menu-open-container .menu-item,
.oxy-pro-menu
  .oxy-pro-menu-container.oxy-pro-menu-off-canvas-container
  .menu-item {
  align-items: flex-start;
}

.oxy-pro-menu .oxy-pro-menu-off-canvas-container {
  top: 0;
  bottom: 0;
  right: auto;
  left: 0;
}
.oxy-pro-menu
  .oxy-pro-menu-container.oxy-pro-menu-open-container
  .oxy-pro-menu-list
  .menu-item
  a,
.oxy-pro-menu
  .oxy-pro-menu-container.oxy-pro-menu-off-canvas-container
  .oxy-pro-menu-list
  .menu-item
  a {
  text-align: left;
  justify-content: flex-start;
}

.ct-section-inner-wrap,
.oxy-header-container {
  max-width: 1366px;
}
body {
  font-family: "Rubik";
}
body {
  line-height: var(--lh-base);
  font-size: var(--text-base);
  font-weight: 400;
  color: var(--paragraph-color);
}
.oxy-nav-menu-hamburger-line {
  background-color: var(--paragraph-color);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Rubik";
  font-size: var(--h1);
  font-weight: var(--h-font-weight);
  line-height: var(--lh-h1);
}
h2,
h3,
h4,
h5,
h6 {
  font-size: var(--h2);
  font-weight: var(--h-font-weight);
  line-height: var(--lh-h2);
}
h3,
h4,
h5,
h6 {
  font-size: var(--h3);
  font-weight: var(--h-font-weight);
  line-height: var(--lh-h3);
}
h4,
h5,
h6 {
  font-size: var(--h4);
  font-weight: var(--h-font-weight);
  line-height: var(--lh-h4);
}
h5,
h6 {
  font-size: var(--h5);
  font-weight: var(--h-font-weight);
  line-height: var(--lh-h5);
}
h6 {
  font-size: var(--h6);
  font-weight: var(--h-font-weight);
  line-height: var(--lh-h6);
}
a {
  color: var(--primary-color);
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
.ct-link-text {
  text-decoration: none;
}
.ct-link {
  text-decoration: none;
}
.ct-link-button {
  border-radius: 3px;
}
.ct-section-inner-wrap {
  padding-top: min(max(8rem, calc(8rem + ((1vw - 0.32rem) * 5))), 12rem);
  padding-right: 2.4rem;
  padding-bottom: min(max(8rem, calc(8rem + ((1vw - 0.32rem) * 5))), 12rem);
  padding-left: 2.4rem;
}
.ct-new-columns > .ct-div-block {
  padding-top: 2.4rem;
  padding-right: 2.4rem;
  padding-bottom: 2.4rem;
  padding-left: 2.4rem;
}
.oxy-header-container {
  padding-right: 2.4rem;
  padding-left: 2.4rem;
}
@media (max-width: 992px) {
  .ct-columns-inner-wrap {
    display: block !important;
  }
  .ct-columns-inner-wrap:after {
    display: table;
    clear: both;
    content: "";
  }
  .ct-column {
    width: 100% !important;
    margin: 0 !important;
  }
  .ct-columns-inner-wrap {
    margin: 0 !important;
  }
}
.bg {
  background-color: var(--background-color);
}
.w-full {
  width: 100% !important;
}
.grid {
}
.grid-cols-4 {
}
.lg-grid-cols-3 {
}
@media (max-width: 991px) {
  .lg-grid-cols-3 {
  }
}

.md-grid-cols-1 {
}
@media (max-width: 767px) {
  .md-grid-cols-1 {
  }
}

.gap-8 {
}
.col-span-3 {
}
.lg-col-span-2 {
}
@media (max-width: 991px) {
  .lg-col-span-2 {
  }
}

.md-col-span-1 {
}
@media (max-width: 767px) {
  .md-col-span-1 {
  }
}

.grid-cols-2 {
}
.btn-l:hover {
  transform: translateY(-5px);
}
.btn-l {
  padding-left: 6.4rem;
  padding-right: 6.4rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  font-weight: 600;
  font-size: 1.8rem;
  border-radius: 0.8rem;
  transition-duration: 0.4s;
  transition-property: all;
}
@media (max-width: 1366px) {
  .btn-l {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 5.4rem;
    padding-right: 5.4rem;
  }
}

@media (max-width: 991px) {
  .btn-l {
    padding-left: 4.4rem;
    padding-right: 4.4rem;
  }
}

@media (max-width: 767px) {
  .btn-l {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    padding-left: 6.4rem;
    padding-right: 6.4rem;
  }
}

@media (max-width: 479px) {
  .btn-l {
    padding-left: 5.4rem;
    padding-right: 5.4rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
  }
}

.btn-m:hover {
  transform: translateY(-5px);
}
.btn-m {
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  font-weight: 600;
  font-size: 1.6rem;
  border-radius: 0.6rem;
  transition-duration: 0.4s;
  transition-property: all;
}
@media (max-width: 1366px) {
  .btn-m {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2.8rem;
    padding-right: 2.8rem;
  }
}

@media (max-width: 767px) {
  .btn-m {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    padding-left: 3.2rem;
    padding-right: 3.2rem;
  }
}

@media (max-width: 479px) {
  .btn-m {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2.8rem;
    padding-right: 2.8rem;
    width: 100%;
  }
}

.btn-s:hover {
  transform: translateY(-5px);
}
.btn-s {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  font-weight: 600;
  font-size: 1.4rem;
  transition-duration: 0.4s;
  transition-property: all;
  border-radius: 0.4rem;
}
@media (max-width: 1366px) {
  .btn-s {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 767px) {
  .btn-s {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
}

@media (max-width: 479px) {
  .btn-s {
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
  }
}

.btn-primary:hover {
  background-color: var(--primary-hover-color);
  box-shadow: 0 8px 9px rgb(var(--primary-rgb-vals), 25%);
}
.btn-primary {
  background-color: var(--primary-color);
  color: var(--light-color);
  transition-duration: 0.4s;
  transition-property: all;
  box-shadow: 0 4px 9px rgb(var(--primary-rgb-vals), 20%);
}
.btn-primary:not(.ct-section):not(.oxy-easy-posts),
.btn-primary.oxy-easy-posts .oxy-posts,
.btn-primary.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.btn-secondary:hover {
  background-color: var(--secondary-hover-color);
  box-shadow: 0 8px 9px rgb(var(--secondary-rgb-vals), 25%);
}
.btn-secondary {
  background-color: var(--secondary-color);
  color: var(--light-color);
  transition-duration: 0.4s;
  transition-property: all;
  box-shadow: 0 4px 9px rgb(var(--secondary-rgb-vals), 20%);
}
.btn-secondary:not(.ct-section):not(.oxy-easy-posts),
.btn-secondary.oxy-easy-posts .oxy-posts,
.btn-secondary.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.btn-white:hover {
  background-color: var(--primary-alt-color);
}
.btn-white {
  color: var(--dark-color);
  background-color: var(--white-color);
  transition-duration: 0.4s;
  transition-property: all;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.btn-white:not(.ct-section):not(.oxy-easy-posts),
.btn-white.oxy-easy-posts .oxy-posts,
.btn-white.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.btn-trans:hover {
  background-color: var(--white-color);
  color: var(--primary-color);
}
.btn-trans {
  color: var(--light-color);
  background-color: rgba(255, 255, 255, 0.15);
  transition-duration: 0.4s;
  transition-property: all;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.btn-trans:not(.ct-section):not(.oxy-easy-posts),
.btn-trans.oxy-easy-posts .oxy-posts,
.btn-trans.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.btn-primary-alt:hover {
  background-color: var(--primary-alt-hover-color);
}
.btn-primary-alt {
  background-color: var(--primary-alt-color);
  color: var(--primary-color);
  transition-duration: 0.4s;
  transition-property: all;
}
.btn-primary-alt:not(.ct-section):not(.oxy-easy-posts),
.btn-primary-alt.oxy-easy-posts .oxy-posts,
.btn-primary-alt.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.btn-secondary-alt:hover {
  background-color: var(--secondary-alt-hover-color);
}
.btn-secondary-alt {
  background-color: var(--secondary-alt-color);
  color: var(--secondary-hover-color);
  transition-duration: 0.4s;
  transition-property: all;
}
.btn-secondary-alt:not(.ct-section):not(.oxy-easy-posts),
.btn-secondary-alt.oxy-easy-posts .oxy-posts,
.btn-secondary-alt.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.icon-3xl-light {
  color: var(--light-color);
}
.icon-3xl-light > svg {
  width: 64px;
  height: 64px;
}
.icon-3xl-light {
}
.icon-2xl-primary {
  color: var(--primary-color);
}
.icon-2xl-primary > svg {
  width: 48px;
  height: 48px;
}
.icon-2xl-primary {
}
.icon-2xl-light {
  color: var(--light-color);
}
.icon-2xl-light > svg {
  width: 48px;
  height: 48px;
}
.icon-2xl-light {
}
.icon-2xl-dark {
  color: var(--dark-color);
}
.icon-2xl-dark > svg {
  width: 48px;
  height: 48px;
}
.icon-2xl-dark {
}
.icon-xl-primary {
  color: var(--primary-color);
}
.icon-xl-primary > svg {
  width: 32px;
  height: 32px;
}
.icon-xl-primary {
}
.icon-xl-light {
  color: var(--light-color);
}
.icon-xl-light > svg {
  width: 32px;
  height: 32px;
}
.icon-xl-light {
}
.icon-xl-dark {
  color: var(--dark-color);
}
.icon-xl-dark > svg {
  width: 32px;
  height: 32px;
}
.icon-xl-dark {
}
.icon-lg-primary {
  color: var(--primary-color);
}
.icon-lg-primary > svg {
  width: 24px;
  height: 24px;
}
.icon-lg-primary {
}
.icon-lg-secondary {
  color: var(--secondary-color);
}
.icon-lg-secondary > svg {
  width: 24px;
  height: 24px;
}
.icon-lg-secondary {
}
.icon-lg-tertiary {
  color: var(--tertiary-color);
}
.icon-lg-tertiary > svg {
  width: 24px;
  height: 24px;
}
.icon-lg-tertiary {
}
.icon-lg-light {
  color: var(--light-color);
}
.icon-lg-light > svg {
  width: 24px;
  height: 24px;
}
.icon-lg-light {
}
.icon-lg-dark {
  color: var(--dark-color);
}
.icon-lg-dark > svg {
  width: 24px;
  height: 24px;
}
.icon-lg-dark {
}
.icon-lg-paragraph {
  color: var(--paragraph-color);
}
.icon-lg-paragraph > svg {
  width: 24px;
  height: 24px;
}
.icon-lg-paragraph {
}
.icon-md-primary {
  color: var(--primary-color);
}
.icon-md-primary > svg {
  width: 20px;
  height: 20px;
}
.icon-md-primary {
}
.icon-md-light {
  color: var(--light-color);
}
.icon-md-light > svg {
  width: 20px;
  height: 20px;
}
.icon-md-light {
}
.icon-md-dark {
  color: var(--dark-color);
}
.icon-md-dark > svg {
  width: 20px;
  height: 20px;
}
.icon-md-dark {
}
.icon-md-paragraph {
  color: var(--paragraph-color);
}
.icon-md-paragraph > svg {
  width: 20px;
  height: 20px;
}
.icon-md-paragraph {
}
.icon-sm-primary {
  color: var(--primary-color);
}
.icon-sm-primary > svg {
  width: 16px;
  height: 16px;
}
.icon-sm-primary {
}
.icon-sm-light {
  color: var(--light-color);
}
.icon-sm-light > svg {
  width: 16px;
  height: 16px;
}
.icon-sm-light {
}
.icon-sm-dark {
  color: var(--dark-color);
}
.icon-sm-dark > svg {
  width: 16px;
  height: 16px;
}
.icon-sm-dark {
}
.icon-sm-paragraph {
  color: var(--paragraph-color);
}
.icon-sm-paragraph > svg {
  width: 16px;
  height: 16px;
}
.icon-sm-paragraph {
}
.icon-solid-lg-primary {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 16px;
  color: var(--light-color);
}
.icon-solid-lg-primary > svg {
  width: 26px;
  height: 26px;
}
.icon-solid-lg-primary {
}
.icon-solid-lg-secondary {
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  padding: 16px;
  color: var(--light-color);
}
.icon-solid-lg-secondary > svg {
  width: 26px;
  height: 26px;
}
.icon-solid-lg-secondary {
}
.icon-solid-lg-tertiary {
  background-color: var(--tertiary-color);
  border: 1px solid var(--tertiary-color);
  padding: 16px;
  color: var(--light-color);
}
.icon-solid-lg-tertiary > svg {
  width: 26px;
  height: 26px;
}
.icon-solid-lg-tertiary {
}
.icon-solid-lg-light {
  background-color: var(--placeholder-color);
  border: 1px solid var(--placeholder-color);
  padding: 16px;
  color: var(--primary-color);
}
.icon-solid-lg-light > svg {
  width: 26px;
  height: 26px;
}
.icon-solid-lg-light {
}
.icon-solid-md-light {
  background-color: var(--placeholder-color);
  border: 1px solid var(--placeholder-color);
  padding: 14px;
  color: var(--primary-color);
}
.icon-solid-md-light > svg {
  width: 22px;
  height: 22px;
}
.icon-solid-md-light {
}
.icon-solid-md-primary {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 14px;
  color: var(--light-color);
}
.icon-solid-md-primary > svg {
  width: 22px;
  height: 22px;
}
.icon-solid-md-primary {
}
.icon-solid-sm-primary {
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  padding: 10px;
  color: var(--light-color);
}
.icon-solid-sm-primary > svg {
  width: 16px;
  height: 16px;
}
.icon-solid-sm-primary {
}
.icon-solid-sm-light {
  background-color: var(--placeholder-color);
  border: 1px solid var(--placeholder-color);
  padding: 10px;
  color: var(--primary-color);
}
.icon-solid-sm-light > svg {
  width: 16px;
  height: 16px;
}
.icon-solid-sm-light {
}
.card-normal {
  width: 100%;
  padding-left: min(
    max(2.24rem, calc(2.24rem + ((1vw - 0.32rem) * 1.2))),
    3.2rem
  );
  padding-right: min(
    max(2.24rem, calc(2.24rem + ((1vw - 0.32rem) * 1.2))),
    3.2rem
  );
  padding-bottom: min(
    max(2.24rem, calc(2.24rem + ((1vw - 0.32rem) * 1.2))),
    3.2rem
  );
  padding-top: min(
    max(2.24rem, calc(2.24rem + ((1vw - 0.32rem) * 1.2))),
    3.2rem
  );
  border-radius: 0.5rem;
}
.card-loose {
  width: 100%;
  padding-top: min(
    max(4.48rem, calc(4.48rem + ((1vw - 0.32rem) * 2.4))),
    6.4rem
  );
  padding-left: min(
    max(4.48rem, calc(4.48rem + ((1vw - 0.32rem) * 2.4))),
    6.4rem
  );
  padding-right: min(
    max(4.48rem, calc(4.48rem + ((1vw - 0.32rem) * 2.4))),
    6.4rem
  );
  padding-bottom: min(
    max(4.48rem, calc(4.48rem + ((1vw - 0.32rem) * 2.4))),
    6.4rem
  );
  border-radius: 0.5rem;
}
.card-tight {
  width: 100%;
  padding-top: min(
    max(1.12rem, calc(1.12rem + ((1vw - 0.32rem) * 0.6))),
    1.6rem
  );
  padding-left: min(
    max(1.12rem, calc(1.12rem + ((1vw - 0.32rem) * 0.6))),
    1.6rem
  );
  padding-right: min(
    max(1.12rem, calc(1.12rem + ((1vw - 0.32rem) * 0.6))),
    1.6rem
  );
  padding-bottom: min(
    max(1.12rem, calc(1.12rem + ((1vw - 0.32rem) * 0.6))),
    1.6rem
  );
  border-radius: 0.5rem;
}
.card-snug {
  width: 100%;
  padding-top: min(
    max(1.682rem, calc(1.682rem + ((1vw - 0.32rem) * 0.8975))),
    2.4rem
  );
  padding-left: min(
    max(1.682rem, calc(1.682rem + ((1vw - 0.32rem) * 0.8975))),
    2.4rem
  );
  padding-right: min(
    max(1.682rem, calc(1.682rem + ((1vw - 0.32rem) * 0.8975))),
    2.4rem
  );
  padding-bottom: min(
    max(1.682rem, calc(1.682rem + ((1vw - 0.32rem) * 0.8975))),
    2.4rem
  );
  border-radius: 0.5rem;
}
.card-relaxed {
  width: 100%;
  padding-top: min(
    max(3.36rem, calc(3.36rem + ((1vw - 0.32rem) * 1.8))),
    4.8rem
  );
  padding-left: min(
    max(3.36rem, calc(3.36rem + ((1vw - 0.32rem) * 1.8))),
    4.8rem
  );
  padding-right: min(
    max(3.36rem, calc(3.36rem + ((1vw - 0.32rem) * 1.8))),
    4.8rem
  );
  padding-bottom: min(
    max(3.36rem, calc(3.36rem + ((1vw - 0.32rem) * 1.8))),
    4.8rem
  );
  border-radius: 0.5rem;
}
.card-none {
  width: 100%;
  border-radius: 0.5rem;
}
.avatar {
  width: 6.4rem;
  height: 6.4rem;
  border-radius: 0.8rem;
  box-shadow: inset 0px 2px 4px 0px rgba(0, 0, 0, 0.11);
}
.avatar-sm {
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 0.8rem;
  box-shadow: inset 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}
.avatar-lg {
  width: 9.6rem;
  height: 9.6rem;
  border-radius: 0.8rem;
  box-shadow: inset 0px 2px 4px 0px rgba(0, 0, 0, 0.11);
}
.avatar-xl {
  width: 12.8rem;
  height: 12.8rem;
  border-radius: 0.8rem;
  box-shadow: inset 0px 2px 4px 0px rgba(0, 0, 0, 0.11);
}
.h1 {
  font-size: var(--h1);
  font-weight: var(--h-font-weight);
  line-height: var(--lh-h1);
}
.h2 {
  font-size: var(--h2);
  font-weight: var(--h-font-weight);
  line-height: var(--lh-h2);
}
.h3 {
  font-size: var(--h3);
  font-weight: var(--h-font-weight);
  line-height: var(--lh-h3);
}
.h4 {
  font-size: var(--h4);
  font-weight: var(--h-font-weight);
  line-height: var(--lh-h4);
}
.h5 {
  font-size: var(--h5);
  font-weight: var(--h-font-weight);
  line-height: var(--lh-h5);
}
.h6 {
  font-size: var(--h6);
  font-weight: var(--h-font-weight);
  line-height: var(--lh-h6);
}
.shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}
.shadow-none {
  box-shadow: 0 0 #0000;
}
.shadow-xs {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03);
}
.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.shadow-md {
  box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.1);
}
.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.shadow-xl {
  box-shadow: 0 0px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.shadow-2xl {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
.two-buttons-set {
}
.two-buttons-set:not(.ct-section):not(.oxy-easy-posts),
.two-buttons-set.oxy-easy-posts .oxy-posts,
.two-buttons-set.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 479px) {
  .two-buttons-set {
    width: 100%;
  }
  .two-buttons-set:not(.ct-section):not(.oxy-easy-posts),
  .two-buttons-set.oxy-easy-posts .oxy-posts,
  .two-buttons-set.ct-section .ct-section-inner-wrap {
    display: flex;
    flex-direction: column;
  }
}

.oxel_iconlist {
  max-width: 100%;
}
.oxel_iconlist__row {
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  width: 100%;
  margin-top: 0px;
}
.oxel_iconlist__row:not(.ct-section):not(.oxy-easy-posts),
.oxel_iconlist__row.oxy-easy-posts .oxy-posts,
.oxel_iconlist__row.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.oxel_iconlist__row__icon {
  color: #7f8b93;
}
.oxel_iconlist__row__icon > svg {
  width: 18px;
  height: 18px;
}
.oxel_iconlist__row__icon {
  margin-left: 32px;
  margin-right: 32px;
}
.oxel_iconlist__row__label {
}
.oxel_iconlist__row--dark {
  background-color: #eceeef;
}
.menu-wrapper {
  border-radius: 20px;
}
.foodiary-logo-white {
  width: 17.3rem;
  height: 80px;
}
@media (max-width: 767px) {
  .foodiary-logo-white {
  }
}

.footer-nav {
}
.foodiary-h1 {
}
.h1-subtitle {
  color: #142c1f;
  font-size: 3.75rem;
  font-weight: 600;
  margin-top: 1.875rem;
}
.h1-subtitle:not(.ct-section):not(.oxy-easy-posts),
.h1-subtitle.oxy-easy-posts .oxy-posts,
.h1-subtitle.ct-section .ct-section-inner-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.foodiary-list-item {
  font-size: 1.125rem;
}
.mockup-background-hover {
  position: absolute;
  z-index: 1;
  object-position: center center;
  object-fit: contain;
  bottom: 50px;
  height: 41rem;
}
@media (max-width: 479px) {
  .mockup-background-hover {
    height: 25rem;
  }
}

.app-mockup-hover {
  position: static;
  bottom: 100px;
  object-position: center center;
  height: 55rem;
  z-index: 2;
  object-fit: contain;
}
@media (max-width: 991px) {
  .app-mockup-hover {
    margin-top: min(max(8rem, calc(8rem + ((1vw - 0.32rem) * 5))), 12rem);
  }
}

@media (max-width: 479px) {
  .app-mockup-hover {
    height: 30rem;
  }
}

.app-mockup {
}
.box-white-border-box {
  background-color: #ffffff;
  border-radius: 30px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  overflow: hidden;
  text-align: center;
  width: 100%;
  box-shadow: 0px 20px 40px 0px rgba(2, 51, 42, 0.1);
}
.box-white-border-box:not(.ct-section):not(.oxy-easy-posts),
.box-white-border-box.oxy-easy-posts .oxy-posts,
.box-white-border-box.ct-section .ct-section-inner-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}
.foodiary-h2 {
  margin-bottom: 1.875rem;
}
.hover-moove-up {
}
.hover-move-up {
}
.box-white-border-8-shadow:hover {
  border-top-width: 0.125rem;
  border-right-width: 0.125rem;
  border-bottom-width: 0.125rem;
  border-left-width: 0.125rem;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: #2ecf96;
  border-right-color: #2ecf96;
  border-bottom-color: #2ecf96;
  border-left-color: #2ecf96;
  background-color: #e7faf3;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: all;
}
.box-white-border-8-shadow {
  background-color: #ffffff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  border-top-color: #ffffff;
  border-right-color: #ffffff;
  border-bottom-color: #ffffff;
  border-left-color: #ffffff;
  border-top-width: 0.125rem;
  border-right-width: 0.125rem;
  border-bottom-width: 0.125rem;
  border-left-width: 0.125rem;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: all;
  border-radius: 0.5rem;
  box-shadow: 0px 20px 40px 0px rgba(2, 51, 42, 0.1);
  cursor: pointer;
}
.box-white-border-8-shadow:not(.ct-section):not(.oxy-easy-posts),
.box-white-border-8-shadow.oxy-easy-posts .oxy-posts,
.box-white-border-8-shadow.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.hover-box-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #142c1f;
}
.hover-box-text {
  font-size: 1rem;
  font-weight: 400;
  color: #7a8780;
}
.hover-box {
  cursor: pointer;
}
.mouse-in {
}
.hover-box-wrapper {
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: all;
  box-shadow: 0px 20px 40px 0px #ffffff;
}
.clicked {
}
.box-light-green-no-shadow {
  background-color: #f0f4f3;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 30px;
  position: relative;
}
.foodiary-outline-button {
}
@media (max-width: 991px) {
  .foodiary-outline-button {
    margin-right: 2rem;
  }
}

.foodiary-green-button {
}
.home-recipe-wrapper {
  text-align: center;
  gap: 2rem;
}
.home-recipe-wrapper:not(.ct-section):not(.oxy-easy-posts),
.home-recipe-wrapper.oxy-easy-posts .oxy-posts,
.home-recipe-wrapper.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2remrem;
}
.home-recipe {
  z-index: 5;
  width: 100%;
}
.home-recipe:not(.ct-section):not(.oxy-easy-posts),
.home-recipe.oxy-easy-posts .oxy-posts,
.home-recipe.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.reviews-slider {
  position: relative;
}
.review-slider-buttons {
  position: relative;
}
.review-slide {
}
.text-20 {
  color: #44554c;
  font-size: 1.25rem;
  margin-top: 3.125rem;
}
.breakout-70 {
  width: 70vw;
  max-width: 70vw;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: calc(-35vw + 50%);
  margin-right: calc(-35vw + 50%);
}
.breakout-80 {
  width: 80vw;
  max-width: 80vw;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: calc(-40vw + 50%);
  margin-right: calc(-40vw + 50%);
}
.breakout-90 {
  width: 90vw;
  max-width: 90vw;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: calc(-45vw + 50%);
  margin-right: calc(-45vw + 50%);
}
.breakout-100 {
  width: 100vw;
  max-width: 100vw;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}
.sticky {
  position: sticky;
  top: 0;
}
.sticky-sm {
  top: 2.5%;
}
.sticky-md {
  top: 5%;
}
.sticky-lg {
  top: 10%;
}
.sticky-xl {
  top: 20%;
}
.overlay-primary:before {
  position: absolute;
  z-index: -1;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-color: rgba(var(--primary-rgb-vals), 0.7);
  content: "";
}
.overlay-primary {
  position: relative;
  z-index: 0;
}
.overlay-secondary:before {
  position: absolute;
  z-index: -1;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-color: rgba(var(--secondary-rgb-vals), 0.7);
  content: "";
}
.overlay-secondary {
  position: relative;
  z-index: 0;
}
.overlay-tertiary:before {
  position: absolute;
  z-index: -1;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-color: rgba(var(--tertiary-rgb-vals), 0.7);
  content: "";
}
.overlay-tertiary {
  position: relative;
  z-index: 0;
}
.overlay-dark:before {
  position: absolute;
  z-index: -1;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-color: rgba(var(--dark-rgb-vals), 0.7);
  content: "";
}
.overlay-dark {
  position: relative;
  z-index: 0;
}
.overlay-paragraph:before {
  position: absolute;
  z-index: -1;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-color: rgba(var(--paragraph-rgb-vals), 0.7);
  content: "";
}
.overlay-paragraph {
  position: relative;
  z-index: 0;
}
.overlay-black:before {
  position: absolute;
  z-index: -1;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-color: rgba(var(--black-rgb-vals), 0.7);
  content: "";
}
.overlay-black {
  position: relative;
  z-index: 0;
}
.overlay-success:before {
  position: absolute;
  z-index: -1;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-color: rgba(var(--success-rgb-vals), 0.7);
  content: "";
}
.overlay-success {
  position: relative;
  z-index: 0;
}
.overlay-warning:before {
  position: absolute;
  z-index: -1;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-color: rgba(var(--warning-rgb-vals), 0.7);
  content: "";
}
.overlay-warning {
  position: relative;
  z-index: 0;
}
.overlay-error:before {
  position: absolute;
  z-index: -1;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-color: rgba(var(--error-rgb-vals), 0.7);
  content: "";
}
.overlay-error {
  position: relative;
  z-index: 0;
}
.overlay-extra-1:before {
  position: absolute;
  z-index: -1;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-color: rgba(var(--extra-color-1-rgb-vals), 0.7);
  content: "";
}
.overlay-extra-1 {
  position: relative;
  z-index: 0;
}
.overlay-extra-2:before {
  position: absolute;
  z-index: -1;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-color: rgba(var(--extra-color-2-rgb-vals), 0.7);
  content: "";
}
.overlay-extra-2 {
  position: relative;
  z-index: 0;
}
.overlay-extra-3:before {
  position: absolute;
  z-index: -1;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-color: rgba(var(--extra-color-3-rgb-vals), 0.7);
  content: "";
}
.overlay-extra-3 {
  position: relative;
  z-index: 0;
}
.overlay-extra-4:before {
  position: absolute;
  z-index: -1;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  background-color: rgba(var(--extra-color-4-rgb-vals), 0.7);
  content: "";
}
.overlay-extra-4 {
  position: relative;
  z-index: 0;
}
.img-scroller:hover {
  transition-property: background-position;
  transition-duration: 5s;
  transition-timing-function: linear;
  transition-delay: 0s;
  background-position: bottom center !important;
}
.img-scroller {
  transition-property: background-position;
  transition-duration: 1.5s;
  transition-timing-function: ease-out;
  transition-delay: 0.5s;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  margin-left: auto !important;
  margin-right: auto !important;
  background-position: top center;
  background-size: 100% auto !important;
}
.inline {
  display: inline;
}
.relative {
  position: relative;
}
.bg-alt {
  background-color: var(--background-alt-color);
}
.bg-primary {
  background-color: var(--primary-color);
}
.bg-secondary {
  background-color: var(--secondary-color);
}
.bg-primary-hover {
  background-color: var(--primary-hover-color);
}
.bg-secondary-hover {
  background-color: var(--secondary-hover-color);
}
.bg-primary-alt {
  background-color: var(--primary-alt-color);
}
.bg-secondary-alt {
  background-color: var(--secondary-alt-color);
}
.bg-primary-alt-hover {
  background-color: var(--primary-alt-hover-color);
}
.bg-secondary-alt-hover {
  background-color: var(--secondary-alt-hover-color);
}
.bg-tertiary {
  background-color: var(--tertiary-color);
}
.bg-white {
  background-color: var(--white-color);
}
.bg-black {
  background-color: var(--black-color);
}
.bg-placeholder {
  background-color: var(--placeholder-color);
}
.bg-placeholder-alt {
  background-color: var(--placeholder-alt-color);
}
.border-color {
  border-top-color: var(--border-color);
  border-right-color: var(--border-color);
  border-bottom-color: var(--border-color);
  border-left-color: var(--border-color);
}
.border-color-alt {
  border-top-color: var(--border-alt-color);
  border-right-color: var(--border-alt-color);
  border-bottom-color: var(--border-alt-color);
  border-left-color: var(--border-alt-color);
}
.border-color-primary {
  border-top-color: var(--primary-color);
  border-right-color: var(--primary-color);
  border-bottom-color: var(--primary-color);
  border-left-color: var(--primary-color);
}
.border-color-secondary {
  border-top-color: var(--secondary-color);
  border-right-color: var(--secondary-color);
  border-bottom-color: var(--secondary-color);
  border-left-color: var(--secondary-color);
}
.border-color-tertiary {
  border-top-color: var(--tertiary-color);
  border-right-color: var(--tertiary-color);
  border-bottom-color: var(--tertiary-color);
  border-left-color: var(--tertiary-color);
}
.space-y-0 {
}
.space-y-1 {
}
.space-y-2 {
}
.space-y-3 {
}
.space-y-4 {
}
.space-y-6 {
}
.space-y-8 {
}
.space-y-10 {
}
.space-y-12 {
}
.space-y-14 {
}
.space-y-16 {
}
.space-y-20 {
}
.space-x-0 {
}
.space-x-1 {
}
.space-x-2 {
}
.space-x-3 {
}
.space-x-4 {
}
.space-x-6 {
}
.space-x-8 {
}
.space-x-10 {
}
.space-x-12 {
}
.space-x-14 {
}
.space-x-16 {
}
.space-x-20 {
}
.divide-x {
}
.divide-y {
}
.rounded-sm {
  border-radius: 0.2rem;
}
.rounded {
  border-radius: 0.4rem;
}
.rounded-md {
  border-radius: 0.6rem;
}
.rounded-lg {
  border-radius: 0.8rem;
}
.rounded-xl {
  border-radius: 3rem;
}
.rounded-full {
  border-radius: 999.901rem;
}
.rounded-r-sm {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.rounded-l-sm {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.rounded-b-sm {
  border-bottom-right-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.rounded-t-sm {
  border-top-right-radius: 0.2rem;
  border-top-left-radius: 0.2rem;
}
.rounded-r {
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}
.rounded-l {
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
}
.rounded-b {
  border-bottom-right-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
}
.rounded-t {
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}
.rounded-r-md {
  border-top-right-radius: 0.6rem;
  border-bottom-right-radius: 0.6rem;
}
.rounded-l-md {
  border-top-left-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
}
.rounded-b-md {
  border-bottom-right-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
}
.rounded-t-md {
  border-top-right-radius: 0.6rem;
  border-top-left-radius: 0.6rem;
}
.rounded-r-lg {
  border-top-right-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
}
.rounded-l-lg {
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
}
.rounded-b-lg {
  border-bottom-right-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
}
.rounded-t-lg {
  border-top-right-radius: 0.8rem;
  border-top-left-radius: 0.8rem;
}
.border-none {
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-right-style: solid;
}
.border-px {
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-right-style: solid;
}
.border-2 {
  border-top-width: 2px;
  border-right-width: 2px;
  border-left-width: 2px;
  border-bottom-width: 2px;
  border-left-style: solid;
  border-top-style: solid;
  border-bottom-style: solid;
  border-right-style: solid;
}
.border-t {
  border-top-width: 1px;
  border-top-style: solid;
}
.border-r {
  border-right-width: 1px;
  border-right-style: solid;
}
.border-b {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.border-l {
  border-left-width: 1px;
  border-left-style: solid;
}
.cursor-pointer {
  cursor: pointer;
}
.inline-grid {
}
.gap-px {
}
.gap-2 {
}
.gap-4 {
}
.gap-6 {
}
.gap-10 {
}
.gap-12 {
}
.gap-14 {
}
.gap-16 {
}
.gap-20 {
}
.gap-24 {
}
.col-gap-0 {
}
.col-gap-2 {
}
.col-gap-4 {
}
.col-gap-6 {
}
.col-gap-8 {
}
.col-gap-10 {
}
.col-gap-12 {
}
.col-gap-14 {
}
.col-gap-16 {
}
.col-gap-20 {
}
.col-gap-24 {
}
.row-gap-0 {
}
.row-gap-2 {
}
.row-gap-4 {
}
.row-gap-6 {
}
.row-gap-8 {
}
.row-gap-10 {
}
.row-gap-12 {
}
.row-gap-14 {
}
.row-gap-16 {
}
.row-gap-20 {
}
.row-gap-24 {
}
.grid-flow-row {
}
.grid-flow-col {
}
.grid-flow-row-dense {
}
.grid-flow-col-dense {
}
.col-end-1 {
}
.col-end-2 {
}
.col-end-3 {
}
.col-end-4 {
}
.col-end-5 {
}
.col-end-6 {
}
.col-end-7 {
}
.col-end-auto {
}
.col-start-1 {
}
.col-start-2 {
}
.col-start-3 {
}
.col-start-4 {
}
.col-start-5 {
}
.col-start-6 {
}
.col-start-7 {
}
.col-start-auto {
}
.col-auto {
}
.col-span-1 {
}
.col-span-2 {
}
.col-span-4 {
}
.col-span-5 {
}
.col-span-6 {
}
.row-auto {
}
.row-span-1 {
}
.row-span-2 {
}
.row-span-3 {
}
.row-start-1 {
}
.row-start-2 {
}
.row-start-3 {
}
.row-start-4 {
}
.row-start-auto {
}
.row-end-1 {
}
.row-end-2 {
}
.row-end-3 {
}
.row-end-4 {
}
.row-end-auto {
}
.grid-rows-1 {
}
.grid-rows-2 {
}
.grid-rows-3 {
}
.grid-rows-none {
}
.grid-cols-1 {
}
.grid-cols-3 {
}
.grid-cols-5 {
}
.grid-cols-6 {
}
.grid-60-40 {
}
.grid-40-60 {
}
.grid-33-66 {
}
.grid-66-33 {
}
.grid-75-25 {
}
.grid-25-75 {
}
.auto-grid-2 {
}
.auto-grid-3 {
}
.auto-grid-4 {
}
.grid-cols-none {
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500 !important;
}
.font-semibold {
  font-weight: 600 !important;
}
.font-bold {
  font-weight: 700 !important;
}
.h-auto {
  height: auto !important;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}
.text-xs {
}
.text-sm {
}
.text-base {
}
.text-lg {
}
.text-xl {
}
.text-2xl {
}
.text-3xl {
}
.text-4xl {
}
.text-5xl {
}
.text-6xl {
}
.text-7xl {
}
.text-8xl {
}
.text-9xl {
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.mt-auto {
  margin-top: auto;
}
.mr-0 {
  margin-right: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.ml-0 {
  margin-left: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.mr-1 {
  margin-right: var(--s-1);
}
.mb-1 {
  margin-bottom: var(--s-1);
}
.ml-1 {
  margin-left: var(--s-1);
}
.mt-1 {
  margin-top: var(--s-1);
}
.mr-2 {
  margin-right: var(--s-2);
}
.mb-2 {
  margin-bottom: var(--s-2);
}
.ml-2 {
  margin-left: var(--s-2);
}
.mt-2 {
  margin-top: var(--s-2);
}
.mr-3 {
  margin-right: var(--s-3);
}
.mb-3 {
  margin-bottom: var(--s-3);
}
.ml-3 {
  margin-left: var(--s-3);
}
.mt-3 {
  margin-top: var(--s-3);
}
.mr-4 {
  margin-right: var(--s-4);
}
.mb-4 {
  margin-bottom: var(--s-4);
}
.ml-4 {
  margin-left: var(--s-4);
}
.mt-4 {
  margin-top: var(--s-4);
}
.mr-5 {
  margin-right: var(--s-5);
}
.mb-5 {
  margin-bottom: var(--s-5);
}
.ml-5 {
  margin-left: var(--s-5);
}
.mt-5 {
  margin-top: var(--s-5);
}
.mr-6 {
  margin-right: var(--s-6);
}
.mb-6 {
  margin-bottom: var(--s-6);
}
.ml-6 {
  margin-left: var(--s-6);
}
.mt-6 {
  margin-top: var(--s-6);
}
.mr-8 {
  margin-right: var(--s-8);
}
.mb-8 {
  margin-bottom: var(--s-8);
}
.ml-8 {
  margin-left: var(--s-8);
}
.mt-8 {
  margin-top: var(--s-8);
}
.mr-10 {
  margin-right: var(--s-10);
}
.mb-10 {
  margin-bottom: var(--s-10);
}
.ml-10 {
  margin-left: var(--s-10);
}
.mt-10 {
  margin-top: var(--s-10);
}
.mr-12 {
  margin-right: var(--s-12);
}
.mb-12 {
  margin-bottom: var(--s-12);
  color: #142c1f;
}
.ml-12 {
  margin-left: var(--s-12);
}
.mt-12 {
  margin-top: var(--s-12);
}
.mr-14 {
  margin-right: var(--s-14);
}
.mb-14 {
  margin-bottom: var(--s-14);
}
.ml-14 {
  margin-left: var(--s-14);
}
.mt-14 {
  margin-top: var(--s-14);
}
.mr-16 {
  margin-right: var(--s-16);
}
.mb-16 {
  margin-bottom: var(--s-16);
}
.ml-16 {
  margin-left: var(--s-16);
}
.mt-16 {
  margin-top: var(--s-16);
}
.mr-20 {
  margin-right: var(--s-20);
}
.mb-20 {
  margin-bottom: var(--s-20);
}
.ml-20 {
  margin-left: var(--s-20);
}
.mt-20 {
  margin-top: var(--s-20);
}
.mr-24 {
  margin-right: var(--s-24);
}
.mb-24 {
  margin-bottom: var(--s-24);
}
.ml-24 {
  margin-left: var(--s-24);
}
.mt-24 {
  margin-top: var(--s-24);
}
.mr-28 {
  margin-right: var(--s-28);
}
.mb-28 {
  margin-bottom: var(--s-28);
}
.ml-28 {
  margin-left: var(--s-28);
}
.mt-28 {
  margin-top: var(--s-28);
}
.mr-32 {
  margin-right: var(--s-32);
}
.mb-32 {
  margin-bottom: var(--s-32);
}
.ml-32 {
  margin-left: var(--s-32);
}
.mt-32 {
  margin-top: var(--s-32);
}
.mr-36 {
  margin-right: var(--s-36);
}
.mb-36 {
  margin-bottom: var(--s-36);
}
.ml-36 {
  margin-left: var(--s-36);
}
.mt-36 {
  margin-top: var(--s-36);
}
.mr-40 {
  margin-right: var(--s-40);
}
.mb-40 {
  margin-bottom: var(--s-40);
}
.ml-40 {
  margin-left: var(--s-40);
}
.mt-40 {
  margin-top: var(--s-40);
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.object-contain {
  object-fit: contain;
}
.object-cover {
  object-fit: cover;
}
.object-bottom {
  object-position: bottom;
}
.object-left {
  object-position: left;
}
.object-right {
  object-position: right;
}
.object-top {
  object-position: top;
}
.bg-cover {
  background-size: cover;
}
.bg-center {
  background-position: center;
}
.opacity-0 {
  opacity: 0;
}
.opacity-25 {
  opacity: 0.25;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-75 {
  opacity: 0.75;
}
.opacity-100 {
  opacity: 1;
}
.hover-opacity-0:hover {
  opacity: 0;
}
.hover-opacity-0 {
}
.hover-opacity-25:hover {
  opacity: 0.25;
}
.hover-opacity-25 {
}
.hover-opacity-50:hover {
  opacity: 0.5;
}
.hover-opacity-50 {
}
.hover-opacity-75:hover {
  opacity: 0.75;
}
.hover-opacity-75 {
}
.hover-opacity-100:hover {
  opacity: 1;
}
.hover-opacity-100 {
}
.font-headings {
  font-family: Rubik;
}
.font-text {
  font-family: Rubik;
}
.p-0 {
  padding-top: 0rem;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-bottom: 0rem;
}
.p-1 {
  padding-top: var(--s-1) !important;
  padding-left: var(--s-1) !important;
  padding-right: var(--s-1) !important;
  padding-bottom: var(--s-1) !important;
}
.p-2 {
  padding-top: var(--s-2) !important;
  padding-left: var(--s-2) !important;
  padding-right: var(--s-2) !important;
  padding-bottom: var(--s-2) !important;
}
.p-3 {
  padding-top: var(--s-3) !important;
  padding-left: var(--s-3) !important;
  padding-right: var(--s-3) !important;
  padding-bottom: var(--s-3) !important;
}
.p-4 {
  padding-top: var(--s-4) !important;
  padding-left: var(--s-4) !important;
  padding-right: var(--s-4) !important;
  padding-bottom: var(--s-4) !important;
}
.p-5 {
  padding-top: var(--s-5) !important;
  padding-left: var(--s-5) !important;
  padding-right: var(--s-5) !important;
  padding-bottom: var(--s-5) !important;
}
.p-6 {
  padding-top: var(--s-6);
  padding-left: var(--s-6);
  padding-right: var(--s-6);
  padding-bottom: var(--s-6);
}
.p-8 {
  padding-top: var(--s-8);
  padding-left: var(--s-8);
  padding-right: var(--s-8);
  padding-bottom: var(--s-8);
}
.p-10 {
  padding-top: var(--s-10);
  padding-left: var(--s-10);
  padding-right: var(--s-10);
  padding-bottom: var(--s-10);
}
.p-12 {
  padding-top: var(--s-12);
  padding-left: var(--s-12);
  padding-right: var(--s-12);
  padding-bottom: var(--s-12);
}
.p-14 {
  padding-top: var(--s-14);
  padding-left: var(--s-14);
  padding-right: var(--s-14);
  padding-bottom: var(--s-14);
}
.p-16 {
  padding-top: var(--s-16);
  padding-left: var(--s-16);
  padding-right: var(--s-16);
  padding-bottom: var(--s-16);
}
.p-20 {
  padding-top: var(--s-20);
  padding-left: var(--s-20);
  padding-right: var(--s-20);
  padding-bottom: var(--s-20);
}
.p-24 {
  padding-top: var(--s-24);
  padding-left: var(--s-24);
  padding-right: var(--s-24);
  padding-bottom: var(--s-24);
}
.p-28 {
  padding-left: var(--s-28);
  padding-bottom: var(--s-28);
  padding-top: var(--s-28);
  padding-right: var(--s-28);
}
.p-32 {
  padding-left: var(--s-32);
  padding-top: var(--s-32);
  padding-right: var(--s-32);
  padding-bottom: var(--s-32);
}
.p-36 {
  padding-left: var(--s-36);
  padding-top: var(--s-36);
  padding-right: var(--s-36);
  padding-bottom: var(--s-36);
}
.p-40 {
  padding-left: var(--s-40);
  padding-top: var(--s-40);
  padding-right: var(--s-40);
  padding-bottom: var(--s-40);
}
.py-1 {
  padding-bottom: var(--s-1);
  padding-top: var(--s-1);
}
.px-1 {
  padding-left: var(--s-1);
  padding-right: var(--s-1);
}
.py-2 {
  padding-top: var(--s-2);
  padding-bottom: var(--s-2);
}
.px-2 {
  padding-left: var(--s-2);
  padding-right: var(--s-2);
}
.py-3 {
  padding-top: var(--s-3);
  padding-bottom: var(--s-3);
}
.px-3 {
  padding-left: var(--s-3);
  padding-right: var(--s-3);
}
.py-4 {
  padding-top: var(--s-4);
  padding-bottom: var(--s-4);
}
.px-4 {
  padding-left: var(--s-4);
  padding-right: var(--s-4);
}
.py-5 {
  padding-top: var(--s-5);
  padding-bottom: var(--s-5);
}
.px-5 {
  padding-left: var(--s-5);
  padding-right: var(--s-5);
}
.py-6 {
  padding-top: var(--s-6);
  padding-bottom: var(--s-6);
}
.px-6 {
  padding-left: var(--s-6);
  padding-right: var(--s-6);
}
.py-8 {
  padding-top: var(--s-8);
  padding-bottom: var(--s-8);
}
.px-8 {
  padding-left: var(--s-8) !important;
  padding-right: var(--s-8) !important;
}
.py-10 {
  padding-top: var(--s-10);
  padding-bottom: var(--s-10);
}
.px-10 {
  padding-left: var(--s-10);
  padding-right: var(--s-10);
}
.py-12 {
  padding-top: var(--s-12) !important;
  padding-bottom: var(--s-12) !important;
}
.px-12 {
  padding-left: var(--s-12);
  padding-right: var(--s-12);
}
.py-14 {
  padding-top: var(--s-14);
  padding-bottom: var(--s-14);
}
.px-14 {
  padding-left: var(--s-14);
  padding-right: var(--s-14);
}
.py-16 {
  padding-top: var(--s-16);
  padding-bottom: var(--s-16);
}
.px-16 {
  padding-left: var(--s-16);
  padding-right: var(--s-16);
}
.py-20 {
  padding-top: var(--s-20);
  padding-bottom: var(--s-20);
}
.px-20 {
  padding-left: var(--s-20);
  padding-right: var(--s-20);
}
.py-24 {
  padding-top: var(--s-24);
  padding-bottom: var(--s-24);
}
.px-24 {
  padding-left: var(--s-24);
  padding-right: var(--s-24);
}
.py-28 {
  padding-top: var(--s-28);
  padding-bottom: var(--s-28);
}
.px-28 {
  padding-left: var(--s-28);
  padding-right: var(--s-28);
}
.py-32 {
  padding-top: var(--s-32);
  padding-bottom: var(--s-32);
}
.px-32 {
  padding-left: var(--s-32);
  padding-right: var(--s-32);
}
.py-36 {
  padding-top: var(--s-36);
  padding-bottom: var(--s-36);
}
.px-36 {
  padding-left: var(--s-36);
  padding-right: var(--s-36);
}
.py-40 {
  padding-top: var(--s-40);
  padding-bottom: var(--s-40);
}
.px-40 {
  padding-left: var(--s-40);
  padding-right: var(--s-40);
}
.pr-1 {
  padding-right: var(--s-1);
}
.pb-1 {
  padding-bottom: var(--s-1);
}
.pl-1 {
  padding-left: var(--s-1);
}
.pt-1 {
  padding-top: var(--s-1);
}
.pr-2 {
  padding-right: var(--s-2);
}
.pb-2 {
  padding-bottom: var(--s-2);
}
.pl-2 {
  padding-left: var(--s-2);
}
.pt-2 {
  padding-top: var(--s-2);
}
.pr-3 {
  padding-right: var(--s-3);
}
.pb-3 {
  padding-bottom: var(--s-3);
}
.pl-3 {
  padding-left: var(--s-3);
}
.pt-3 {
  padding-top: var(--s-3);
}
.pr-4 {
  padding-right: var(--s-4);
}
.pb-4 {
  padding-bottom: var(--s-4);
}
.pl-4 {
  padding-left: var(--s-4);
}
.pt-4 {
  padding-top: var(--s-4);
}
.pr-5 {
  padding-right: var(--s-5);
}
.pb-5 {
  padding-bottom: var(--s-5);
}
.pl-5 {
  padding-left: var(--s-5);
}
.pt-5 {
  padding-top: var(--s-5);
}
.pr-6 {
  padding-right: var(--s-6);
}
.pb-6 {
  padding-bottom: var(--s-6);
}
.pl-6 {
  padding-left: var(--s-6);
}
.pt-6 {
  padding-top: var(--s-6);
}
.pr-8 {
  padding-right: var(--s-8);
}
.pb-8 {
  padding-bottom: var(--s-8);
}
.pl-8 {
  padding-left: var(--s-8);
}
.pt-8 {
  padding-top: var(--s-8);
}
.pr-12 {
  padding-right: var(--s-12);
}
.pb-12 {
  padding-bottom: var(--s-12);
}
.pl-12 {
  padding-left: var(--s-12);
}
.pt-12 {
  padding-top: var(--s-12);
}
.pr-16 {
  padding-right: var(--s-16);
}
.pb-16 {
  padding-bottom: var(--s-16);
}
.pl-16 {
  padding-left: var(--s-16);
}
.pt-16 {
  padding-top: var(--s-16);
}
.pr-20 {
  padding-right: var(--s-20);
}
.pb-20 {
  padding-bottom: var(--s-20);
}
.pl-20 {
  padding-left: var(--s-20);
}
.pt-20 {
  padding-top: var(--s-20);
}
.pr-24 {
  padding-right: var(--s-24);
}
.pb-24 {
  padding-bottom: var(--s-24);
}
.pl-24 {
  padding-left: var(--s-24);
}
.pt-24 {
  padding-top: var(--s-24);
}
.pr-28 {
  padding-right: var(--s-28);
}
.pb-28 {
  padding-bottom: var(--s-28);
}
.pl-28 {
  padding-left: var(--s-28);
}
.pt-28 {
  padding-top: var(--s-28);
}
.pr-32 {
  padding-right: var(--s-32);
}
.pb-32 {
  padding-bottom: var(--s-32);
}
.pl-32 {
  padding-left: var(--s-32);
}
.pt-32 {
  padding-top: var(--s-32);
}
.pr-36 {
  padding-right: var(--s-36);
}
.pb-36 {
  padding-bottom: var(--s-36);
}
.pl-36 {
  padding-left: var(--s-36);
}
.pt-36 {
  padding-top: var(--s-36);
}
.pr-40 {
  padding-right: var(--s-40);
}
.pb-40 {
  padding-bottom: var(--s-40);
}
.pl-40 {
  padding-left: var(--s-40);
}
.pt-40 {
  padding-top: var(--s-40);
}
@media screen and (max-width: 767.99px) {
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0 {
    padding-left: 0 !important;
  }
  .pr-sm-0 {
    padding-right: 0 !important;
  }
  .px-sm-0 {
    padding-inline: 0 !important;
  }
  .py-sm-0 {
    padding-block: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 991.99px) {
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0 {
    padding-left: 0 !important;
  }
  .pr-md-0 {
    padding-right: 0 !important;
  }
  .px-md-0 {
    padding-inline: 0 !important;
  }
  .py-md-0 {
    padding-block: 0 !important;
  }
}
.section-pt-0 {
}
.section-pt-sm {
}
.section-pb-0 {
}
.section-pb-sm {
}
.section-py-0 {
}
.section-py-sm {
}
.underline {
  text-decoration: underline;
}
.hover-underline:hover {
  text-decoration: underline;
}
.hover-underline {
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.centered {
  text-align: center;
}
.centered:not(.ct-section):not(.oxy-easy-posts),
.centered.oxy-easy-posts .oxy-posts,
.centered.ct-section .ct-section-inner-wrap {
  align-items: center;
  align-content: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .centered {
  }
}

.horizontal {
}
.horizontal:not(.ct-section):not(.oxy-easy-posts),
.horizontal.oxy-easy-posts .oxy-posts,
.horizontal.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: row;
}
.horizontal-wrap {
}
.horizontal-wrap:not(.ct-section):not(.oxy-easy-posts),
.horizontal-wrap.oxy-easy-posts .oxy-posts,
.horizontal-wrap.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.vertical {
}
.vertical:not(.ct-section):not(.oxy-easy-posts),
.vertical.oxy-easy-posts .oxy-posts,
.vertical.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: column;
}
.flex {
}
.flex:not(.ct-section):not(.oxy-easy-posts),
.flex.oxy-easy-posts .oxy-posts,
.flex.ct-section .ct-section-inner-wrap {
  display: flex;
}
.flex-row {
}
.flex-row:not(.ct-section):not(.oxy-easy-posts),
.flex-row.oxy-easy-posts .oxy-posts,
.flex-row.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: row;
}
.flex-col {
}
.flex-col:not(.ct-section):not(.oxy-easy-posts),
.flex-col.oxy-easy-posts .oxy-posts,
.flex-col.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-direction: column;
}
.flex-wrap {
}
.flex-wrap:not(.ct-section):not(.oxy-easy-posts),
.flex-wrap.oxy-easy-posts .oxy-posts,
.flex-wrap.ct-section .ct-section-inner-wrap {
  display: flex;
  flex-wrap: wrap;
}
.justify-start {
}
.justify-start:not(.ct-section):not(.oxy-easy-posts),
.justify-start.oxy-easy-posts .oxy-posts,
.justify-start.ct-section .ct-section-inner-wrap {
  display: flex;
  justify-content: flex-start;
}
.justify-end {
}
.justify-end:not(.ct-section):not(.oxy-easy-posts),
.justify-end.oxy-easy-posts .oxy-posts,
.justify-end.ct-section .ct-section-inner-wrap {
  display: flex;
  justify-content: flex-end;
}
.justify-center {
}
.justify-center:not(.ct-section):not(.oxy-easy-posts),
.justify-center.oxy-easy-posts .oxy-posts,
.justify-center.ct-section .ct-section-inner-wrap {
  display: flex;
  justify-content: center;
}
.justify-between {
}
.justify-between:not(.ct-section):not(.oxy-easy-posts),
.justify-between.oxy-easy-posts .oxy-posts,
.justify-between.ct-section .ct-section-inner-wrap {
  display: flex;
  justify-content: space-between;
}
.items-start {
}
.items-start:not(.ct-section):not(.oxy-easy-posts),
.items-start.oxy-easy-posts .oxy-posts,
.items-start.ct-section .ct-section-inner-wrap {
  display: flex;
  align-items: flex-start;
}
.items-end {
}
.items-end:not(.ct-section):not(.oxy-easy-posts),
.items-end.oxy-easy-posts .oxy-posts,
.items-end.ct-section .ct-section-inner-wrap {
  display: flex;
  align-items: flex-end;
}
.items-baseline {
  align-items: baseline;
}
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.self-stretch {
  align-self: stretch;
}
.items-center {
}
.items-center:not(.ct-section):not(.oxy-easy-posts),
.items-center.oxy-easy-posts .oxy-posts,
.items-center.ct-section .ct-section-inner-wrap {
  align-items: center;
}
.items-stretch {
}
.items-stretch:not(.ct-section):not(.oxy-easy-posts),
.items-stretch.oxy-easy-posts .oxy-posts,
.items-stretch.ct-section .ct-section-inner-wrap {
  align-items: stretch;
}
.content-center {
}
.content-center:not(.ct-section):not(.oxy-easy-posts),
.content-center.oxy-easy-posts .oxy-posts,
.content-center.ct-section .ct-section-inner-wrap {
  align-content: center;
}
.content-between {
}
.content-between:not(.ct-section):not(.oxy-easy-posts),
.content-between.oxy-easy-posts .oxy-posts,
.content-between.ct-section .ct-section-inner-wrap {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
}
.content-around {
}
.content-around:not(.ct-section):not(.oxy-easy-posts),
.content-around.oxy-easy-posts .oxy-posts,
.content-around.ct-section .ct-section-inner-wrap {
  display: flex;
  justify-content: space-around;
}
.hidden {
}
.hidden:not(.ct-section):not(.oxy-easy-posts),
.hidden.oxy-easy-posts .oxy-posts,
.hidden.ct-section .ct-section-inner-wrap {
  display: none;
  flex-direction: unset;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.tracking-tighter {
  letter-spacing: -0.05rem;
}
.tracking-tight {
  letter-spacing: -0.025rem;
}
.tracking-wide {
  letter-spacing: 0.05rem;
}
.tracking-wider {
  letter-spacing: 0.1rem;
}
.overflow-hidden {
  overflow: hidden;
}
.inline-block {
  display: inline-block;
}
.inline-block:not(.ct-section):not(.oxy-easy-posts),
.inline-block.oxy-easy-posts .oxy-posts,
.inline-block.ct-section .ct-section-inner-wrap {
  display: inline-block;
  flex-direction: unset;
}
.color-primary {
  color: var(--primary-color);
}
.color-secondary {
  color: var(--secondary-color);
}
.color-primary-alt {
  color: var(--primary-alt-color);
}
.color-secondary-alt {
  color: var(--secondary-alt-color);
}
.color-tertiary {
  color: var(--tertiary-color);
}
.color-dark {
  color: var(--dark-color);
}
.color-light {
  color: var(--light-color);
}
.color-paragraph {
  color: var(--paragraph-color);
}
.color-paragraph-alt {
  color: var(--paragraph-alt-color);
}
.color-black {
  color: var(--black-color);
}
.color-white {
  color: var(--white-color);
}
.order-1 {
  -webkit-order: 1;
  order: 1;
}
.order-2 {
  -webkit-order: 2;
  order: 2;
}
.order-3 {
  -webkit-order: 3;
  order: 3;
}
.order-4 {
  -webkit-order: 4;
  order: 4;
}
.order-5 {
  -webkit-order: 5;
  order: 5;
}
.order-6 {
  -webkit-order: 6;
  order: 6;
}
.order-first {
  -webkit-order: -9999;
  order: -9999;
}
.order-last {
  -webkit-order: 9999;
  order: 9999;
}
.order-none {
  -webkit-order: 0;
  order: 0;
}
.w-auto {
  width: auto;
}
.w-1by2 {
  width: 50%;
}
.w-1by3 {
  width: 33.333333%;
}
.w-2by3 {
  width: 66.666667%;
}
.w-1by4 {
  width: 25%;
}
.w-2by4 {
  width: 50%;
}
.w-3by4 {
  width: 75%;
}
.w-1by6 {
  width: 16.666667%;
}
.w-2by6 {
  width: 33.333333%;
}
.w-3by6 {
  width: 50%;
}
.w-4by6 {
  width: 66.666667%;
}
.w-5by6 {
  width: 83.333333%;
}
.w-1by5 {
  width: 20%;
}
.w-10p {
  width: 10%;
}
.w-screen {
  width: 100vw;
}
.max-w-0 {
  max-width: 0rem;
}
.max-w-xs {
  max-width: 31.9rem;
}
.max-w-sm {
  max-width: 47.9rem;
}
.max-w-md {
  max-width: 76.7rem;
}
.max-w-lg {
  max-width: 99.1rem;
}
.max-w-xl {
  max-width: 111.9rem;
}
.max-w-full {
  max-width: 100%;
}
.max-w-prose {
  max-width: 65chnone;
}
.z-99 {
  z-index: 99;
}
.blur-sm {
  filter: blur(4px);
}
.blur {
  filter: blur(8px);
}
.blur-md {
  filter: blur(12px);
}
.blur-lg {
  filter: blur(16px);
}
.blur-xl {
  filter: blur(24px);
}
.blogzine {
}
@media (max-width: 1366px) {
  .xl-breakout {
    width: 100vw;
    max-width: 100vw;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
}

.xl-sticky-off {
}
@media (max-width: 1366px) {
  .xl-sticky-off {
    position: static;
  }
}

.xl-grid-cols-1 {
}
@media (max-width: 1366px) {
  .xl-grid-cols-1 {
  }
}

.xl-grid-cols-2 {
}
@media (max-width: 1366px) {
  .xl-grid-cols-2 {
  }
}

.xl-grid-cols-3 {
}
@media (max-width: 1366px) {
  .xl-grid-cols-3 {
  }
}

.xl-grid-cols-4 {
}
@media (max-width: 1366px) {
  .xl-grid-cols-4 {
  }
}

.xl-grid-cols-5 {
}
@media (max-width: 1366px) {
  .xl-grid-cols-5 {
  }
}

.xl-grid-cols-none {
}
@media (max-width: 1366px) {
  .xl-grid-cols-none {
  }
}

.xl-col-end-1 {
}
@media (max-width: 1366px) {
  .xl-col-end-1 {
  }
}

.xl-col-end-2 {
}
@media (max-width: 1366px) {
  .xl-col-end-2 {
  }
}

.xl-col-end-3 {
}
@media (max-width: 1366px) {
  .xl-col-end-3 {
  }
}

.xl-col-end-4 {
}
@media (max-width: 1366px) {
  .xl-col-end-4 {
  }
}

.xl-col-end-5 {
}
@media (max-width: 1366px) {
  .xl-col-end-5 {
  }
}

.xl-col-end-6 {
}
@media (max-width: 1366px) {
  .xl-col-end-6 {
  }
}

.xl-col-end-auto {
}
@media (max-width: 1366px) {
  .xl-col-end-auto {
  }
}

.xl-col-start-1 {
}
@media (max-width: 1366px) {
  .xl-col-start-1 {
  }
}

.xl-col-start-2 {
}
@media (max-width: 1366px) {
  .xl-col-start-2 {
  }
}

.xl-col-start-3 {
}
@media (max-width: 1366px) {
  .xl-col-start-3 {
  }
}

.xl-col-start-4 {
}
@media (max-width: 1366px) {
  .xl-col-start-4 {
  }
}

.xl-col-start-5 {
}
@media (max-width: 1366px) {
  .xl-col-start-5 {
  }
}

.xl-col-start-6 {
}
@media (max-width: 1366px) {
  .xl-col-start-6 {
  }
}

.xl-col-start-auto {
}
@media (max-width: 1366px) {
  .xl-col-start-auto {
  }
}

.xl-col-auto {
}
@media (max-width: 1366px) {
  .xl-col-auto {
  }
}

.xl-col-span-1 {
}
@media (max-width: 1366px) {
  .xl-col-span-1 {
  }
}

.xl-col-span-2 {
}
@media (max-width: 1366px) {
  .xl-col-span-2 {
  }
}

.xl-col-span-3 {
}
@media (max-width: 1366px) {
  .xl-col-span-3 {
  }
}

.xl-col-span-4 {
}
@media (max-width: 1366px) {
  .xl-col-span-4 {
  }
}

.xl-col-span-5 {
}
@media (max-width: 1366px) {
  .xl-col-span-5 {
  }
}

.xl-row-span-1 {
}
@media (max-width: 1366px) {
  .xl-row-span-1 {
  }
}

.xl-row-span-2 {
}
@media (max-width: 1366px) {
  .xl-row-span-2 {
  }
}

.xl-row-span-3 {
}
@media (max-width: 1366px) {
  .xl-row-span-3 {
  }
}

.xl-flex {
}
@media (max-width: 1366px) {
  .xl-flex {
  }
}

.xl-h-full {
}
@media (max-width: 1366px) {
  .xl-h-full {
  }
}

.xl-h-screen {
}
@media (max-width: 1366px) {
  .xl-h-screen {
  }
}

.xl-mx-auto {
}
@media (max-width: 1366px) {
  .xl-mx-auto {
  }
}

.xl-centered {
}
@media (max-width: 1366px) {
  .xl-centered {
  }
}

.xl-horizontal {
}
@media (max-width: 1366px) {
  .xl-horizontal {
  }
}

.xl-horizontal-wrap {
}
@media (max-width: 1366px) {
  .xl-horizontal-wrap {
  }
}

.xl-vertical {
}
@media (max-width: 1366px) {
  .xl-vertical {
  }
}

.xl-hidden {
}
@media (max-width: 1366px) {
  .xl-hidden {
  }
}

.xl-w-1by2 {
}
@media (max-width: 1366px) {
  .xl-w-1by2 {
  }
}

.xl-w-1by3 {
}
@media (max-width: 1366px) {
  .xl-w-1by3 {
  }
}

.xl-w-2by3 {
}
@media (max-width: 1366px) {
  .xl-w-2by3 {
  }
}

.xl-w-1by4 {
}
@media (max-width: 1366px) {
  .xl-w-1by4 {
  }
}

.xl-w-2by4 {
}
@media (max-width: 1366px) {
  .xl-w-2by4 {
  }
}

.xl-w-3by4 {
}
@media (max-width: 1366px) {
  .xl-w-3by4 {
  }
}

.xl-w-full {
}
@media (max-width: 1366px) {
  .xl-w-full {
  }
}

@media (max-width: 991px) {
  .lg-breakout {
    width: 100vw;
    max-width: 100vw;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
}

.lg-sticky-off {
}
@media (max-width: 991px) {
  .lg-sticky-off {
    position: static;
  }
}

.lg-col-end-1 {
}
@media (max-width: 991px) {
  .lg-col-end-1 {
  }
}

.lg-col-end-2 {
}
@media (max-width: 991px) {
  .lg-col-end-2 {
  }
}

.lg-col-end-3 {
}
@media (max-width: 991px) {
  .lg-col-end-3 {
  }
}

.lg-col-end-4 {
}
@media (max-width: 991px) {
  .lg-col-end-4 {
  }
}

.lg-col-end-5 {
}
@media (max-width: 991px) {
  .lg-col-end-5 {
  }
}

.lg-col-end-auto {
}
@media (max-width: 991px) {
  .lg-col-end-auto {
  }
}

.lg-col-start-1 {
}
@media (max-width: 991px) {
  .lg-col-start-1 {
  }
}

.lg-col-start-2 {
}
@media (max-width: 991px) {
  .lg-col-start-2 {
  }
}

.lg-col-start-3 {
}
@media (max-width: 991px) {
  .lg-col-start-3 {
  }
}

.lg-col-start-4 {
}
@media (max-width: 991px) {
  .lg-col-start-4 {
  }
}

.lg-col-start-5 {
}
@media (max-width: 991px) {
  .lg-col-start-5 {
  }
}

.lg-col-start-auto {
}
@media (max-width: 991px) {
  .lg-col-start-auto {
  }
}

.lg-col-auto {
}
@media (max-width: 991px) {
  .lg-col-auto {
    grid-column: auto;
  }
}

.lg-col-span-1 {
}
@media (max-width: 991px) {
  .lg-col-span-1 {
  }
}

.lg-col-span-3 {
}
@media (max-width: 991px) {
  .lg-col-span-3 {
  }
}

.lg-col-span-4 {
}
@media (max-width: 991px) {
  .lg-col-span-4 {
  }
}

.lg-row-span-1 {
}
@media (max-width: 991px) {
  .lg-row-span-1 {
  }
}

.lg-row-span-2 {
}
@media (max-width: 991px) {
  .lg-row-span-2 {
  }
}

.lg-row-span-3 {
}
@media (max-width: 991px) {
  .lg-row-span-3 {
  }
}

.lg-grid-cols-1 {
}
@media (max-width: 991px) {
  .lg-grid-cols-1 {
  }
}

.lg-grid-cols-2 {
}
@media (max-width: 991px) {
  .lg-grid-cols-2 {
  }
}

.lg-grid-cols-4 {
}
@media (max-width: 991px) {
  .lg-grid-cols-4 {
  }
}

.lg-grid-cols-none {
}
@media (max-width: 991px) {
  .lg-grid-cols-none {
  }
}

.lg-row-start-1 {
}
@media (max-width: 991px) {
  .lg-row-start-1 {
  }
}

.lg-flex {
}
@media (max-width: 991px) {
  .lg-flex {
  }
}

.lg-order-1 {
}
@media (max-width: 991px) {
  .lg-order-1 {
  }
}

.lg-order-2 {
}
@media (max-width: 991px) {
  .lg-order-2 {
  }
}

.lg-order-3 {
}
@media (max-width: 991px) {
  .lg-order-3 {
  }
}

.lg-order-4 {
}
@media (max-width: 991px) {
  .lg-order-4 {
  }
}

.lg-order-5 {
}
@media (max-width: 991px) {
  .lg-order-5 {
  }
}

.lg-order-6 {
}
@media (max-width: 991px) {
  .lg-order-6 {
  }
}

.lg-order-first {
}
@media (max-width: 991px) {
  .lg-order-first {
  }
}

.lg-order-last {
}
@media (max-width: 991px) {
  .lg-order-last {
  }
}

.lg-order-none {
}
@media (max-width: 991px) {
  .lg-order-none {
  }
}

.lg-h-full {
}
@media (max-width: 991px) {
  .lg-h-full {
  }
}

.lg-h-screen {
}
@media (max-width: 991px) {
  .lg-h-screen {
  }
}

.lg-mr-0 {
}
@media (max-width: 991px) {
  .lg-mr-0 {
  }
}

.lg-mx-auto {
}
@media (max-width: 991px) {
  .lg-mx-auto {
  }
}

.lg-centered {
}
@media (max-width: 991px) {
  .lg-centered {
  }
}

.lg-horizontal {
}
@media (max-width: 991px) {
  .lg-horizontal {
  }
}

.lg-horizontal-reverse {
}
@media (max-width: 991px) {
  .lg-horizontal-reverse {
  }
}

.lg-horizontal-wrap {
}
@media (max-width: 991px) {
  .lg-horizontal-wrap {
  }
}

.lg-vertical {
}
@media (max-width: 991px) {
  .lg-vertical {
  }
}

.lg-vertical-reverse {
}
@media (max-width: 991px) {
  .lg-vertical-reverse {
  }
}

.lg-hidden {
}
@media (max-width: 991px) {
  .lg-hidden {
  }
}

.lg-w-1by2 {
}
@media (max-width: 991px) {
  .lg-w-1by2 {
  }
}

.lg-w-1by3 {
}
@media (max-width: 991px) {
  .lg-w-1by3 {
  }
}

.lg-w-2by3 {
}
@media (max-width: 991px) {
  .lg-w-2by3 {
  }
}

.lg-w-1by4 {
}
@media (max-width: 991px) {
  .lg-w-1by4 {
  }
}

.lg-w-2by4 {
}
@media (max-width: 991px) {
  .lg-w-2by4 {
  }
}

.lg-w-3by4 {
}
@media (max-width: 991px) {
  .lg-w-3by4 {
  }
}

.lg-w-full {
}
@media (max-width: 991px) {
  .lg-w-full {
  }
}

@media (max-width: 767px) {
  .md-breakout {
    width: 100vw;
    max-width: 100vw;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
}

.md-sticky-off {
}
@media (max-width: 767px) {
  .md-sticky-off {
    position: static;
  }
}

.md-rounded-sm {
}
@media (max-width: 767px) {
  .md-rounded-sm {
  }
}

.md-rounded {
}
@media (max-width: 767px) {
  .md-rounded {
  }
}

.md-rounded-md {
}
@media (max-width: 767px) {
  .md-rounded-md {
  }
}

.md-rounded-lg {
}
@media (max-width: 767px) {
  .md-rounded-lg {
  }
}

.md-col-end-1 {
}
@media (max-width: 767px) {
  .md-col-end-1 {
  }
}

.md-col-end-2 {
}
@media (max-width: 767px) {
  .md-col-end-2 {
  }
}

.md-col-end-3 {
}
@media (max-width: 767px) {
  .md-col-end-3 {
  }
}

.md-col-end-4 {
}
@media (max-width: 767px) {
  .md-col-end-4 {
  }
}

.md-col-end-5 {
}
@media (max-width: 767px) {
  .md-col-end-5 {
  }
}

.md-col-end-auto {
}
@media (max-width: 767px) {
  .md-col-end-auto {
  }
}

.md-col-start-1 {
}
@media (max-width: 767px) {
  .md-col-start-1 {
  }
}

.md-col-start-2 {
}
@media (max-width: 767px) {
  .md-col-start-2 {
  }
}

.md-col-start-3 {
}
@media (max-width: 767px) {
  .md-col-start-3 {
  }
}

.md-col-start-4 {
}
@media (max-width: 767px) {
  .md-col-start-4 {
  }
}

.md-col-start-5 {
}
@media (max-width: 767px) {
  .md-col-start-5 {
  }
}

.md-col-start-auto {
}
@media (max-width: 767px) {
  .md-col-start-auto {
  }
}

.md-col-auto {
}
@media (max-width: 767px) {
  .md-col-auto {
  }
}

.md-col-span-2 {
}
@media (max-width: 767px) {
  .md-col-span-2 {
  }
}

.md-col-span-3 {
}
@media (max-width: 767px) {
  .md-col-span-3 {
  }
}

.md-col-span-4 {
}
@media (max-width: 767px) {
  .md-col-span-4 {
  }
}

.md-row-span-1 {
}
@media (max-width: 767px) {
  .md-row-span-1 {
  }
}

.md-row-span-2 {
}
@media (max-width: 767px) {
  .md-row-span-2 {
  }
}

.md-row-span-3 {
}
@media (max-width: 767px) {
  .md-row-span-3 {
  }
}

.md-grid-cols-2 {
}
@media (max-width: 767px) {
  .md-grid-cols-2 {
  }
}

.md-grid-cols-3 {
}
@media (max-width: 767px) {
  .md-grid-cols-3 {
  }
}

.md-grid-cols-4 {
}
@media (max-width: 767px) {
  .md-grid-cols-4 {
  }
}

.md-grid-cols-none {
}
@media (max-width: 767px) {
  .md-grid-cols-none {
  }
}

.md-row-start-1 {
}
@media (max-width: 767px) {
  .md-row-start-1 {
  }
}

.md-flex {
}
@media (max-width: 767px) {
  .md-flex {
  }
}

.md-order-1 {
}
@media (max-width: 767px) {
  .md-order-1 {
  }
}

.md-order-2 {
}
@media (max-width: 767px) {
  .md-order-2 {
  }
}

.md-order-3 {
}
@media (max-width: 767px) {
  .md-order-3 {
  }
}

.md-order-4 {
}
@media (max-width: 767px) {
  .md-order-4 {
  }
}

.md-order-5 {
}
@media (max-width: 767px) {
  .md-order-5 {
  }
}

.md-order-6 {
}
@media (max-width: 767px) {
  .md-order-6 {
  }
}

.md-order-first {
}
@media (max-width: 767px) {
  .md-order-first {
  }
}

.md-order-last {
}
@media (max-width: 767px) {
  .md-order-last {
  }
}

.md-order-none {
}
@media (max-width: 767px) {
  .md-order-none {
  }
}

.md-h-full {
}
@media (max-width: 767px) {
  .md-h-full {
  }
}

.md-h-screen {
}
@media (max-width: 767px) {
  .md-h-screen {
  }
}

.md-ml-auto {
}
@media (max-width: 767px) {
  .md-ml-auto {
    margin-left: auto;
  }
}

.md-mr-auto {
}
@media (max-width: 767px) {
  .md-mr-auto {
  }
}

.md-mr-0 {
}
@media (max-width: 767px) {
  .md-mr-0 {
  }
}

.md-mb-0 {
}
@media (max-width: 767px) {
  .md-mb-0 {
  }
}

.md-mx-auto {
}
@media (max-width: 767px) {
  .md-mx-auto {
  }
}

.md-centered {
}
@media (max-width: 767px) {
  .md-centered {
  }
}

.md-horizontal {
}
@media (max-width: 767px) {
  .md-horizontal {
  }
}

.md-horizontal-reverse {
}
@media (max-width: 767px) {
  .md-horizontal-reverse {
  }
}

.md-horizontal-wrap {
}
@media (max-width: 767px) {
  .md-horizontal-wrap {
  }
}

.md-vertical {
}
@media (max-width: 767px) {
  .md-vertical {
  }
}

.md-vertical-reverse {
}
@media (max-width: 767px) {
  .md-vertical-reverse {
  }
}

.md-hidden {
}
@media (max-width: 767px) {
  .md-hidden {
  }
}

.md-w-1by2 {
}
@media (max-width: 767px) {
  .md-w-1by2 {
  }
}

.md-w-1by3 {
}
@media (max-width: 767px) {
  .md-w-1by3 {
  }
}

.md-w-2by3 {
}
@media (max-width: 767px) {
  .md-w-2by3 {
  }
}

.md-w-full {
}
@media (max-width: 767px) {
  .md-w-full {
  }
}

@media (max-width: 479px) {
  .sm-breakout {
    width: 100vw;
    max-width: 100vw;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
}

.sm-sticky-off {
}
@media (max-width: 479px) {
  .sm-sticky-off {
    position: static;
  }
}

.sm-col-end-1 {
}
@media (max-width: 479px) {
  .sm-col-end-1 {
  }
}

.sm-col-end-2 {
}
@media (max-width: 479px) {
  .sm-col-end-2 {
  }
}

.sm-col-end-3 {
}
@media (max-width: 479px) {
  .sm-col-end-3 {
  }
}

.sm-col-end-4 {
}
@media (max-width: 479px) {
  .sm-col-end-4 {
  }
}

.sm-col-end-auto {
}
@media (max-width: 479px) {
  .sm-col-end-auto {
  }
}

.sm-col-start-1 {
}
@media (max-width: 479px) {
  .sm-col-start-1 {
  }
}

.sm-col-start-2 {
}
@media (max-width: 479px) {
  .sm-col-start-2 {
  }
}

.sm-col-start-3 {
}
@media (max-width: 479px) {
  .sm-col-start-3 {
  }
}

.sm-col-start-4 {
}
@media (max-width: 479px) {
  .sm-col-start-4 {
  }
}

.sm-col-start-auto {
}
@media (max-width: 479px) {
  .sm-col-start-auto {
  }
}

.sm-col-auto {
}
@media (max-width: 479px) {
  .sm-col-auto {
  }
}

.sm-col-span-1 {
}
@media (max-width: 479px) {
  .sm-col-span-1 {
  }
}

.sm-col-span-2 {
}
@media (max-width: 479px) {
  .sm-col-span-2 {
  }
}

.sm-col-span-3 {
}
@media (max-width: 479px) {
  .sm-col-span-3 {
  }
}

.sm-row-span-1 {
}
@media (max-width: 479px) {
  .sm-row-span-1 {
  }
}

.sm-row-span-2 {
}
@media (max-width: 479px) {
  .sm-row-span-2 {
  }
}

.sm-row-span-3 {
}
@media (max-width: 479px) {
  .sm-row-span-3 {
  }
}

.sm-grid-cols-1 {
}
@media (max-width: 479px) {
  .sm-grid-cols-1 {
  }
}

.sm-grid-cols-2 {
}
@media (max-width: 479px) {
  .sm-grid-cols-2 {
  }
}

.sm-grid-cols-3 {
}
@media (max-width: 479px) {
  .sm-grid-cols-3 {
  }
}

.sm-grid-cols-none {
}
@media (max-width: 479px) {
  .sm-grid-cols-none {
  }
}

.sm-flex {
}
@media (max-width: 479px) {
  .sm-flex {
  }
}

.sm-order-1 {
}
@media (max-width: 479px) {
  .sm-order-1 {
  }
}

.sm-order-2 {
}
@media (max-width: 479px) {
  .sm-order-2 {
  }
}

.sm-order-3 {
}
@media (max-width: 479px) {
  .sm-order-3 {
  }
}

.sm-order-4 {
}
@media (max-width: 479px) {
  .sm-order-4 {
  }
}

.sm-order-5 {
}
@media (max-width: 479px) {
  .sm-order-5 {
  }
}

.sm-order-6 {
}
@media (max-width: 479px) {
  .sm-order-6 {
  }
}

.sm-order-first {
}
@media (max-width: 479px) {
  .sm-order-first {
  }
}

.sm-order-last {
}
@media (max-width: 479px) {
  .sm-order-last {
  }
}

.sm-order-none {
}
@media (max-width: 479px) {
  .sm-order-none {
  }
}

.sm-h-full {
}
@media (max-width: 479px) {
  .sm-h-full {
  }
}

.sm-h-screen {
}
@media (max-width: 479px) {
  .sm-h-screen {
  }
}

.sm-mt-0 {
}
@media (max-width: 479px) {
  .sm-mt-0 {
  }
}

.sm-mr-0 {
}
@media (max-width: 479px) {
  .sm-mr-0 {
  }
}

.sm-mr-auto {
}
@media (max-width: 479px) {
  .sm-mr-auto {
  }
}

.sm-ml-auto {
}
@media (max-width: 479px) {
  .sm-ml-auto {
  }
}

.sm-mx-auto {
}
@media (max-width: 479px) {
  .sm-mx-auto {
  }
}

.sm-centered {
}
@media (max-width: 479px) {
  .sm-centered {
  }
}

.sm-horizontal {
}
@media (max-width: 479px) {
  .sm-horizontal {
  }
}

.sm-horizontal-reverse {
}
@media (max-width: 479px) {
  .sm-horizontal-reverse {
  }
}

.sm-horizontal-wrap {
}
@media (max-width: 479px) {
  .sm-horizontal-wrap {
  }
}

.sm-vertical {
}
@media (max-width: 479px) {
  .sm-vertical {
  }
}

.sm-vertical-reverse {
}
@media (max-width: 479px) {
  .sm-vertical-reverse {
  }
}

.sm-hidden {
}
@media (max-width: 479px) {
  .sm-hidden {
  }
}

.sm-w-1by2 {
}
@media (max-width: 479px) {
  .sm-w-1by2 {
  }
}

.sm-w-full {
}
@media (max-width: 479px) {
  .sm-w-full {
  }
}

.hover-bg-primary {
}
.hover-bg-secondary {
}
.hover-bg {
}
.hover-bg-dark {
}
.hover-bg-light {
}
.hover-bg-alt {
}
.hover-bg-white {
}
.hover-bg-black {
}
.hover-bg-placeholder {
}
.hover-bg-placeholder-alt {
}
.hover-color-dark {
}
.hover-color-light {
}
.hover-color-primary {
}
.hover-color-paragraph {
}
.hover-color-paragraph-alt {
}
.hover-border-primary {
}
.hover-border-secondary {
}
.hover-border-dark {
}
.hover-border-light {
}
.hover-border {
}
.hover-border-alt {
}
.auto-grid-5 {
}
.pz-6 {
}
.text-s {
}
.absolute-tr {
  position: absolute;
  top: -1rem;
  right: -9rem;
}
@media (max-width: 1366px) {
  .absolute-tr {
    top: -8.5rem;
  }
}

@media (max-width: 767px) {
  .absolute-tr {
    top: -4rem;
  }
}

@media (max-width: 479px) {
  .absolute-tr {
    right: 0.5rem;
    top: -8.5rem;
  }
}

.center {
}
.hover-float:active {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
}
.hover-float:focus {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
}
.hover-float:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
}
.hover-float {
  transform: perspective(1px);
  transition-duration: 0.3s;
  transition-property: all;
  transition-timing-function: ease-out;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: transform;
  -webkit-transition-timing-function: ease-out;
}
.hover-float:locked {
}
.hover-float-shadow:active:before {
  transform: translateY(3px);
  opacity: 1;
  -webkit-transform: translateY(3px);
}
.hover-float-shadow:focus:before {
  transform: translateY(3px);
  opacity: 1;
  -webkit-transform: translateY(3px);
}
.hover-float-shadow:hover:before {
  transform: translateY(3px);
  opacity: 1;
  -webkit-transform: translateY(3px);
}
.hover-float-shadow:active {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
}
.hover-float-shadow:focus {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
}
.hover-float-shadow:hover {
  transform: translateY(-3px);
  -webkit-transform: translateY(-3px);
}
.hover-float-shadow:before {
  position: absolute;
  z-index: -1;
  top: 100%;
  height: 10px;
  left: 5%;
  width: 90%;
  opacity: 0;
  transition-duration: 0.3s;
  transition-property: all;
  content: "";
  pointer-events: none;
  background: -webkit-radial-gradient(
    center,
    ellipse,
    rgba(17, 17, 26, 0.15) 0%,
    rgba(17, 17, 26, 0) 80%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(17, 17, 26, 0.15) 0%,
    rgba(17, 17, 26, 0) 80%
  );
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: transform, opacity;
}
.hover-float-shadow {
  transform: perspective(1px);
  position: relative;
  transition-duration: 0.3s;
  transition-property: all;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  -webkit-transition-duration: 0.3s;
  -webkit-transition-property: transform;
}
.hover-float-shadow:locked {
}
.font-light {
}
.first-section {
}
.mobile-nav-icon {
}
.w-ful {
}
.mobile-nav-div {
}
.akkordeon-item {
}
.active {
}
.accordion-title {
}
.accordion-icon {
}
.panel {
}
.active-text {
}
.pr-10 {
}
.gap-5 {
}
.pt-10 {
}
.partner-switcher {
  padding-left: 50%;
  width: 100%;
}
.partner-slide {
}
.rounded-video {
}
.mb12 {
}
.pb-10 {
}
.xl-order-1 {
}
.xl-order-2 {
}
.pl-10 {
}
.cmplz-btn.cmplz-manage-consent.manage-consent-2.cmplz-show {
  display: none !important;
}
html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --foodiary-green: #1c9d87;
}

@media only screen and (max-width: 1280px) {
  html {
    font-size: 10px;
  }
}

@media only screen and (max-width: 1920px) {
  html {
    font-size: 10px;
  }
}
@media (-webkit-device-pixel-ratio: 2) {
  html {
    font-size: 50%;
  }
}

@media (-webkit-device-pixel-ratio: 1.25) {
  html {
    font-size: 50%;
  }
}

@media (-webkit-device-pixel-ratio: 1.5) {
  html {
    font-size: 40%;
  }
}

@media (-webkit-device-pixel-ratio: 1.76) {
  html {
    font-size: 30%;
  }
}

/* Erste Sektion mit Padding */
.ct-inner-content section:first-of-type {
  padding-top: 10rem;
  z-index: 1;
}

.menu-item {
  color: #44554c;
  font-size: 2rem;
  font-weight: 400;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
}
.box-white-border-box {
  background-color: #ffffff;
  border-radius: 30px;
  overflow: hidden;
  text-align: center;
  width: 100%;
  box-shadow: 0px 2rem 2.5rem 0px rgba(2, 51, 42, 0.1);
}
.sticky {
  position: fixed;
  top: 3rem;
  background-color: white;
  transition: all 0.2s ease-in-out;
  box-shadow: #142c1f14 0px 5px 10px;
}
.menu-wrapper {
  transition: all 0.2s ease-in-out;
  z-index: 99;
}
.menu-wrapper:hover {
  background-color: white;
  transition: all 0.2s ease-in-out;
  box-shadow: #142c1f14 0px 5px 10px;
}
.menu-item:hover {
  color: #142c1f;
  transition: all 0.2s ease-in-out;
}
.submenu-item {
  color: #44554c;
  font-size: 2rem;
  font-weight: 400;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
  width: max-content;
}
.submenu-item:hover {
  color: #142c1f;
  transition: all 0.2s ease-in-out;
}
.menu-item:hover:after {
  opacity: 1;
}
.submenu-item:hover:before {
  opacity: 1;
}
.menu-item::after {
  /* content: url("assets/data/Menu-Item-Underline-Hover.svg");
  background-image: url("assets/data/Menu-Item-Underline-Hover.svg");
  width: 3rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(50% - 3rem);
  opacity: 0;
  content: "";
  height: 3px;
  background-size: 3rem 0.64rem;
  background-repeat: no-repeat;
  background-position: center center; */
}
.submenu-item::before {
  content: url("assets/data/Menu-Item-Underline-Hover.svg");
  width: 0px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -10px;
  transform: translateY(50%) rotate(90deg) scale(0.5);
  bottom: 50%;
  opacity: 0;
  transition: all 0.1s ease-in-out;
}
.current-menu-item {
  color: #142c1f;
  font-weight: 400;
  position: relative;
}
.current-menu-item-sub {
  color: #142c1f;
  font-weight: 400;
  position: relative;
}
.current-menu-item::after {
  background-image: url("assets/data/Menu-Item-Underline.svg");
  width: 3rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  bottom: calc(50% - 3rem);
  opacity: 1 !important;
  content: "";
  height: 3px;
  background-size: 3rem 0.4rem;
  background-repeat: no-repeat;
  background-position: center center;
}
.current-menu-item-sub::before {
  content: url("assets/data/Menu-Item-Underline.svg");
  width: 0px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -10px;
  transform: translateY(50%) rotate(90deg) scale(0.5);
  bottom: 50%;
  opacity: 1 !important;
  transition: all 0.1s ease-in-out;
}
.menu-item-wrapper {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
#menu-items-wrapper {
  display: flex;
  gap: 4rem;
}
.submenu-wrapper {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -3rem;
  top: calc(100% + 2rem);
  opacity: 0;
  background-color: #fff;
  border-radius: 0rem 0rem 2rem 2rem;
  box-shadow: #142c1f14 0rem 0.5rem 1rem;
  z-index: -1;
  transition: all 0.2s ease-in-out;
  gap: 2rem;
  transform: scale(0) translateY(-100%);
}
.mouse-over .submenu-wrapper {
  opacity: 1;
  z-index: 98;
  transition: all 0.2s ease-in-out;
  transform: scale(1) translateY(0%);
}
.submenu-button-wrapper {
  padding-top: 1.5625rem;
  border-top: 1px solid #dddddd;
}
.submenu-item-button {
  border: 2px solid #25a08b;
  padding: 1rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #25a08b;
  border-radius: 1rem;
  font-size: 2rem;
  font-weight: 600;
  width: max-content;
  transition: all 0.2s ease-in-out;
}
.submenu-item-button:hover {
  border: 2px solid #25a08b;
  background-color: #25a08b;
  padding: 1rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 1rem;
  font-weight: 600;
  width: max-content;
  transition: all 0.2s ease-in-out;
}

/*Mobile Nav*/

.mobile-submenu-wrapper {
  height: 0;
  display: none;
  align-items: baseline;
}

#mobile-nav {
  gap: 1rem;
  padding: 0rem 3rem;
}
.mobile-menu-item-wrapper {
  padding: 1rem 0rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  min-width: 100%;
}
.mobile-submenu-indicator {
  position: absolute;
  right: 0;
  top: -1rem;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  height: 1rem;
  padding: 1rem 1rem;
  display: flex;
}

.mobile-submenu-indicator-close {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 1rem;
  padding: 1rem 1rem;
  display: none;
  top: 0;
}
.current-menu-item .mobile-submenu-wrapper {
  margin-top: 3rem;
}

#mobile-nav-div {
  transition: all 0.2s ease-in-out;
}

.submenu-open .mobile-submenu-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  margin-top: 1rem;
  gap: 0.5rem;
}
.current-menu-item ~ .mobile-submenu-wrapper {
  margin-top: 3rem;
}
.submenu-open #mobile-submenu-indicator {
  display: none;
}
.submenu-open #mobile-submenu-indicator-close {
  display: block;
}
.foodiary-outline-button {
  border: 2px solid #25a08b;
  padding: 1.5rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #25a08b;
  border-radius: 1rem;
  font-size: 1.6rem;
  font-weight: 600;
  width: max-content;
  transition: all 0.2s ease-in-out;
  background-color: #ffffff;
  cursor: pointer;
}
.foodiary-outline-button:hover {
  border: 2px solid #25a08b;
  background-color: #25a08b;
  padding: 1.5rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 1rem;
  font-weight: 600;
  width: max-content;
  transition: all 0.2s ease-in-out;
  transform: translateY(-2px);
}
.foodiary-green-button {
  border: none;
  cursor: pointer;
  padding: 1.7rem 3rem;
  background: linear-gradient(110deg, #2ecf96 0%, #1c9d87 100%);
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 1rem;
  font-size: 1.6rem;
  font-weight: 600;
  width: max-content;
  transition: all 0.2s ease-in-out;
}
.foodiary-green-button:hover {
  background: linear-gradient(110deg, #2ecf96 0%, #1c9d87 100%);
  background-size: 150%;
  transform: translateY(-2px);
}

.foodiary-green-button:disabled {
  background: lightgray;
  color: black;
}

.menu-item-button {
  padding: 1rem 3rem;
  background: linear-gradient(110deg, #2ecf96 0%, #1c9d87 100%);
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 2rem;
  border-radius: 1rem;
  font-weight: 600;
  width: max-content;
  transition: all 0.2s ease-in-out;
}

.menu-item-button:hover {
  background: linear-gradient(110deg, #2ecf96 0%, #1c9d87 100%);
  background-size: 150%;
  padding: 1rem 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 1rem;
  font-weight: 600;
  width: max-content;
  transition: all 0.2s ease-in-out;
}
.foodiary-logo-white {
  background-color: white;
  -webkit-mask: url(assets/data/foodiary-logo.svg) no-repeat center;
  mask: url(assets/data/foodiary-logo.svg) no-repeat center;
}

.footer-nav {
  align-items: stretch;
  grid-template-columns: repeat(3, minmax(12.5rem, 1fr));
  grid-column-gap: 1.25rem;
  grid-row-gap: 1.25rem;
  width: 100%;
  display: grid;
}
.footer-menu-heading {
  font-size: 3rem;
  color: #fff;
  font-weight: 300;
}
.footer-submenu-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 1.875rem;
  gap: 1rem;
}
.footer-submenu-item {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 400;
  opacity: 0.7;
  transition: all 0.2s ease-in-out;
}
.footer-submenu-item:hover {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
.app-mockup:hover .app-mockup-hover {
  transform: scale(1.03);
  transition: all 0.2s ease-in-out;
}
.app-mockup:hover .mockup-background-hover {
  transform: translateX(10px);
  transition: all 0.2s ease-in-out;
}
.mockup-background-hover,
.app-mockup-hover {
  transition: all 0.2s ease-in-out;
}

.hover-move-up {
  transition: all 0.2s ease-in-out;
}
.hover-move-up:hover {
  transform: translateY(-5px) !important;
  transition: all 0.2s ease-in-out;
}

/*Hover Boxen App Seite*/
.clicked img {
  opacity: 1 !important;
  transition: all 0.2s ease-in-out;
}
.clicked .hover-box-wrapper {
  box-shadow: 0px 20px 40px 0px rgba(2, 51, 42, 0.1);
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
}
.clicked #hover-box-circle {
  fill: #2ecf96;
  transition: all 0.2s ease-in-out;
}
.clicked #hover-box-icon {
  fill: #fff;
  transition: all 0.2s ease-in-out;
}
.mouse-in #hover-box-circle {
  fill: #2ecf96;
  transition: all 0.2s ease-in-out;
}
.mouse-in #hover-box-icon {
  fill: #fff;
  transition: all 0.2s ease-in-out;
}

#hover-box-circle,
#hover-box-icon {
  transition: all 0.2s ease-in-out;
}
#hover-box-icon {
  fill: #1c9d87;
}
#hover-box-circle {
  fill: #e5f5f2;
}
.hover-box-wrapper img {
  pointer-events: none;
}
.hover-box-wrapper,
.home-recipe {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.home-recipe-wrapper .home-recipe:nth-of-type(1n) {
  transform: scale(0.9);
  margin-left: -7rem;
}
.home-recipe-wrapper .home-recipe:nth-of-type(2n) {
  transform: scale(1);
  margin-left: 0rem;
}
.home-recipe-wrapper .home-recipe:nth-of-type(3n) {
  transform: scale(0.9);
  margin-left: -2.5re;
}
.reviews-slider {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.reviews-slider::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 992px) {
  .clicked img {
    opacity: 1 !important;
    transition: all 0.2s ease-in-out;
    bottom: 2rem !important;
    height: 30rem;
    object-fit: contain !important;
    max-width: 100%;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    top: unset !important;
  }
  .clicked .hover-box-wrapper {
    padding-bottom: 34rem !important;
    position: relative !important;
  }
  .home-recipe-wrapper .home-recipe:nth-of-type(1n) {
    transform: scale(1);
    margin-left: 0rem;
  }
  .home-recipe-wrapper .home-recipe:nth-of-type(2n) {
    transform: scale(1);
    margin-left: 0rem;
  }
  .home-recipe-wrapper .home-recipe:nth-of-type(3n) {
    transform: scale(1);
    margin-left: 0rem;
  }
  .menu-item {
    color: #44554c;
    font-size: 1.8rem;
    font-weight: 400;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-in-out;
  }
  .footer-nav {
    align-items: stretch;
    grid-template-columns: repeat(1, minmax(12.5rem, 1fr));
    grid-column-gap: 1.25rem;
    grid-row-gap: 4.25rem;
    width: 100%;
    display: grid;
  }
  .current-menu-item::after {
    background-image: url("assets/data/Menu-Item-Underline.svg") !important;
    width: 3rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0rem;
    transform: translateX(0%);
    bottom: calc(50% - 3rem);
    opacity: 1 !important;
    content: "";
    height: 3px;
    background-size: 3rem 0.4rem;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .menu-item::after {
    content: url("assets/data/Menu-Item-Underline-Hover.svg");
    background-image: url("assets/data/Menu-Item-Underline-Hover.svg");
    width: 3rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0rem;
    transform: translateX(0%);
    bottom: calc(50% - 3rem);
    opacity: 0;
    content: "";
    height: 3px;
    background-size: 3rem 0.64rem;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .mobile-menu-item-wrapper:has(.foodiary-outline-button) {
    padding-top: 3rem;
    border-top: 1px solid #ddd;
  }
  .-mobile-submenu-item {
    color: var(--paragraph-color);
    margin-left: 0.5rem;
  }
}

@media only screen and (max-width: 933px) {
  #menu-items-wrapper {
    display: none !important;
  }
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Fluid typography settings */

.hero {
  font-size: var(--hero);
  line-height: var(--lh-hero);
}
.h1 {
  font-size: var(--h1);
  line-height: var(--lh-h1);
  font-weight: var(--h-font-weight);
}
.h2 {
  font-size: var(--h2);
  line-height: var(--lh-h2);
  font-weight: var(--h-font-weight);
}
.h3 {
  font-size: var(--h3);
  line-height: var(--lh-h3);
  font-weight: var(--h-font-weight);
}
.h4 {
  font-size: var(--h4);
  line-height: var(--lh-h4);
  font-weight: var(--h-font-weight);
}
.h5 {
  font-size: var(--h5);
  line-height: var(--lh-h5);
  font-weight: var(--h-font-weight);
}
.h6 {
  font-size: var(--h6);
  line-height: var(--lh-h6);
  font-weight: var(--h-font-weight);
}

.text-xs {
  font-size: var(--text-xs);
  line-height: var(--lh-xs);
}
.text-sm {
  font-size: var(--text-sm);
  line-height: var(--lh-sm);
}
.text-base {
  font-size: var(--text-base);
  line-height: var(--lh-base);
}
.text-lg {
  font-size: var(--text-lg);
  line-height: var(--lh-lg);
}
.text-xl {
  font-size: var(--text-xl);
  line-height: var(--lh-xl);
}
.text-2xl {
  font-size: var(--text-2xl);
  line-height: var(--lh-2xl);
}
.text-3xl {
  font-size: var(--text-3xl);
  line-height: var(--lh-3xl);
}
.text-4xl {
  font-size: var(--text-4xl);
  line-height: var(--lh-4xl);
}
.text-5xl {
  font-size: var(--text-5xl);
  line-height: var(--lh-5xl);
}
.text-6xl {
  font-size: var(--text-6xl);
  line-height: var(--lh-6xl);
}
.text-7xl {
  font-size: var(--text-7xl);
  line-height: var(--lh-7xl);
}
.text-8xl {
  font-size: var(--text-8xl);
  line-height: var(--lh-8xl);
}
.text-9xl {
  font-size: var(--text-9xl);
  line-height: var(--lh-9xl);
}

/* Ending of fluid typography settings */

/* BlogZine Styles Start */

a.oxy-post-title,
a.alt-oxy-post-title {
  transition: color 0.5s linear;
}

a.oxy-post-title:hover,
a.alt-oxy-post-title:hover {
  background-position: 0 100%;
}

.btn-l .ct-fancy-icon svg {
  height: 18px;
  width: 18px;
}

.btn-m .ct-fancy-icon svg {
  height: 16px;
  width: 16px;
}

.btn-s .ct-fancy-icon svg {
  height: 14px;
  width: 14px;
}

/* For <a> tag color */

.color-primary a,
.color-secondary a,
.color-tertiary a,
.color-dark a,
.color-light a,
.color-paragraph a,
.color-paragraph-alt a,
.color-black a,
.color-white a {
  color: inherit;
}

a.color-primary {
  color: var(--primary-color);
}
a.color-secondary {
  color: var(--secondary-color);
}
a.color-tertiary {
  color: var(--tertiary-color);
}
a.color-dark {
  color: var(--dark-color);
}
a.color-light {
  color: var(--light-color);
}
a.color-paragraph {
  color: var(--paragraph-color);
}
a.color-paragraph-alt {
  color: var(--paragraph-alt-color);
}
a.color-black {
  color: var(--black-color);
}
a.color-white {
  color: var(--white-color);
}

/* Image zoom */

.oxy-post-image,
.alt-oxy-post-image {
  overflow: hidden;
}

.oxy-post-image-fixed-ratio,
.alt-oxy-post-image-fixed-ratio {
  transition: all 1s;
}

.oxy-post-image-fixed-ratio:hover,
.alt-oxy-post-image-fixed-ratio:hover {
  transform: scale(1.2);
}

.font-light {
  font-weight: 300;
}

/* Post Translate Y Animation */

article.oxy-post,
article.alt-oxy-post {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-transition: transform 0.5s ease-out;
  -moz-transition: transform 0.5s ease-out;
  -o-transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
}

article.oxy-post:hover,
article.oxy-post:focus,
article.oxy-post:active,
article.alt-oxy-post:hover,
article.alt-oxy-post:focus,
article.alt-oxy-post:active {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}

/* BlogZine Styles End */

.grid {
  display: grid;
}
.gap-2 {
  gap: 0.8rem;
  gap: min(max(0.56rem, calc(0.56rem + ((1vw - 0.32rem) * 0.2857))), 0.8rem);
}
.gap-4 {
  gap: 1.6rem;
  gap: min(max(1.12rem, calc(1.12rem + ((1vw - 0.32rem) * 0.5714))), 1.6rem);
}
.gap-6 {
  gap: 2.4rem;
  gap: min(max(1.68rem, calc(1.68rem + ((1vw - 0.32rem) * 0.8571))), 2.4rem);
}
.gap-8 {
  gap: 3.2rem;
  gap: min(max(2.24rem, calc(2.24rem + ((1vw - 0.32rem) * 1.1429))), 3.2rem);
}
.gap-10 {
  gap: 4rem;
  gap: min(max(2.8rem, calc(2.8rem + ((1vw - 0.32rem) * 1.4286))), 4rem);
}
.gap-12 {
  gap: 4.8rem;
  gap: min(max(3.36rem, calc(3.36rem + ((1vw - 0.32rem) * 1.7143))), 4.8rem);
}
.gap-14 {
  gap: 5.6rem;
  gap: min(max(3.92rem, calc(3.92rem + ((1vw - 0.32rem) * 2))), 5.6rem);
}
.gap-16 {
  gap: 6.4rem;
  gap: min(max(4.48rem, calc(4.48rem + ((1vw - 0.32rem) * 2.2857))), 6.4rem);
}
.gap-20 {
  gap: 8rem;
  gap: min(max(5.6rem, calc(5.6rem + ((1vw - 0.32rem) * 2.8571))), 8rem);
}
.gap-24 {
  gap: 9.6rem;
  gap: min(max(6.72rem, calc(6.72rem + ((1vw - 0.32rem) * 3.4286))), 9.6rem);
}
.gap-px {
  gap: 1px;
}
.col-gap-0 {
  column-gap: 0rem;
}
.col-gap-2 {
  column-gap: 0.8rem;
  column-gap: min(
    max(0.56rem, calc(0.56rem + ((1vw - 0.32rem) * 0.2857))),
    0.8rem
  );
}
.col-gap-4 {
  column-gap: 1.6rem;
  column-gap: min(
    max(1.12rem, calc(1.12rem + ((1vw - 0.32rem) * 0.5714))),
    1.6rem
  );
}
.col-gap-6 {
  column-gap: 2.4rem;
  column-gap: min(
    max(1.68rem, calc(1.68rem + ((1vw - 0.32rem) * 0.8571))),
    2.4rem
  );
}
.col-gap-8 {
  column-gap: 3.2rem;
  column-gap: min(
    max(2.24rem, calc(2.24rem + ((1vw - 0.32rem) * 1.1429))),
    3.2rem
  );
}
.col-gap-10 {
  column-gap: 4rem;
  column-gap: min(max(2.8rem, calc(2.8rem + ((1vw - 0.32rem) * 1.4286))), 4rem);
}
.col-gap-12 {
  column-gap: 4.8rem;
  column-gap: min(
    max(3.36rem, calc(3.36rem + ((1vw - 0.32rem) * 1.7143))),
    4.8rem
  );
}
.col-gap-14 {
  column-gap: 5.6rem;
  column-gap: min(max(3.92rem, calc(3.92rem + ((1vw - 0.32rem) * 2))), 5.6rem);
}
.col-gap-16 {
  column-gap: 6.4rem;
  column-gap: min(
    max(4.48rem, calc(4.48rem + ((1vw - 0.32rem) * 2.2857))),
    6.4rem
  );
}
.col-gap-20 {
  column-gap: 8rem;
  column-gap: min(max(5.6rem, calc(5.6rem + ((1vw - 0.32rem) * 2.8571))), 8rem);
}
.col-gap-24 {
  column-gap: 9.6rem;
  column-gap: min(
    max(6.72rem, calc(6.72rem + ((1vw - 0.32rem) * 3.4286))),
    9.6rem
  );
}
.row-gap-0 {
  row-gap: 0rem;
}
.row-gap-2 {
  row-gap: 0.8rem;
  row-gap: min(
    max(0.56rem, calc(0.56rem + ((1vw - 0.32rem) * 0.2857))),
    0.8rem
  );
}
.row-gap-4 {
  row-gap: 1.6rem;
  row-gap: min(
    max(1.12rem, calc(1.12rem + ((1vw - 0.32rem) * 0.5714))),
    1.6rem
  );
}
.row-gap-6 {
  row-gap: 2.4rem;
  row-gap: min(
    max(1.68rem, calc(1.68rem + ((1vw - 0.32rem) * 0.8571))),
    2.4rem
  );
}
.row-gap-8 {
  row-gap: 3.2rem;
  row-gap: min(
    max(2.24rem, calc(2.24rem + ((1vw - 0.32rem) * 1.1429))),
    3.2rem
  );
}
.row-gap-10 {
  row-gap: 4rem;
  row-gap: min(max(2.8rem, calc(2.8rem + ((1vw - 0.32rem) * 1.4286))), 4rem);
}
.row-gap-12 {
  row-gap: 4.8rem;
  row-gap: min(
    max(3.36rem, calc(3.36rem + ((1vw - 0.32rem) * 1.7143))),
    4.8rem
  );
}
.row-gap-14 {
  row-gap: 5.6rem;
  row-gap: min(max(3.92rem, calc(3.92rem + ((1vw - 0.32rem) * 2))), 5.6rem);
}
.row-gap-16 {
  row-gap: 6.4rem;
  row-gap: min(
    max(4.48rem, calc(4.48rem + ((1vw - 0.32rem) * 2.2857))),
    6.4rem
  );
}
.row-gap-20 {
  row-gap: 8rem;
  row-gap: min(max(5.6rem, calc(5.6rem + ((1vw - 0.32rem) * 2.8571))), 8rem);
}
.row-gap-24 {
  row-gap: 9.6rem;
  row-gap: min(
    max(6.72rem, calc(6.72rem + ((1vw - 0.32rem) * 3.4286))),
    9.6rem
  );
}
.grid-flow-row {
  grid-auto-flow: row;
}
.grid-flow-col {
  grid-auto-flow: column;
}
.grid-flow-row-dense {
  grid-auto-flow: row dense;
}
.grid-flow-col-dense {
  grid-auto-flow: column dense;
}
.col-auto {
  grid-column: auto;
}
.col-span-1 {
  grid-column: span 1 / span 1;
}
.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}
.col-span-4 {
  grid-column: span 4 / span 4;
}
.col-span-5 {
  grid-column: span 5 / span 5;
}
.col-span-6 {
  grid-column: span 6 / span 6;
}
.col-start-1 {
  grid-column-start: 1;
}
.col-start-2 {
  grid-column-start: 2;
}
.col-start-3 {
  grid-column-start: 3;
}
.col-start-4 {
  grid-column-start: 4;
}
.col-start-5 {
  grid-column-start: 5;
}
.col-start-6 {
  grid-column-start: 6;
}
.col-start-7 {
  grid-column-start: 7;
}
.col-start-auto {
  grid-column-start: auto;
}
.col-end-1 {
  grid-column-end: 1;
}
.col-end-2 {
  grid-column-end: 2;
}
.col-end-3 {
  grid-column-end: 3;
}
.col-end-4 {
  grid-column-end: 4;
}
.col-end-5 {
  grid-column-end: 5;
}
.col-end-6 {
  grid-column-end: 6;
}
.col-end-7 {
  grid-column-end: 7;
}
.col-end-auto {
  grid-column-end: auto;
}
.row-auto {
  grid-row: auto;
}
.row-span-1 {
  grid-row: span 1 / span 1;
}
.row-span-2 {
  grid-row: span 2 / span 2;
}
.row-span-3 {
  grid-row: span 3 / span 3;
}
.row-start-1 {
  grid-row-start: 1;
}
.row-start-2 {
  grid-row-start: 2;
}
.row-start-3 {
  grid-row-start: 3;
}
.row-start-4 {
  grid-row-start: 4;
}
.row-start-auto {
  grid-row-start: auto;
}
.row-end-1 {
  grid-row-end: 1;
}
.row-end-2 {
  grid-row-end: 2;
}
.row-end-3 {
  grid-row-end: 3;
}
.row-end-4 {
  grid-row-end: 4;
}
.row-end-auto {
  grid-row-end: auto;
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid-cols-none {
  grid-template-columns: none;
}

.grid-60-40 {
  display: grid;
  grid-template-columns: 3fr 2fr;
}
.grid-40-60 {
  display: grid;
  grid-template-columns: 2fr 3fr;
}
.grid-33-66 {
  display: grid;
  grid-template-columns: 1fr 2fr;
}
.grid-66-33 {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.grid-75-25 {
  display: grid;
  grid-template-columns: 3fr 1fr;
}
.grid-25-75 {
  display: grid;
  grid-template-columns: 1fr 3fr;
}

/* grid power utilities */
/* 2-cols */
.auto-grid-2 {
  display: grid;
  --auto-grid-min-size: calc(56rem - var(--gutter-size));
  --gutter-size: 16px;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(35.2rem, min(45.6rem, var(--auto-grid-min-size))), 1fr)
  );
}

/* 3-cols */
.auto-grid-3 {
  display: grid;
  --auto-grid-min-size: calc(37.3rem - var(--gutter-size));
  --gutter-size: 21.4px;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(28.8rem, min(29.4rem, var(--auto-grid-min-size))), 1fr)
  );
}

/* 4-cols */
.auto-grid-4 {
  display: grid;
  --auto-grid-min-size: calc(28rem - var(--gutter-size));
  --gutter-size: 24px;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(20.8rem, min(21.2rem, var(--auto-grid-min-size))), 1fr)
  );
}

/* 5-cols */
.auto-grid-5 {
  display: grid;
  --auto-grid-min-size: calc(22.4rem - var(--gutter-size));
  --gutter-size: 29.4px;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(23.8rem, min(11.2rem, var(--auto-grid-min-size))), 1fr)
  );
}

/* if we want to override the magic grid gap size */
.auto-grid-2.gap-0,
.auto-grid-3.gap-0,
.auto-grid-4.gap-0 {
  grid-gap: 0px;
  --gutter-size: 0px;
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}
.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}
.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}
.grid-rows-none {
  grid-template-rows: none;
}

/* space-y classes */

.ct-section.space-y-0 .ct-section-inner-wrap > * + *,
.space-y-0:not(.ct-section) > * + * {
  margin-top: 0;
}
.ct-section.space-y-1 .ct-section-inner-wrap > * + *,
.space-y-1:not(.ct-section) > * + * {
  margin-top: min(
    max(0.28rem, calc(0.28rem + ((1vw - 0.32rem) * 0.15))),
    0.4rem
  );
}
.ct-section.space-y-2 .ct-section-inner-wrap > * + *,
.space-y-2:not(.ct-section) > * + * {
  margin-top: min(
    max(0.56rem, calc(0.56rem + ((1vw - 0.32rem) * 0.3))),
    0.8rem
  );
}
.ct-section.space-y-3 .ct-section-inner-wrap > * + *,
.space-y-3:not(.ct-section) > * + * {
  margin-top: min(
    max(0.84rem, calc(0.84rem + ((1vw - 0.32rem) * 0.45))),
    1.2rem
  );
}
.ct-section.space-y-4 .ct-section-inner-wrap > * + *,
.space-y-4:not(.ct-section) > * + * {
  margin-top: min(
    max(1.12rem, calc(1.12rem + ((1vw - 0.32rem) * 0.6))),
    1.6rem
  );
}
.ct-section.space-y-6 .ct-section-inner-wrap > * + *,
.space-y-6:not(.ct-section) > * + * {
  margin-top: min(
    max(1.68rem, calc(1.68rem + ((1vw - 0.32rem) * 0.9))),
    2.4rem
  );
}
.ct-section.space-y-8 .ct-section-inner-wrap > * + *,
.space-y-8:not(.ct-section) > * + * {
  margin-top: min(
    max(2.24rem, calc(2.24rem + ((1vw - 0.32rem) * 1.2))),
    3.2rem
  );
}
.ct-section.space-y-10 .ct-section-inner-wrap > * + *,
.space-y-10:not(.ct-section) > * + * {
  margin-top: min(max(2.8rem, calc(2.8rem + ((1vw - 0.32rem) * 1.5))), 4rem);
}
.ct-section.space-y-12 .ct-section-inner-wrap > * + *,
.space-y-12:not(.ct-section) > * + * {
  margin-top: min(
    max(3.36rem, calc(3.36rem + ((1vw - 0.32rem) * 1.8))),
    4.8rem
  );
}
.ct-section.space-y-14 .ct-section-inner-wrap > * + *,
.space-y-14:not(.ct-section) > * + * {
  margin-top: min(
    max(3.92rem, calc(3.92rem + ((1vw - 0.32rem) * 2.1))),
    5.6rem
  );
}
.ct-section.space-y-16 .ct-section-inner-wrap > * + *,
.space-y-16:not(.ct-section) > * + * {
  margin-top: min(
    max(4.48rem, calc(4.48rem + ((1vw - 0.32rem) * 2.4))),
    6.4rem
  );
}
.ct-section.space-y-20 .ct-section-inner-wrap > * + *,
.space-y-20:not(.ct-section) > * + * {
  margin-top: min(max(5.6rem, calc(5.6rem + ((1vw - 0.32rem) * 3))), 8rem);
}

.ct-section.space-x-0 .ct-section-inner-wrap > * + *,
.space-x-0:not(.ct-section) > * + * {
  margin-left: 0;
}
.ct-section.space-x-1 .ct-section-inner-wrap > * + *,
.space-x-1:not(.ct-section) > * + * {
  margin-left: min(
    max(0.28rem, calc(0.28rem + ((1vw - 0.32rem) * 0.15))),
    0.4rem
  );
}
.ct-section.space-x-2 .ct-section-inner-wrap > * + *,
.space-x-2:not(.ct-section) > * + * {
  margin-left: min(
    max(0.56rem, calc(0.56rem + ((1vw - 0.32rem) * 0.3))),
    0.8rem
  );
}
.ct-section.space-x-3 .ct-section-inner-wrap > * + *,
.space-x-3:not(.ct-section) > * + * {
  margin-left: min(
    max(0.84rem, calc(0.84rem + ((1vw - 0.32rem) * 0.45))),
    1.2rem
  );
}
.ct-section.space-x-4 .ct-section-inner-wrap > * + *,
.space-x-4:not(.ct-section) > * + * {
  margin-left: min(
    max(1.12rem, calc(1.12rem + ((1vw - 0.32rem) * 0.6))),
    1.6rem
  );
}
.ct-section.space-x-6 .ct-section-inner-wrap > * + *,
.space-x-6:not(.ct-section) > * + * {
  margin-left: min(
    max(1.68rem, calc(1.68rem + ((1vw - 0.32rem) * 0.9))),
    2.4rem
  );
}
.ct-section.space-x-8 .ct-section-inner-wrap > * + *,
.space-x-8:not(.ct-section) > * + * {
  margin-left: min(
    max(2.24rem, calc(2.24rem + ((1vw - 0.32rem) * 1.2))),
    3.2rem
  );
}
.ct-section.space-x-10 .ct-section-inner-wrap > * + *,
.space-x-10:not(.ct-section) > * + * {
  margin-left: min(max(2.8rem, calc(2.8rem + ((1vw - 0.32rem) * 1.5))), 4rem);
}
.ct-section.space-x-12 .ct-section-inner-wrap > * + *,
.space-x-12:not(.ct-section) > * + * {
  margin-left: min(
    max(3.36rem, calc(3.36rem + ((1vw - 0.32rem) * 1.8))),
    4.8rem
  );
}
.ct-section.space-x-14 .ct-section-inner-wrap > * + *,
.space-x-14:not(.ct-section) > * + * {
  margin-left: min(
    max(3.92rem, calc(3.92rem + ((1vw - 0.32rem) * 2.1))),
    5.6rem
  );
}
.ct-section.space-x-16 .ct-section-inner-wrap > * + *,
.space-x-16:not(.ct-section) > * + * {
  margin-left: min(
    max(4.48rem, calc(4.48rem + ((1vw - 0.32rem) * 2.4))),
    6.4rem
  );
}
.ct-section.space-x-20 .ct-section-inner-wrap > * + *,
.space-x-20:not(.ct-section) > * + * {
  margin-left: min(max(5.6rem, calc(5.6rem + ((1vw - 0.32rem) * 3))), 8rem);
}

.ct-video > .oxygen-vsb-responsive-video-wrapper > iframe {
  border-radius: 6px;
}

.section-pt-0 > .ct-section-inner-wrap {
  padding-top: 0px;
}
.section-pt-sm > .ct-section-inner-wrap {
  padding-top: min(max(4rem, calc(4rem + ((1vw - 0.32rem) * 2.5))), 6rem);
}
.section-pb-0 > .ct-section-inner-wrap {
  padding-bottom: 0px;
}
.section-pb-sm > .ct-section-inner-wrap {
  padding-bottom: min(max(4rem, calc(4rem + ((1vw - 0.32rem) * 2.5))), 6rem);
}
.section-py-0 > .ct-section-inner-wrap {
  padding-top: 0px;
  padding-bottom: 0px;
}
.section-py-sm > .ct-section-inner-wrap {
  padding-top: min(max(4rem, calc(4rem + ((1vw - 0.32rem) * 2.5))), 6rem);
  padding-bottom: min(max(4rem, calc(4rem + ((1vw - 0.32rem) * 2.5))), 6rem);
}

.ct-section[class*="overlay-"] .ct-section-inner-wrap {
  /* position: relative; */
  z-index: 1;
}
@media screen and (max-width: 1119px) {
  .breakout-70,
  .breakout-80,
  .breakout-90,
  .breakout-100 {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
  .xl-col-auto {
    grid-column: auto;
  }
  .xl-col-span-1 {
    grid-column: span 1 / span 1;
  }
  .xl-col-span-2 {
    grid-column: span 2 / span 2;
  }
  .xl-col-span-3 {
    grid-column: span 3 / span 3;
  }
  .xl-col-span-4 {
    grid-column: span 4 / span 4;
  }
  .xl-col-span-5 {
    grid-column: span 5 / span 5;
  }
  .xl-row-span-1 {
    grid-row: span 1 / span 1;
  }
  .xl-row-span-2 {
    grid-row: span 2 / span 2;
  }
  .xl-row-span-3 {
    grid-row: span 3 / span 3;
  }
  .xl-col-start-1 {
    grid-column-start: 1;
  }
  .xl-col-start-2 {
    grid-column-start: 2;
  }
  .xl-col-start-3 {
    grid-column-start: 3;
  }
  .xl-col-start-4 {
    grid-column-start: 4;
  }
  .xl-col-start-5 {
    grid-column-start: 5;
  }
  .xl-col-start-6 {
    grid-column-start: 6;
  }
  .xl-col-start-auto {
    grid-column-start: auto;
  }
  .xl-col-end-1 {
    grid-column-end: 1;
  }
  .xl-col-end-2 {
    grid-column-end: 2;
  }
  .xl-col-end-3 {
    grid-column-end: 3;
  }
  .xl-col-end-4 {
    grid-column-end: 4;
  }
  .xl-col-end-5 {
    grid-column-end: 5;
  }
  .xl-col-end-6 {
    grid-column-end: 6;
  }
  .xl-col-end-auto {
    grid-column-end: auto;
  }
  .xl-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .xl-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .xl-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .xl-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .xl-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .xl-grid-cols-none {
    grid-template-columns: none;
  }
  .xl-flex {
    display: flex !important;
  }
  .xl-h-full {
    height: 100%;
  }
  .xl-h-screen {
    height: 100vh;
  }
  .xl-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .xl-centered {
    align-items: center !important;
    justify-content: center !important;
    align-content: center !important;
    text-align: center !important;
  }
  .xl-horizontal {
    display: flex !important;
    flex-direction: row !important;
  }
  .xl-horizontal-wrap {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
  }
  .xl-vertical {
    display: flex !important;
    flex-direction: column !important;
  }
  .xl-hidden {
    display: none !important;
  }
  .xl-w-1by2 {
    width: 50%;
  }
  .xl-w-1by3 {
    width: 33.333333%;
  }
  .xl-w-2by3 {
    width: 66.666667%;
  }
  .xl-w-1by4 {
    width: 25%;
  }
  .xl-w-2by4 {
    width: 50%;
  }
  .xl-w-3by4 {
    width: 75%;
  }
  .xl-w-full {
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .lg-col-auto {
    grid-column: auto;
  }
  .lg-col-span-1 {
    grid-column: span 1 / span 1;
  }
  .lg-col-span-2 {
    grid-column: span 2 / span 2;
  }
  .lg-col-span-3 {
    grid-column: span 3 / span 3;
  }
  .lg-col-span-4 {
    grid-column: span 4 / span 4;
  }
  .lg-row-span-1 {
    grid-row: span 1 / span 1;
  }
  .lg-row-span-2 {
    grid-row: span 2 / span 2;
  }
  .lg-row-span-3 {
    grid-row: span 3 / span 3;
  }
  .lg-col-start-1 {
    grid-column-start: 1;
  }
  .lg-col-start-2 {
    grid-column-start: 2;
  }
  .lg-col-start-3 {
    grid-column-start: 3;
  }
  .lg-col-start-4 {
    grid-column-start: 4;
  }
  .lg-col-start-5 {
    grid-column-start: 5;
  }
  .lg-col-start-auto {
    grid-column-start: auto;
  }
  .lg-col-end-1 {
    grid-column-end: 1;
  }
  .lg-col-end-2 {
    grid-column-end: 2;
  }
  .lg-col-end-3 {
    grid-column-end: 3;
  }
  .lg-col-end-4 {
    grid-column-end: 4;
  }
  .lg-col-end-5 {
    grid-column-end: 5;
  }
  .lg-col-end-auto {
    grid-column-end: auto;
  }
  .lg-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .lg-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .lg-grid-cols-none {
    grid-template-columns: none;
  }
  .lg-row-start-1 {
    grid-row-start: 1;
  }
  .lg-flex {
    display: flex !important;
  }
  .lg-order-1 {
    order: 1;
  }
  .lg-order-2 {
    order: 2;
  }
  .lg-order-3 {
    order: 3;
  }
  .lg-order-4 {
    order: 4;
  }
  .lg-order-5 {
    order: 5;
  }
  .lg-order-6 {
    order: 6;
  }
  .lg-order-first {
    order: -9999;
  }
  .lg-order-last {
    order: 9999;
  }
  .lg-order-none {
    order: 0;
  }
  .lg-h-full {
    height: 100%;
  }
  .lg-h-screen {
    height: 100vh;
  }
  .lg-mr-0 {
    margin-right: 0;
  }
  .lg-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .lg-centered {
    align-items: center !important;
    justify-content: center !important;
    align-content: center !important;
    text-align: center !important;
  }
  .lg-horizontal {
    display: flex !important;
    flex-direction: row !important;
  }
  .lg-horizontal-reverse {
    display: flex !important;
    flex-direction: row-reverse !important;
  }
  .lg-horizontal-wrap {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
  }
  .lg-vertical {
    display: flex !important;
    flex-direction: column !important;
  }
  .lg-vertical-reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .lg-hidden {
    display: none !important;
  }
  .lg-w-1by2 {
    width: 50%;
  }
  .lg-w-1by3 {
    width: 33.333333%;
  }
  .lg-w-2by3 {
    width: 66.666667%;
  }
  .lg-w-1by4 {
    width: 25%;
  }
  .lg-w-2by4 {
    width: 50%;
  }
  .lg-w-3by4 {
    width: 75%;
  }
  .lg-w-full {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .md-col-auto {
    grid-column: auto;
  }
  .md-col-span-1 {
    grid-column: span 1 / span 1;
  }
  .md-col-span-2 {
    grid-column: span 2 / span 2;
  }
  .md-col-span-3 {
    grid-column: span 3 / span 3;
  }
  .md-row-span-1 {
    grid-row: span 1 / span 1;
  }
  .md-row-span-2 {
    grid-row: span 2 / span 2;
  }
  .md-row-span-3 {
    grid-row: span 3 / span 3;
  }
  .md-col-start-1 {
    grid-column-start: 1;
  }
  .md-col-start-2 {
    grid-column-start: 2;
  }
  .md-col-start-3 {
    grid-column-start: 3;
  }
  .md-col-start-4 {
    grid-column-start: 4;
  }
  .md-col-start-auto {
    grid-column-start: auto;
  }
  .md-col-end-1 {
    grid-column-end: 1;
  }
  .md-col-end-2 {
    grid-column-end: 2;
  }
  .md-col-end-3 {
    grid-column-end: 3;
  }
  .md-col-end-4 {
    grid-column-end: 4;
  }
  .md-col-end-auto {
    grid-column-end: auto;
  }
  .md-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .md-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md-grid-cols-none {
    grid-template-columns: none;
  }
  .md-rounded-sm {
    border-radius: 0.2rem;
  }
  .md-rounded {
    border-radius: 0.4rem;
  }
  .md-rounded-md {
    border-radius: 0.6rem;
  }
  .md-rounded-lg {
    border-radius: 0.8rem;
  }
  .md-row-start-1 {
    grid-row-start: 1;
  }
  .md-flex {
    display: flex !important;
  }
  .md-order-1 {
    order: 1;
  }
  .md-order-2 {
    order: 2;
  }
  .md-order-3 {
    order: 3;
  }
  .md-order-4 {
    order: 4;
  }
  .md-order-5 {
    order: 5;
  }
  .md-order-6 {
    order: 6;
  }
  .md-order-first {
    order: -9999;
  }
  .md-order-last {
    order: 9999;
  }
  .md-order-none {
    order: 0;
  }
  .md-h-full {
    height: 100%;
  }
  .md-h-screen {
    height: 100vh;
  }
  .md-ml-auto {
    margin-left: auto;
  }
  .md-mr-auto {
    margin-right: auto;
  }
  .md-mr-0 {
    margin-right: 0;
  }
  .md-mb-0 {
    margin-bottom: 0;
  }
  .md-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .md-centered {
    align-items: center !important;
    justify-content: center !important;
    align-content: center !important;
    text-align: center !important;
  }
  .md-horizontal {
    display: flex !important;
    flex-direction: row !important;
  }
  .md-horizontal-reverse {
    display: flex !important;
    flex-direction: row-reverse !important;
  }
  .md-horizontal-wrap {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
  }
  .md-vertical {
    display: flex !important;
    flex-direction: column !important;
  }
  .md-vertical-reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .md-hidden {
    display: none !important;
  }
  .md-w-1by2 {
    width: 50%;
  }
  .md-w-1by3 {
    width: 33.333333%;
  }
  .md-w-2by3 {
    width: 66.666667%;
  }
  .md-w-full {
    width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .sm-col-auto {
    grid-column: auto;
  }
  .sm-col-span-1 {
    grid-column: span 1 / span 1;
  }
  .sm-col-span-2 {
    grid-column: span 2 / span 2;
  }
  .sm-row-span-1 {
    grid-row: span 1 / span 1;
  }
  .sm-row-span-2 {
    grid-row: span 2 / span 2;
  }
  .sm-row-span-3 {
    grid-row: span 3 / span 3;
  }
  .sm-col-start-1 {
    grid-column-start: 1;
  }
  .sm-col-start-2 {
    grid-column-start: 2;
  }
  .sm-col-start-3 {
    grid-column-start: 3;
  }
  .sm-col-start-auto {
    grid-column-start: auto;
  }
  .sm-col-end-1 {
    grid-column-end: 1;
  }
  .sm-col-end-2 {
    grid-column-end: 2;
  }
  .sm-col-end-3 {
    grid-column-end: 3;
  }
  .sm-col-end-auto {
    grid-column-end: auto;
  }
  .sm-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .sm-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sm-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sm-grid-cols-none {
    grid-template-columns: none;
  }
  .sm-flex {
    display: flex !important;
  }
  .sm-order-1 {
    order: 1;
  }
  .sm-order-2 {
    order: 2;
  }
  .sm-order-3 {
    order: 3;
  }
  .sm-order-4 {
    order: 4;
  }
  .sm-order-5 {
    order: 5;
  }
  .sm-order-6 {
    order: 6;
  }
  .sm-order-first {
    order: -9999;
  }
  .sm-order-last {
    order: 9999;
  }
  .sm-order-none {
    order: 0;
  }
  .sm-h-full {
    height: 100%;
  }
  .sm-h-screen {
    height: 100vh;
  }
  .sm-mt-0 {
    margin-top: 0;
  }
  .sm-mr-0 {
    margin-right: 0;
  }
  .sm-mr-auto {
    margin-right: auto;
  }
  .sm-ml-auto {
    margin-left: auto;
  }
  .sm-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .sm-centered {
    align-items: center !important;
    justify-content: center !important;
    align-content: center !important;
    text-align: center !important;
  }
  .sm-horizontal {
    display: flex !important;
    flex-direction: row !important;
  }
  .sm-horizontal-reverse {
    display: flex !important;
    flex-direction: row-reverse !important;
  }
  .sm-horizontal-wrap {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
  }
  .sm-vertical {
    display: flex !important;
    flex-direction: column !important;
  }
  .sm-vertical-reverse {
    display: flex !important;
    flex-direction: column-reverse !important;
  }
  .sm-hidden {
    display: none !important;
  }
  .sm-w-1by2 {
    width: 50%;
  }
  .sm-w-full {
    width: 100%;
  }
} /* Don't add/delete any custom css in this file. This file may change in future as we extend the framework */
/* The framework will overwrite any CSS you write here when we make an update. */
/* Find hover classes documentation at https://learn.oxymade.com/docs/learn/hover-classes/ */

@keyframes hover-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes hover-buzz-out {
  10% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  20% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  30% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  40% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  50% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  60% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  70% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  80% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  90% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  100% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
@keyframes hover-back-pulse {
  50% {
    background-color: var(--tertiary-color);
  }
}
@keyframes hover-ripple-out {
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
}

:where(.hover-fade, .hover-back-pulse).hover-bg-primary:where(
    :hover,
    :focus,
    :active
  ),
:where(
    .hover-bounce-to-right,
    .hover-bounce-to-bottom,
    .hover-shutter-out-horizontal,
    .hover-underline-from-left,
    .hover-underline-from-center,
    .hover-overline-from-left,
    .hover-overline-from-center,
    .hover-underline-reveal,
    .hover-overline-reveal
  ).hover-bg-primary:before,
:where(
    .hover-bubble-left,
    .hover-bubble-bottom,
    .hover-bubble-right,
    .hover-bubble-top
  ).hover-bg-primary:hover {
  background: var(--primary-color) !important;
}
:where(.hover-fade, .hover-back-pulse).hover-bg-secondary:where(
    :hover,
    :focus,
    :active
  ),
:where(
    .hover-bounce-to-right,
    .hover-bounce-to-bottom,
    .hover-shutter-out-horizontal,
    .hover-underline-from-left,
    .hover-underline-from-center,
    .hover-overline-from-left,
    .hover-overline-from-center,
    .hover-underline-reveal,
    .hover-overline-reveal
  ).hover-bg-secondary:before,
:where(
    .hover-bubble-left,
    .hover-bubble-bottom,
    .hover-bubble-right,
    .hover-bubble-top
  ).hover-bg-secondary:hover {
  background: var(--secondary-color) !important;
}

:where(.hover-fade, .hover-back-pulse).hover-bg:where(:hover, :focus, :active),
:where(
    .hover-bounce-to-right,
    .hover-bounce-to-bottom,
    .hover-shutter-out-horizontal,
    .hover-underline-from-left,
    .hover-underline-from-center,
    .hover-overline-from-left,
    .hover-overline-from-center,
    .hover-underline-reveal,
    .hover-overline-reveal
  ).hover-bg:before,
:where(
    .hover-bubble-left,
    .hover-bubble-bottom,
    .hover-bubble-right,
    .hover-bubble-top
  ).hover-bg:hover {
  background: var(--background-color) !important;
}

:where(.hover-fade, .hover-back-pulse).hover-bg-dark:where(
    :hover,
    :focus,
    :active
  ),
:where(
    .hover-bounce-to-right,
    .hover-bounce-to-bottom,
    .hover-shutter-out-horizontal,
    .hover-underline-from-left,
    .hover-underline-from-center,
    .hover-overline-from-left,
    .hover-overline-from-center,
    .hover-underline-reveal,
    .hover-overline-reveal
  ).hover-bg-dark:before,
:where(
    .hover-bubble-left,
    .hover-bubble-bottom,
    .hover-bubble-right,
    .hover-bubble-top
  ).hover-bg-dark:hover {
  background: var(--dark-color) !important;
}

:where(.hover-fade, .hover-back-pulse).hover-bg-light:where(
    :hover,
    :focus,
    :active
  ),
:where(
    .hover-bounce-to-right,
    .hover-bounce-to-bottom,
    .hover-shutter-out-horizontal,
    .hover-underline-from-left,
    .hover-underline-from-center,
    .hover-overline-from-left,
    .hover-overline-from-center,
    .hover-underline-reveal,
    .hover-overline-reveal
  ).hover-bg-light:before,
:where(
    .hover-bubble-left,
    .hover-bubble-bottom,
    .hover-bubble-right,
    .hover-bubble-top
  ).hover-bg-light:hover {
  background: var(--light-color) !important;
}

:where(.hover-fade, .hover-back-pulse).hover-bg-alt:where(
    :hover,
    :focus,
    :active
  ),
:where(
    .hover-bounce-to-right,
    .hover-bounce-to-bottom,
    .hover-shutter-out-horizontal,
    .hover-underline-from-left,
    .hover-underline-from-center,
    .hover-overline-from-left,
    .hover-overline-from-center,
    .hover-underline-reveal,
    .hover-overline-reveal
  ).hover-bg-alt:before,
:where(
    .hover-bubble-left,
    .hover-bubble-bottom,
    .hover-bubble-right,
    .hover-bubble-top
  ).hover-bg-alt:hover {
  background: var(--background-alt-color) !important;
}

:where(.hover-fade, .hover-back-pulse).hover-bg-white:where(
    :hover,
    :focus,
    :active
  ),
:where(
    .hover-bounce-to-right,
    .hover-bounce-to-bottom,
    .hover-shutter-out-horizontal,
    .hover-underline-from-left,
    .hover-underline-from-center,
    .hover-overline-from-left,
    .hover-overline-from-center,
    .hover-underline-reveal,
    .hover-overline-reveal
  ).hover-bg-white:before,
:where(
    .hover-bubble-left,
    .hover-bubble-bottom,
    .hover-bubble-right,
    .hover-bubble-top
  ).hover-bg-white:hover {
  background: var(--white-color) !important;
}

:where(.hover-fade, .hover-back-pulse).hover-bg-black:where(
    :hover,
    :focus,
    :active
  ),
:where(
    .hover-bounce-to-right,
    .hover-bounce-to-bottom,
    .hover-shutter-out-horizontal,
    .hover-underline-from-left,
    .hover-underline-from-center,
    .hover-overline-from-left,
    .hover-overline-from-center,
    .hover-underline-reveal,
    .hover-overline-reveal
  ).hover-bg-black:before,
:where(
    .hover-bubble-left,
    .hover-bubble-bottom,
    .hover-bubble-right,
    .hover-bubble-top
  ).hover-bg-black:hover {
  background: var(--black-color) !important;
}

:where(.hover-fade, .hover-back-pulse).hover-bg-placeholder:where(
    :hover,
    :focus,
    :active
  ),
:where(
    .hover-bounce-to-right,
    .hover-bounce-to-bottom,
    .hover-shutter-out-horizontal,
    .hover-underline-from-left,
    .hover-underline-from-center,
    .hover-overline-from-left,
    .hover-overline-from-center,
    .hover-underline-reveal,
    .hover-overline-reveal
  ).hover-bg-placeholder:before,
:where(
    .hover-bubble-left,
    .hover-bubble-bottom,
    .hover-bubble-right,
    .hover-bubble-top
  ).hover-bg-placeholder:hover {
  background: var(--placeholder-color) !important;
}

:where(.hover-fade, .hover-back-pulse).hover-bg-placeholder-alt:where(
    :hover,
    :focus,
    :active
  ),
:where(
    .hover-bounce-to-right,
    .hover-bounce-to-bottom,
    .hover-shutter-out-horizontal,
    .hover-underline-from-left,
    .hover-underline-from-center,
    .hover-overline-from-left,
    .hover-overline-from-center,
    .hover-underline-reveal,
    .hover-overline-reveal
  ).hover-bg-placeholder-alt:before,
:where(
    .hover-bubble-left,
    .hover-bubble-bottom,
    .hover-bubble-right,
    .hover-bubble-top
  ).hover-bg-placeholder-alt:hover {
  background: var(--placeholder-alt-color) !important;
}

:where(
    .hover-bounce-to-bottom,
    .hover-bounce-to-right,
    .hover-shutter-out-horizontal
  ).hover-color-dark:where(:hover, :focus, :active) {
  color: var(--dark-color) !important;
}

:where(
    .hover-bounce-to-bottom,
    .hover-bounce-to-right,
    .hover-shutter-out-horizontal
  ).hover-color-light:where(:hover, :focus, :active) {
  color: var(--light-color) !important;
}

:where(
    .hover-bounce-to-bottom,
    .hover-bounce-to-right,
    .hover-shutter-out-horizontal
  ).hover-color-primary:where(:hover, :focus, :active) {
  color: var(--primary-color) !important;
}

:where(
    .hover-bounce-to-bottom,
    .hover-bounce-to-right,
    .hover-shutter-out-horizontal
  ).hover-color-paragraph:where(:hover, :focus, :active) {
  color: var(--paragraph-color) !important;
}
:where(
    .hover-bounce-to-bottom,
    .hover-bounce-to-right,
    .hover-shutter-out-horizontal
  ).hover-color-paragraph-alt:where(:hover, :focus, :active) {
  color: var(--paragraph-alt-color) !important;
}
.hover-color-dark:hover,
.hover-color-dark:hover :where(p, div, h1, h2, h3, h4, h5, h6, a, ul, li) {
  color: var(--dark-color) !important;
}
.hover-color-light:hover,
.hover-color-light:hover :where(p, div, h1, h2, h3, h4, h5, h6, a, ul, li) {
  color: var(--light-color) !important;
}
.hover-color-primary:hover,
.hover-color-primary:hover :where(p, div, h1, h2, h3, h4, h5, h6, a, ul, li) {
  color: var(--primary-color) !important;
}
.hover-color-paragraph:hover,
.hover-color-paragraph:hover :where(p, div, h1, h2, h3, h4, h5, h6, a, ul, li) {
  color: var(--paragraph-color) !important;
}
.hover-color-paragraph-alt:hover,
.hover-color-paragraph-alt:hover
  :where(p, div, h1, h2, h3, h4, h5, h6, a, ul, li) {
  color: var(--paragraph-alt-color) !important;
}
:where(
    .hover-ripple-out,
    .hover-outline-out,
    .hover-trim,
    .hover-reveal
  ).hover-border-primary:before {
  border-color: var(--primary-color) !important;
}
:where(
    .hover-ripple-out,
    .hover-outline-out,
    .hover-trim,
    .hover-reveal
  ).hover-border-secondary:before {
  border-color: var(--secondary-color) !important;
}
:where(
    .hover-ripple-out,
    .hover-outline-out,
    .hover-trim,
    .hover-reveal
  ).hover-border-dark:before {
  border-color: var(--dark-color) !important;
}
:where(
    .hover-ripple-out,
    .hover-outline-out,
    .hover-trim,
    .hover-reveal
  ).hover-border-light:before {
  border-color: var(--light-color) !important;
}
:where(
    .hover-ripple-out,
    .hover-outline-out,
    .hover-trim,
    .hover-reveal
  ).hover-border:before {
  border-color: var(--border-color) !important;
}
:where(
    .hover-ripple-out,
    .hover-outline-out,
    .hover-trim,
    .hover-reveal
  ).hover-border-alt:before {
  border-color: var(--border-alt-color) !important;
}
.hover-bubble-top.hover-border-primary:where(:hover, :focus, :active):before {
  border-color: transparent transparent var(--primary-color) transparent !important;
}
.hover-bubble-top.hover-border-secondary:where(:hover, :focus, :active):before {
  border-color: transparent transparent var(--secondary-color) transparent !important;
}
.hover-bubble-top.hover-border-dark:where(:hover, :focus, :active):before {
  border-color: transparent transparent var(--dark-color) transparent !important;
}
.hover-bubble-top.hover-border-light:where(:hover, :focus, :active):before {
  border-color: transparent transparent var(--light-color) transparent !important;
}
.hover-bubble-top.hover-border:where(:hover, :focus, :active):before {
  border-color: transparent transparent var(--border-color) transparent !important;
}
.hover-bubble-top.hover-border-alt:where(:hover, :focus, :active):before {
  border-color: transparent transparent var(--border-alt-color) transparent !important;
}
.hover-bubble-left.hover-border-primary:where(:hover, :focus, :active):before {
  border-color: transparent var(--primary-color) transparent transparent !important;
}
.hover-bubble-left.hover-border-secondary:where(
    :hover,
    :focus,
    :active
  ):before {
  border-color: transparent var(--secondary-color) transparent transparent !important;
}
.hover-bubble-left.hover-border-dark:where(:hover, :focus, :active):before {
  border-color: transparent var(--dark-color) transparent transparent !important;
}
.hover-bubble-left.hover-border-light:where(:hover, :focus, :active):before {
  border-color: transparent var(--light-color) transparent transparent !important;
}
.hover-bubble-left.hover-border:where(:hover, :focus, :active):before {
  border-color: transparent var(--border-color) transparent transparent !important;
}
.hover-bubble-left.hover-border-alt:where(:hover, :focus, :active):before {
  border-color: transparent var(--border-alt-color) transparent transparent !important;
}
.hover-bubble-bottom.hover-border-primary:where(
    :hover,
    :focus,
    :active
  ):before {
  border-color: var(--primary-color) transparent transparent transparent !important;
}
.hover-bubble-bottom.hover-border-secondary:where(
    :hover,
    :focus,
    :active
  ):before {
  border-color: var(--secondary-color) transparent transparent transparent !important;
}
.hover-bubble-bottom.hover-border-dark:where(:hover, :focus, :active):before {
  border-color: var(--dark-color) transparent transparent transparent !important;
}
.hover-bubble-bottom.hover-border-light:where(:hover, :focus, :active):before {
  border-color: var(--light-color) transparent transparent transparent !important;
}
.hover-bubble-bottom.hover-border:where(:hover, :focus, :active):before {
  border-color: var(--border-color) transparent transparent transparent !important;
}
.hover-bubble-bottom.hover-border-alt:where(:hover, :focus, :active):before {
  border-color: var(--border-alt-color) transparent transparent transparent !important;
}
.hover-bubble-right.hover-border-primary:where(:hover, :focus, :active):before {
  border-color: transparent transparent transparent var(--primary-color) !important;
}
.hover-bubble-right.hover-border-secondary:where(
    :hover,
    :focus,
    :active
  ):before {
  border-color: transparent transparent transparent var(--secondary-color) !important;
}
.hover-bubble-right.hover-border-dark:where(:hover, :focus, :active):before {
  border-color: transparent transparent transparent var(--dark-color) !important;
}
.hover-bubble-right.hover-border-light:where(:hover, :focus, :active):before {
  border-color: transparent transparent transparent var(--light-color) !important;
}
.hover-bubble-right.hover-border:where(:hover, :focus, :active):before {
  border-color: transparent transparent transparent var(--border-color) !important;
}
.hover-bubble-right.hover-border-alt:where(:hover, :focus, :active):before {
  border-color: transparent transparent transparent var(--border-alt-color) !important;
}

/* Don't add any custom css to this file. This file may change in future as we extend the framework */
/* The framework will overwrite any CSS you write here when we make an update. */
.ct-slide {
  padding-left: 1.6rem !important;
  padding-right: 1.6rem !important;
}
.oxy-stock-content-styles img {
  height: auto;
}
.two-buttons-set > .btn-s:first-child {
  margin-right: 1rem;
}
.two-buttons-set > .btn-m:first-child {
  margin-right: 1rem;
}
.two-buttons-set > .btn-l:first-child {
  margin-right: 1rem;
}
@media (max-width: 479px) {
  .two-buttons-set > .btn-l:first-child {
    margin-right: 0rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 479px) {
  .two-buttons-set > .btn-m:first-child {
    margin-right: 0rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 479px) {
  .two-buttons-set > .btn-s:first-child {
    margin-right: 0rem;
    margin-bottom: 1rem;
  }
}

#section-2-31 {
  background-color: #eeeeee;
}
#section-10-31 > .ct-section-inner-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#section-10-31 {
  text-align: left;
}
#section-39-31 > .ct-section-inner-wrap {
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#section-39-31 {
  text-align: left;
}
#section-116-31 > .ct-section-inner-wrap {
  padding-top: 0;
}
#section-78-31 > .ct-section-inner-wrap {
  padding-top: 0;
  align-items: center;
}
#section-78-31 {
  text-align: left;
}
@media (max-width: 767px) {
  #section-10-31 > .ct-section-inner-wrap {
    padding-bottom: 4rem;
  }
}
#div_block-3-31 {
  width: 145.7rem;
  max-width: 100%;
  flex-direction: column;
  display: flex;
}
#div_block-11-31 {
  width: 145.7rem;
  max-width: 100%;
}
#div_block-40-31 {
  width: 145.7rem;
  max-width: 100%;
}
#div_block-65-31 {
  position: relative;
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  padding-left: 4rem;
}
#div_block-117-31 {
  width: 145.7rem;
  max-width: 100%;
}
#div_block-79-31 {
  width: 145.7rem;
  /* flex-direction: column;
  display: flex;
  text-align: center;
  align-items: flex-start; */
  max-width: 100%;
}
#div_block-80-31 {
  margin-bottom: 7rem;
  width: 100%;
  text-align: left;
  flex-direction: row;
  display: flex;
}
#div_block-82-31 {
  width: max-content;
}
#div_block-85-31 {
  display: flex;
  width: 100%;
  overflow: scroll;
  gap: 5rem;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: nowrap;
  align-content: flex-start;
  float: left;
  clear: left;
  position: relative;
  align-self: flex-start;
  flex-direction: row;
  padding-top: 6rem;
}
#div_block-86-31 {
  min-width: calc(50% - 2.5rem);
}
#div_block-90-31 {
  width: 15rem;
  height: 15rem;
  position: absolute;
  right: 4rem;
  top: -6rem;
}
#div_block-92-31 {
  min-width: calc(50% - 2.5rem);
}
#div_block-96-31 {
  width: 15rem;
  height: 15rem;
  position: absolute;
  right: 4rem;
  top: -6rem;
}
#div_block-98-31 {
  min-width: calc(50% - 2.5rem);
}
#div_block-102-31 {
  width: 15rem;
  height: 15rem;
  position: absolute;
  right: 4rem;
  top: -6rem;
}
#div_block-104-31 {
  min-width: calc(50% - 2.5rem);
}
#div_block-108-31 {
  width: 15rem;
  height: 15rem;
  position: absolute;
  right: 4rem;
  top: -6rem;
}
#div_block-110-31 {
  min-width: calc(50% - 2.5rem);
}
#div_block-114-31 {
  width: 15rem;
  height: 15rem;
  position: absolute;
  right: 4rem;
  top: -6rem;
}
@media (max-width: 991px) {
  #div_block-121-31 {
    gap: 3rem;
  }
}
@media (max-width: 991px) {
  #div_block-80-31 {
    margin-bottom: 3px;
  }
}
@media (max-width: 991px) {
  #div_block-86-31 {
    min-width: 75%;
  }
}
@media (max-width: 991px) {
  #div_block-92-31 {
    min-width: 75%;
  }
}
@media (max-width: 991px) {
  #div_block-98-31 {
    min-width: 75%;
  }
}
@media (max-width: 991px) {
  #div_block-104-31 {
    min-width: 75%;
  }
}
@media (max-width: 991px) {
  #div_block-110-31 {
    min-width: 75%;
  }
}
@media (max-width: 767px) {
  #div_block-65-31 {
    text-align: center;
    padding-left: 0px;
    margin-bottom: 4rem;
  }
}
@media (max-width: 767px) {
  #div_block-117-31 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
@media (max-width: 767px) {
  #div_block-90-31 {
    right: 0px;
    width: 10rem;
    height: 10rem;
    top: -6rem;
  }
}
@media (max-width: 767px) {
  #div_block-96-31 {
    right: 0px;
    width: 10rem;
    height: 10rem;
    top: -6rem;
  }
}
@media (max-width: 767px) {
  #div_block-102-31 {
    right: 0px;
    width: 10rem;
    height: 10rem;
    top: -6rem;
  }
}
@media (max-width: 767px) {
  #div_block-108-31 {
    right: 0px;
    width: 10rem;
    height: 10rem;
    top: -6rem;
  }
}
@media (max-width: 767px) {
  #div_block-114-31 {
    right: 0px;
    width: 10rem;
    height: 10rem;
    top: -6rem;
  }
}
@media (max-width: 479px) {
  #div_block-12-31 {
    margin-bottom: 4rem;
  }
}
#headline-4-31 {
  usecustomtag: true;
}
#headline-15-31 {
  usecustomtag: true;
}
#headline-16-31 {
  usecustomtag: false;
}
#headline-42-31 {
  usecustomtag: true;
}
#text_block-5-31 {
  usecustomtag: true;
}
#text_block-88-31 {
  font-style: italic;
}
#text_block-94-31 {
  font-style: italic;
}
#text_block-100-31 {
  font-style: italic;
}
#text_block-106-31 {
  font-style: italic;
}
#text_block-112-31 {
  font-style: italic;
}
@media (max-width: 767px) {
  #text_block-6-31 {
    width: 100%;
  }
}
@media (max-width: 600px) {
  #text_block-5-31 {
    font-size: 3rem;
  }
}
#image-147-31 {
  object-position: center center;
}
#image-84-31 {
  position: absolute;
  right: 200%;
  top: 100%;
}
#image-91-31 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-width: 10px;
  border-right-width: 10px;
  border-bottom-width: 10px;
  border-left-width: 10px;
  border-top-color: #ffffff;
  border-right-color: #ffffff;
  border-bottom-color: #ffffff;
  border-left-color: #ffffff;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-radius: 99px;
}
#image-97-31 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-width: 10px;
  border-right-width: 10px;
  border-bottom-width: 10px;
  border-left-width: 10px;
  border-top-color: #ffffff;
  border-right-color: #ffffff;
  border-bottom-color: #ffffff;
  border-left-color: #ffffff;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-radius: 99px;
}
#image-103-31 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-width: 10px;
  border-right-width: 10px;
  border-bottom-width: 10px;
  border-left-width: 10px;
  border-top-color: #ffffff;
  border-right-color: #ffffff;
  border-bottom-color: #ffffff;
  border-left-color: #ffffff;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-radius: 99px;
}
#image-109-31 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-width: 10px;
  border-right-width: 10px;
  border-bottom-width: 10px;
  border-left-width: 10px;
  border-top-color: #ffffff;
  border-right-color: #ffffff;
  border-bottom-color: #ffffff;
  border-left-color: #ffffff;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-radius: 99px;
}
#image-115-31 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-width: 10px;
  border-right-width: 10px;
  border-bottom-width: 10px;
  border-left-width: 10px;
  border-top-color: #ffffff;
  border-right-color: #ffffff;
  border-bottom-color: #ffffff;
  border-left-color: #ffffff;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-radius: 99px;
}
#code_block-145-31 {
  position: absolute;
  bottom: -8rem;
}
#code_block-19-31 {
  margin-right: 20px;
}
#code_block-22-31 {
  margin-right: 20px;
}
#code_block-25-31 {
  margin-right: 20px;
}
#code_block-28-31 {
  margin-right: 20px;
}
#code_block-124-31 {
  margin-right: 20px;
}
#code_block-127-31 {
  margin-right: 20px;
}
#code_block-130-31 {
  margin-right: 20px;
}
#code_block-137-31 {
  margin-right: 20px;
}
#code_block-140-31 {
  margin-right: 20px;
}
#code_block-143-31 {
  margin-right: 20px;
}
#code_block-83-31 {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: flex-end;
  justify-content: center;
}
@media (max-width: 991px) {
  #code_block-83-31 {
    display: none;
  }
}
div.ct-section-inner-wrap,
div.oxy-header-container {
  max-width: 1366px;
}

#header-section > .ct-section-inner-wrap {
  padding-top: 3rem;
  padding-right: 0;
  padding-bottom: 3rem;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#header-section {
  z-index: 99;
  text-align: left;
  margin-bottom: -18rem;
}
#section-38-15 > .ct-section-inner-wrap {
  padding-top: 125;
  padding-bottom: 125;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#section-38-15 {
  text-align: left;
  background-color: var(--light-color);
  border-top-left-radius: 12.5rem;
  border-top-right-radius: 12.5rem;
}
#section-14-15 > .ct-section-inner-wrap {
  padding-top: 100;
  padding-bottom: 50;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#section-14-15 {
  background-color: #212121;
  text-align: left;
}
@media (max-width: 991px) {
  #section-14-15 > .ct-section-inner-wrap {
    padding-top: 2.4rem;
  }
}
@media (max-width: 767px) {
  #section-38-15 {
    border-top-left-radius: 5rem;
    border-top-right-radius: 5rem;
  }
}
#menu-outer-div {
  width: 152rem;
  max-width: 110%;
}
#menu-wrapper {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  width: 100%;
}
#div_block-64-15 {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  width: 100%;
}
#div_block-3-15 {
  width: 19.5rem;
}
#div_block-11-15 {
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 7.5rem;
}
#div_block-5-15 {
  flex-direction: row;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  height: 100%;
}
#mobile-nav-div {
  display: none;
}
#div_block-39-15 {
  width: 91rem;
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
}
#div_block-43-15 {
  text-align: left;
  flex-direction: row;
  display: flex;
  gap: 2.4rem;
}
#div_block-51-15 {
  width: 91rem;
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
}
#div_block-129-15 {
  width: 91rem;
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
}
#div_block-90-15 {
  width: 91rem;
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
}
#div_block-99-15 {
  width: 91rem;
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
}
#div_block-104-15 {
  width: 91rem;
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
}
#div_block-111-15 {
  width: 91rem;
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
}
#div_block-118-15 {
  width: 91rem;
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
}
#div_block-125-15 {
  width: 91rem;
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
}
#div_block-28-15 {
  min-width: 320px;
}
#div_block-31-15 {
  text-align: left;
  flex-direction: row;
  display: flex;
  gap: 3rem;
}
#div_block-16-15 {
  margin-top: 10rem;
}
@media (max-width: 1470px) {
  #menu-outer-div {
    max-width: 100%;
    padding-bottom: 0rem;
    padding-top: 0rem;
    padding-right: 0rem;
    padding-left: 0rem;
  }
}
@media (max-width: 991px) {
  #menu-outer-div {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media (max-width: 991px) {
  #menu-wrapper {
    flex-direction: column;
    display: flex;
    text-align: center;
  }
}
@media (max-width: 991px) {
  #div_block-11-15 {
    padding-right: 3rem;
    flex-direction: row;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: flex-end;
  }
}
@media (max-width: 991px) {
  #div_block-5-15 {
    text-align: center;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
@media (max-width: 991px) {
  #mobile-nav-div {
    height: 0px;
    display: none;
  }
}
@media (max-width: 991px) {
  #div_block-22-15 {
    margin-top: 10rem;
    align-items: flex-start;
    display: flex;
    justify-content: flex-start;
    text-align: left;
    flex-direction: row;
  }
}
@media (max-width: 767px) {
  #div_block-39-15 {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  #div_block-51-15 {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  #div_block-129-15 {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  #div_block-90-15 {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  #div_block-99-15 {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  #div_block-104-15 {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  #div_block-111-15 {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  #div_block-118-15 {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  #div_block-125-15 {
    max-width: 100%;
  }
}
@media (max-width: 767px) {
  #div_block-30-15 {
    width: 12rem;
  }
}
@media (max-width: 767px) {
  #div_block-31-15 {
    flex-direction: column;
    display: flex;
    gap: 1rem;
  }
}
@media (max-width: 767px) {
  #div_block-22-15 {
    margin-top: 2rem;
    text-align: left;
    flex-direction: column;
    display: flex;
  }
}
#text_block-42-15 {
  width: 50%;
}
#text_block-53-15 {
  width: 60%;
}
#text_block-131-15 {
  width: 60%;
}
#text_block-92-15 {
  width: 60%;
}
#text_block-101-15 {
  width: 70%;
}
#text_block-106-15 {
  width: 60%;
}
#text_block-113-15 {
  width: 70%;
}
#text_block-120-15 {
  width: 60%;
}
#text_block-127-15 {
  width: 70%;
}
#text_block-76-15 {
  color: rgba(255, 255, 255, 0.7);
  display: none;
}
#text_block-70-15 {
  color: rgba(255, 255, 255, 0.7);
}
#text_block-18-15 {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}
@media (max-width: 991px) {
  #text_block-76-15 {
    border-bottom-color: rgba(255, 255, 255, 0.1);
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
}
@media (max-width: 767px) {
  #text_block-42-15 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  #text_block-53-15 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  #text_block-131-15 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  #text_block-92-15 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  #text_block-101-15 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  #text_block-106-15 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  #text_block-113-15 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  #text_block-120-15 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  #text_block-127-15 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  #text_block-76-15 {
    display: flex;
  }
}
@media (max-width: 767px) {
  #text_block-70-15 {
    display: none;
  }
}
#text_block-24-15 {
  font-weight: 400;
  min-width: 13rem;
}
#link_text-26-15 {
  font-weight: 400;
  min-width: 18rem;
}
#image-4-15 {
  width: 19.5rem;
}
#image-44-15 {
  height: 4.5rem;
}
#image-45-15 {
  height: 4.5rem;
}
#image-32-15 {
  height: 4.5rem;
}
#image-33-15 {
  height: 4.5rem;
}
#mobile-nav-icon {
  display: none;
}
#mobile-nav-close-icon {
  display: none;
}
#menu-items-wrapper {
  height: 100%;
  color: #44554c;
}
#code_block-79-15 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#mobile-nav {
  height: 100%;
  color: #44554c;
  display: none;
}
#code_block-36-15 {
  width: 100%;
  unwrap: true;
}
@media (max-width: 1470px) {
  #menu-items-wrapper {
    display: none;
  }
}
@media (max-width: 1470px) {
  #mobile-nav {
    display: none;
  }
}
@media (max-width: 991px) {
  #mobile-nav-icon {
    display: flex;
  }
}
@media (max-width: 991px) {
  #mobile-nav-close-icon {
    display: none;
  }
}
@media (max-width: 991px) {
  #menu-items-wrapper {
    display: none;
  }
}
@media (max-width: 991px) {
  #mobile-nav {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  #menu-items-wrapper {
    display: none;
  }
}
@media (max-width: 479px) {
  #mobile-nav-icon {
    padding-left: 0px;
  }
}
div.ct-section-inner-wrap,
div.oxy-header-container {
  max-width: 1470px;
}

#section-2-33 {
  background-color: #eeeeee;
}
#section-10-33 > .ct-section-inner-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#section-10-33 {
  text-align: left;
}
#section-60-33 > .ct-section-inner-wrap {
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#section-60-33 {
  text-align: left;
}
#section-169-33 > .ct-section-inner-wrap {
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#section-169-33 {
  text-align: left;
}
#section-186-33 > .ct-section-inner-wrap {
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#section-186-33 {
  text-align: left;
}
#section-207-33 > .ct-section-inner-wrap {
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#section-207-33 {
  text-align: left;
}
#section-243-33 > .ct-section-inner-wrap {
  padding-top: 0;
  align-items: center;
}
#section-243-33 {
  text-align: left;
}
#section-305-33 > .ct-section-inner-wrap {
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#section-305-33 {
  text-align: left;
}
@media (max-width: 767px) {
  #section-60-33 > .ct-section-inner-wrap {
    padding-bottom: 4rem;
  }
}
@media (max-width: 767px) {
  #section-169-33 > .ct-section-inner-wrap {
    padding-bottom: 4rem;
  }
}
@media (max-width: 767px) {
  #section-186-33 > .ct-section-inner-wrap {
    padding-bottom: 4rem;
  }
}
#div_block-3-33 {
  width: 145.7rem;
  max-width: 100%;
  flex-direction: column;
  display: flex;
}
#div_block-11-33 {
  width: 145.7rem;
  max-width: 100%;
}
#div_block-37-33 {
  position: relative;
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
}
#div_block-61-33 {
  width: 145.7rem;
  max-width: 100%;
}
#div_block-62-33 {
  width: 145.7rem;
  max-width: 100%;
}
#div_block-66-33 {
  padding-left: 65rem;
  position: relative;
  justify-content: center;
  text-align: center;
  align-items: flex-start;
  width: 135rem;
  max-width: 100%;
}
#div_block-67-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  width: 100%;
  align-items: stretch;
  position: static;
}
#div_block-68-33 {
  width: 100%;
  flex-direction: column;
  display: flex;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-right: 3rem;
  padding-left: 3rem;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}
#div_block-71-33 {
  align-items: flex-start;
  text-align: left;
  flex-direction: column;
  display: flex;
}
#div_block-129-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  width: 100%;
  align-items: stretch;
  position: static;
}
#div_block-130-33 {
  width: 100%;
  flex-direction: column;
  display: flex;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-right: 3rem;
  padding-left: 3rem;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}
#div_block-133-33 {
  align-items: flex-start;
  text-align: left;
  flex-direction: column;
  display: flex;
}
#div_block-137-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  width: 100%;
  align-items: stretch;
  position: static;
}
#div_block-138-33 {
  width: 100%;
  flex-direction: column;
  display: flex;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-right: 3rem;
  padding-left: 3rem;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}
#div_block-141-33 {
  align-items: flex-start;
  text-align: left;
  flex-direction: column;
  display: flex;
}
#div_block-145-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  width: 100%;
  align-items: stretch;
  position: static;
}
#div_block-146-33 {
  width: 100%;
  flex-direction: column;
  display: flex;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-right: 3rem;
  padding-left: 3rem;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}
#div_block-149-33 {
  align-items: flex-start;
  text-align: left;
  flex-direction: column;
  display: flex;
}
#div_block-153-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  width: 100%;
  align-items: stretch;
  position: static;
}
#div_block-154-33 {
  width: 100%;
  flex-direction: column;
  display: flex;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-right: 3rem;
  padding-left: 3rem;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}
#div_block-157-33 {
  align-items: flex-start;
  text-align: left;
  flex-direction: column;
  display: flex;
}
#div_block-161-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  width: 100%;
  align-items: stretch;
  position: static;
}
#div_block-162-33 {
  width: 100%;
  flex-direction: column;
  display: flex;
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-right: 3rem;
  padding-left: 3rem;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}
#div_block-165-33 {
  align-items: flex-start;
  text-align: left;
  flex-direction: column;
  display: flex;
}
#div_block-170-33 {
  width: 145.7rem;
  max-width: 100%;
}
#div_block-177-33 {
  position: relative;
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: center;
}
#div_block-187-33 {
  width: 145.7rem;
  max-width: 100%;
}
#div_block-202-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  gap: 2.4rem;
}
#div_block-208-33 {
  width: 145.7rem;
  max-width: 100%;
}
#div_block-244-33 {
  width: 145.7rem;
  max-width: 100%;
}
#div_block-245-33 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: flex-start;
}
#div_block-254-33 {
  padding-left: 4rem;
  padding-bottom: 4rem;
  padding-top: 4rem;
  padding-right: 4rem;
}
#div_block-258-33 {
  grid-auto-rows: minmax(min-content, 1fr);
}
#div_block-259-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  align-items: center;
}
#div_block-262-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  align-items: center;
}
#div_block-265-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  align-items: center;
}
#div_block-268-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  align-items: center;
}
#div_block-271-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  align-items: center;
}
#div_block-274-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  align-items: center;
}
#div_block-278-33 {
  padding-left: 4rem;
  padding-bottom: 4rem;
  padding-top: 4rem;
  padding-right: 4rem;
  border-radius: 25px;
  background-color: #e7faf3;
}
#div_block-283-33 {
  grid-auto-rows: minmax(min-content, 1fr);
}
#div_block-284-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  align-items: center;
}
#div_block-287-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  align-items: center;
}
#div_block-290-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  align-items: center;
}
#div_block-293-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  align-items: center;
}
#div_block-296-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  align-items: center;
}
#div_block-299-33 {
  text-align: left;
  flex-direction: row;
  display: flex;
  align-items: center;
}
#div_block-306-33 {
  width: 145.7rem;
  max-width: 100%;
}
#div_block-312-33 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: flex-end;
  margin-top: 45px;
  position: absolute;
  bottom: 30px;
  right: 30px;
}
#div_block-317-33 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: flex-end;
  margin-top: 45px;
  position: absolute;
  bottom: 30px;
  right: 30px;
}
#div_block-322-33 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: flex-end;
  margin-top: 45px;
  position: absolute;
  bottom: 30px;
  right: 30px;
}
#div_block-335-33 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: flex-end;
  margin-top: 45px;
  position: absolute;
  bottom: 30px;
  right: 30px;
}
#div_block-340-33 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: flex-end;
  margin-top: 45px;
  position: absolute;
  bottom: 30px;
  right: 30px;
}
#div_block-345-33 {
  flex-direction: column;
  display: flex;
  text-align: left;
  align-items: flex-end;
  margin-top: 45px;
  position: absolute;
  bottom: 30px;
  right: 30px;
}
@media (max-width: 1366px) {
  #div_block-66-33 {
    padding-left: 65rem;
  }
}
@media (max-width: 1366px) {
  #div_block-177-33 {
    padding-bottom: 6rem;
    padding-top: 6rem;
    padding-right: 6rem;
    padding-left: 6rem;
  }
}
@media (max-width: 991px) {
  #div_block-66-33 {
    padding-left: 0rem;
  }
}
@media (max-width: 991px) {
  #div_block-188-33 {
    margin-top: 4rem;
  }
}
@media (max-width: 767px) {
  #div_block-12-33 {
    margin-top: 4rem;
  }
}
@media (max-width: 767px) {
  #div_block-37-33 {
    text-align: center;
  }
}
@media (max-width: 767px) {
  #div_block-62-33 {
    display: flex;
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 767px) {
  #div_block-177-33 {
    padding-bottom: 4rem;
    text-align: center;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-top: 0rem;
  }
}
@media (max-width: 767px) {
  #div_block-188-33 {
    margin-top: 0px;
    margin-bottom: 4rem;
  }
}
@media (max-width: 767px) {
  #div_block-202-33 {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 767px) {
  #div_block-231-33 {
    margin-top: 4rem;
  }
}
@media (max-width: 767px) {
  #div_block-235-33 {
    margin-top: 4rem;
  }
}
@media (max-width: 767px) {
  #div_block-309c-33 {
    padding-bottom: 12rem;
  }
}
@media (max-width: 767px) {
  #div_block-314-33 {
    padding-bottom: 12rem;
  }
}
@media (max-width: 767px) {
  #div_block-319-33 {
    padding-bottom: 12rem;
  }
}
@media (max-width: 767px) {
  #div_block-332-33 {
    padding-bottom: 12rem;
  }
}
@media (max-width: 767px) {
  #div_block-337-33 {
    padding-bottom: 12rem;
  }
}
@media (max-width: 767px) {
  #div_block-342-33 {
    padding-bottom: 12rem;
  }
}
@media (max-width: 479px) {
  #div_block-3-33 {
    padding-bottom: 2.4rem;
    padding-top: 2.4rem;
    padding-right: 0px;
    padding-left: 0px;
  }
}
@media (max-width: 479px) {
  #div_block-188-33 {
    padding-right: 0px;
    padding-left: 0px;
  }
}
#headline-4-33 {
  usecustomtag: true;
}
#headline-172-33 {
  usecustomtag: true;
}
#headline-180-33 {
  usecustomtag: false;
}
#headline-191-33 {
  usecustomtag: true;
}
#headline-192-33 {
  usecustomtag: false;
}
#headline-246-33 {
  margin-bottom: 0px;
}
#text_block-5-33 {
  usecustomtag: true;
}
#text_block-313-33 {
  width: 8rem;
  max-width: 8rem;
  height: 8rem;
  max-height: 8rem;
}
#text_block-318-33 {
  width: 8rem;
  max-width: 8rem;
  height: 8rem;
  max-height: 8rem;
}
#text_block-323-33 {
  width: 8rem;
  max-width: 8rem;
  height: 8rem;
  max-height: 8rem;
}
#text_block-336-33 {
  width: 8rem;
  max-width: 8rem;
  height: 8rem;
  max-height: 8rem;
}
#text_block-341-33 {
  width: 8rem;
  max-width: 8rem;
  height: 8rem;
  max-height: 8rem;
}
#text_block-346-33 {
  width: 8rem;
  max-width: 8rem;
  height: 8rem;
  max-height: 8rem;
}
@media (max-width: 991px) {
  #text_block-6-33 {
    width: 100%;
  }
}
@media (max-width: 767px) {
  #text_block-64-33 {
    text-align: left;
  }
}
@media (max-width: 767px) {
  #text_block-348-33 {
    text-align: left;
  }
}
@media (max-width: 767px) {
  #text_block-307-33 {
    text-align: left;
  }
}
@media (max-width: 479px) {
  #text_block-5-33 {
    font-size: 3rem;
  }
}
#link_text-184-33 {
  margin-top: 6rem;
}
#link_text-277-33 {
  width: 100%;
  margin-top: 50px;
}
#link_text-302-33 {
  width: 100%;
  margin-top: 50px;
}
@media (max-width: 767px) {
  #link_text-184-33 {
    margin-left: auto;
    margin-right: auto;
  }
}
#image-38-33 {
  object-fit: initial;
}
#image-74-33 {
  object-fit: cover;
  z-index: 2;
  position: absolute;
  left: 0px;
  width: 65rem;
  top: 0px;
  bottom: 0px;
  display: flex;
  opacity: 0;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: all;
  margin-top: auto;
  margin-bottom: auto;
}
#image-136-33 {
  object-fit: cover;
  z-index: 2;
  position: absolute;
  left: 0px;
  width: 65rem;
  top: 0px;
  bottom: 0px;
  display: flex;
  opacity: 0;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: all;
  margin-top: auto;
  margin-bottom: auto;
}
#image-144-33 {
  object-fit: cover;
  z-index: 2;
  position: absolute;
  left: 0px;
  width: 65rem;
  top: 0px;
  bottom: 0px;
  display: flex;
  opacity: 0;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: all;
  margin-top: auto;
  margin-bottom: auto;
}
#image-152-33 {
  object-fit: cover;
  z-index: 2;
  position: absolute;
  left: 0px;
  width: 65rem;
  top: 0px;
  bottom: 0px;
  display: flex;
  opacity: 0;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: all;
  margin-top: auto;
  margin-bottom: auto;
}
#image-160-33 {
  object-fit: cover;
  z-index: 2;
  position: absolute;
  left: 0px;
  width: 65rem;
  top: 0px;
  bottom: 0px;
  display: flex;
  opacity: 0;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-property: all;
  margin-top: auto;
  margin-bottom: auto;
}
#image-201-33 {
  object-fit: scale-down;
}
#image-204-33 {
  height: 4.5rem;
}
#image-206-33 {
  height: 4.5rem;
}
#image-210-33 {
  width: 100%;
}
#image-232-33 {
  width: 100%;
}
#image-236-33 {
  width: 100%;
}
#code_block-347-33 {
  position: absolute;
  bottom: -8rem;
}
#code_block-42-33 {
  margin-right: 20px;
}
#code_block-45-33 {
  margin-right: 20px;
}
#code_block-48-33 {
  margin-right: 20px;
}
#code_block-260-33 {
  margin-right: 20px;
}
#code_block-263-33 {
  margin-right: 20px;
}
#code_block-266-33 {
  margin-right: 20px;
}
#code_block-269-33 {
  margin-right: 20px;
}
#code_block-272-33 {
  margin-right: 20px;
}
#code_block-275-33 {
  margin-right: 20px;
}
#code_block-285-33 {
  margin-right: 20px;
}
#code_block-288-33 {
  margin-right: 20px;
}
#code_block-291-33 {
  margin-right: 20px;
}
#code_block-294-33 {
  margin-right: 20px;
}
#code_block-297-33 {
  margin-right: 20px;
}
#code_block-300-33 {
  margin-right: 20px;
}
@media (max-width: 767px) {
  #span-65-33 {
    text-align: left;
  }
}
@media (max-width: 767px) {
  #span-349-33 {
    text-align: left;
  }
}
div.ct-section-inner-wrap,
div.oxy-header-container {
  max-width: 1398px;
}

#image-233-9 {
  width: 100%;
  max-width: 22.5rem;
  max-height: 22.5rem;
  object-fit: cover;
  border-top-width: 10px;
  border-right-width: 10px;
  border-bottom-width: 10px;
  border-left-width: 10px;
  border-top-color: #f4f4f4;
  border-right-color: #f4f4f4;
  border-bottom-color: #f4f4f4;
  border-left-color: #f4f4f4;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-radius: 99rem;
}

@media (min-width: 1921px) {
  #image-223-9 {
    max-width: 19.5rem;
    max-height: 19.5rem;
  }
}


#text_block-222-9 {
  width: 65%;
}

@media (max-width: 767px) {
  #text_block-222-9 {
    width: 100%;
  }
}

@media (max-width: 600px) {
  #text_block-222-9 {
    width: 100%;
  }
}

