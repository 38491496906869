@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Rubik";
}

.foodiary-menu {
  height: 10rem;
  .foodiary-menu-trigger {
    transition: all ease-in-out 0.3s;
    z-index: 9999;
    position: relative;
    // font-weight: 400;
    // font-size: 1rem;
    cursor: pointer;
    // margin: 1rem 1.3rem 0;
    color: #44554c;
    &:hover::after {
      content: "";
      height: 0.3rem;
      width: 3rem;
      margin-inline: auto;
      background-color: #a5b5af;
      border-radius: 0.13rem;
      position: absolute;
      bottom: 1.9rem;
      left: calc(50% - 0rem);
      transition: all ease-in-out 0.3s;
    }

    &.active {
      color: #142c1f;
      &:hover::after {
        background-color: #2dcc95;
      }
    }
  }

  &:hover {
    .foodiary-menu-trigger {
      color: #000000;
    }
  }
  &:not(:hover) {
    .foodiary-menu-box {
      display: none;
    }
  }
}

.foodiary-menu-box {
  transition: all ease-in-out 0.3s;
  animation: fadeEffect 1s;
  position: fixed;
  top: 10rem;
  width: 100%;
  max-width: 29rem;
  z-index: 1999;
  .foodiary-menu-box-child {
    z-index: 1999;
    background-color: white;
    padding: 3rem;
    margin-top: 4rem;
    margin-inline: auto;
    border-radius: 0 0 0.9rem 0.9rem;
    box-shadow: #142c1f14 0rem 0.5rem 1rem;
    box-sizing: border-box;
  }

  .menu-item-link {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    display: block;
    color: #44554c;
    // font-weight: 400;
    // font-size: 1rem;
    position: relative;
    text-decoration: none;
    // font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
    //   Roboto, "Helvetica Neue", Arial, sans-serif;
    font-family: "Rubik";
    &:hover::before {
      content: "";
      height: 90%;
      width: 0.2rem;
      background-color: #a5b5af;
      border-radius: 0.13rem;
      position: absolute;
      top: 5%;
      left: -1.33rem;
    }

    &::after {
      display: none;
    }
  }
}

.menu-item-button-wrap {
  padding-top: 1.2rem;
  border-top: 1px solid #dddddd;
}

.btn-menu-outline {
  border: 2px solid #25a08b;
  padding: 10px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #25a08b;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;
  transition: all 0.2s ease-in-out;
  background-color: #ffffff;

  &:hover {
    background-color: #25a08b;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
}

.hook-block {
  position: absolute;
  bottom: -160px;
  left: 50%;
  translate: -50% 0;
  @media (max-width: 600px) {
    bottom: -140px;
  }
}

.btn-primary {
  background: linear-gradient(99deg, #2ecf96 0%, #1c9d87 100%);
  border-radius: 5px;
  color: white;
  border: none;


  &:hover {
    transform: translateY(-2px);
    background: linear-gradient(99deg, #21d193 0%, #15937e 100%);
  }
}

.btn-outline {
  border: 1px solid #1c9d87;
  background-color: white;
  border-radius: 5px;
  color: #1c9d87;
  &:hover {
    transform: translateY(-2px);
  }
}

.btn-secondary {
  background: #f8a492;
  border: 1px solid #f8a492;
  border-radius: 5px;
  color: white;
  &:hover {
    transform: translateY(-2px);
    background: #f8957f;
    border: 1px solid #f8957f;
  }
}

.btn-primary,
.btn-outline,
.btn-secondary,
.btn-load-more {
  padding: 0.8rem 1.5rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
   
  font-size: 16px;
  transition: all 0.34s ease;
  // @media (max-width: 600px) {
  //   font-size: 17px !important;
  //   line-height: 18px !important;
  // }

  &:hover {
    transform: translateY(-2px);
  }
}

.btn-sm {
  @media screen and (max-width: 600px) {
    padding: 0.6rem 1rem;
    font-size: 14px;
  }
}

.user-slider,
.generic-slider {
  .slick-list {
    margin: 0px -25px;
    padding-top: 60px;
    @media (max-width: 767.99px) {
      margin: 0;
    }

    .slick-slide {
      height: 0;
      &.slick-active {
        height: auto;
      }
    }
    .slick-item {
      position: relative;
      .sliderInner {
        margin: 0 25px;
        padding: 60px 50px 30px;
        background: #f0f4f3;
        border-radius: 20px;

        @media (max-width: 767px) {
          margin: 0 auto;
          width: 95%;
          padding: 60px 20px 28px;
        }
      }
    }
  }
  .slick-prev,
  .slick-next {
    width: 50px;
    height: 50px;
    top: -90px !important;
    @media (max-width: 767px) {
      top: unset !important;
      bottom: -30px;
    }

    color: #a5b5af !important;
    &:hover {
      color: #2ecf96 !important;
    }
  }
  .slick-prev {
    right: 65px !important;
    left: unset !important;
    @media (max-width: 767px) {
      right: unset !important;
      left: 50% !important;
      margin-left: -55px;
    }
  }
  .slick-next {
    right: 0;
    @media (max-width: 767px) {
      right: 50% !important;
      margin-right: -55px;
    }
  }
}

.custum-btn {
  width: 247px !important;
  @media (max-width: 600px) {
    width: 100% !important;
    font-size: 15px !important;
  }
}

.largeBtn {
  width: 500px !important;
  font-size: 20px;
  height: 54px;
  margin: 60px auto 0;
  @media (max-width: 600px) {
    width: 100% !important;
    font-size: 15px;
    height: 42px;
    line-height: 18px;
    margin-top: 60px;
  }
}

.grid-view {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;

  @media (min-width: 992px) and (max-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 768px) and (max-width: 991.99px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 767.99px) {
    grid-template-columns: 1fr;
  }

  &.cards-view {
    @media (max-width: 600px) {
      gap: 0.6rem;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.dropdown {
  position: relative;
  width: fit-content;
  .dropdown-top,
  .dropdown-menu {
    padding: 1rem;
  }

  .dropdown-top {
    width: fit-content;
    gap: 0.4rem;
    cursor: pointer;

    .title {
      font-size: 1.3rem;
    }
    .icon {
      svg {
        position: relative;
        top: 0.15rem;
      }
    }
    &.open {
      box-shadow: 1px -3px 12px #9c9a9a47;
      background-color: white;
    }

    @media screen and (max-width: 600px) {
      .title {
        font-size: 1rem;
      }
      .icon {
        svg {
          top: 0.15rem;
        }
      }
    }
  }

  .dropdown-menu {
    background-color: white;
    max-width: 400px;
    z-index: 999;
    width: 400px;
    box-shadow: 1px 4px 5px 0px #b0aeae4d;
    gap: 1rem;
    position: absolute;
    top: 3.5rem;
    left: 0;

    @media screen and (max-width: 600px) {
      max-width: 100%;
    }
  }
}

.chip-item {
  background: #2ecf96;
  border: 1px solid #2ecf96;
  border-radius: 22px;
  color: white;
  padding: 1rem;
  height: fit-content;
  width: fit-content;
  padding: 0.4rem 1rem;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    font-size: 0.8rem;
    padding: 0.5rem 0.7rem;
  }
}

.pagination-nav {
  .pagination {
    display: inline-block;
    list-style: none;
    padding: 0;

    * {
       
    }

    li {
      display: inline-block;
      padding: 0.8rem;

      span {
        padding: 0;
        font-size: 1.2rem;
        text-decoration: none;
        color: #000000;
        cursor: pointer;
        &:hover,
        &:active {
          color: #1c9d87;
        }

        &.active-page {
          color: #1c9d87;
          font-weight: 700;
        }
      }
      svg {
        color: #1c9d87;
        cursor: pointer;
        width: 2rem;
        height: 2rem;
        position: relative;
        top: 0.7rem;
      }
    }
  }
}

.header {
  margin-block: 4rem;
  span {
    font-weight: 300;
    @media screen and (max-width: 600px) {
      display: block;
    }
  }
  @media screen and (max-width: 600px) {
    margin-top: 1rem;
  }
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.align-items-end {
  align-items: end;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-between {
  justify-content: space-between;
}

.flex-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.gap-3 {
  gap: 1rem;
}

.gap-4 {
  gap: 1.5rem;
}

.gap-5 {
  gap: 2rem;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mx-0 {
  margin-inline: 0 !important;
}

.my-0 {
  margin-block: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.px-0 {
  padding-inline: 0 !important;
}

.py-0 {
  padding-block: 0 !important;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.text-center {
  text-align: center;
}

.custum-btn {
  width: 247px !important;
  @media (max-width: 600px) {
    width: 100% !important;
    font-size: 15px !important;
  }
}

.center-btn {
  width: 247px !important;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 600px) {
    width: 100% !important;
    font-size: 15px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.largeBtn {
  width: 500px !important;
  font-size: 20px;
  height: 54px;
  margin: 60px auto 0;
  @media (max-width: 600px) {
    width: 100% !important;
    font-size: 15px;
    height: 42px;
    line-height: 18px;
    margin-top: 60px;
  }
}

.priceSlider {
  // .slick-slide {
  //   padding: 10px;
  //   width: 100% !important;
  // }
}

.cardSlider {
  .slick-dots {
    bottom: 25px;
    li {
      width: 12px;
      height: 12px;
      button {
        width: 12px;
        height: 12px;
        background: #7a8780;
        border-radius: 50%;
        &:before {
          display: none;
        }
      }
      &.slick-active {
        width: 18px;
        height: 18px;
        border: 2px solid #2ecf96;
        top: -1px;
        border-radius: 50%;
        button {
          background: transparent;
        }
      }
    }
  }
}

.home-page {
  // padding-top: 10rem;
}

.bg-white {
  background-color: white !important;
}

.bg-curve {
  background: url(../assets/outer-pages/svg/White-Background-Coach-finden.svg);
  background-position: top center;
  background-size: cover;
}

.header-wrap {
  // padding: 187.8px 0 96px;
  height: 559px;
  background: transparent linear-gradient(90deg, #eeeeee 0%, #f8f8f8 100%);
  position: relative;
  .scroll-icon {
    position: absolute;
    bottom: -6.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 12.6rem;
    height: 12.6rem;
    // display: block;
    // margin-inline: auto;
    // margin-bottom: -2rem;
  }
  @media screen and (min-width: 1800px) {
    // height: 829px;
    height: 699px;
  }

  @media screen and (max-width: 767.99px) {
    padding: 10rem 0 6rem;
    height: 412px !important;
  }
}

.couch-page {
  .header-wrap {
    margin-bottom: 6rem;
    height: auto !important;
    background: transparent;
    .bg-gray {
      background: transparent linear-gradient(90deg, #eeeeee 0%, #f8f8f8 100%);
      height: 459px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: -1;
    }
    @media screen and (max-width: 767.99px) {
      .bg-gray {
        height: 359px;
      }
      margin-bottom: 0;
    }
  }
}

.badge {
  background: white;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 42px;
  letter-spacing: 0.32px;
  color: #1c9d87;
  text-transform: uppercase;
  font-weight: 600;
  margin-inline: auto;
}

.header-details {
  padding-top: 170px;
  @media screen and (max-width: 767.99px) {
    padding-top: 10rem;
  }
}

.border-box {
  box-sizing: border-box;
}

.header {
  // padding: 12rem 0 0;
  h1 {
    color: #142c1f;
    // font-size: 47.6px;
    // line-height: 60px;
    // font-weight: 700;
    // margin-bottom: 38.4;
    // margin-top: 25.6px;
  }

  p {
    // font-size: 13.6px;
    // line-height: 18px;
  }

  @media screen and (max-width: 767.99px) {
    padding: 0;
    h1 {
      // font-size: 30px;
      // line-height: 36px;
      // margin-top: 22.4px;
      // margin-bottom: 33.6px;
    }

    p {
      // font-size: 15px;
      // line-height: 22px;
    }

    .badge {
      // width: 152px;
      // height: 41px;
      // font-size: 15px;
    }
  }

  @media screen and (max-width: 600px) {
    max-width: 308px;
    margin-inline: auto;
  }
}

.pagination-nav {
  .pagination {
    display: flex;
    justify-content: end;
    align-items: center;
    list-style: none;
    padding: 0;
    li {
      display: inline-block;
      padding: 1.3rem;

      span {
        padding: 0;
        font-size: 2.2rem;
        width: 35px;
        height: 35px;
        border: none;

        text-decoration: none;
        color: #44554c;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover,
        &:active {
          // color: #2ecf96;
          background-color: transparent;
        }

        &.active-page {
          color: #ffffff;
          font-weight: 700;
          background: #2ecf96;
          box-shadow: 0px 15px 30px #02332a1a;
          border-radius: 20px;
          svg {
            // color: #44554C;
          }
        }
      }
      svg {
        // color: #A5B5AF;
        color: #44554c;
        cursor: pointer;
        width: 23px;
        height: 23px;
        // position: relative;
        // top: 1.3rem;
      }
    }
  }
}

// ============================================

.foodiary-mega-menu {
  .foodiary-mega-menu-trigger {
    transition: all ease-in-out 0.3s;
    z-index: 9999;
    position: relative;
    // color: white;
    font-weight: 600;
    font-size: 1.3rem;
    cursor: pointer;
  }

  &:hover {
    .foodiary-mega-menu-trigger {
      color: #000000;
    }
  }
  &:not(:hover) {
    .foodiary-menu-wrapper {
      display: none;
    }
  }
}

.foodiary-menu-wrapper {
  transition: all ease-in-out 0.3s;
  animation: fadeEffect 1s;
  position: fixed;
  top: 30px;
  left: 0;
  width: calc(100% - 0px);
  z-index: 1999;

  .foodiary-menu-wrapper-child {
    z-index: 1999;
    background-color: white;
    padding: 24px;
    margin-top: 40px;
    margin-inline: auto;
    width: calc(100% - 10px);
    border-radius: 13px;
    box-sizing: border-box;
    box-shadow: 0px 4000px 500px 4000px rgba(0, 0, 0, 0.2);
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 5vw;
  }

  .col-heading {
    font-weight: 800;
    color: #142c1f;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 1.3rem;
  }
  .menu-item-link {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    a {
      // font-weight: 400;
      color: #363636;
      // font-size: 17px;
      text-decoration: none;
      font-weight: "Rubik";
      // font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
      //   Roboto, "Helvetica Neue", Arial, sans-serif;

      &:hover {
        color: #199c86;
        text-decoration: underline;
      }
    }
  }
}

.foodiary-header-sticky {
  .foodiary-menu-wrapper-child {
    margin-top: 40px;
  }
}

.menu-cols {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  .column {
    margin-bottom: 1.3rem;
  }

  @media (min-width: 768px) and (max-width: 991.99px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 767.99px) {
    grid-template-columns: 1fr 1fr;
  }
}

.app-menu-cols-wrap {
  .right-col {
    width: 35%;
    img {
      max-width: 300px;
      margin-top: 50px;
      margin-left: 2rem;
    }
  }
  .left-col {
    width: 65%;
  }
}
.app-menu-cols {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  .column {
    margin-bottom: 1.3rem;
  }
}
.coach-menu-cols {
  gap: 1rem;

  .column {
    width: 100%;
    max-width: 300px;

    &.img {
      margin-top: -20px;
      max-width: 280px;
      max-height: 325px;
    }
  }
}

.mobile-menu {
  position: fixed;
  background-color: #fff;
  width: 100vw;
  height: calc(100vh - 70px);
  left: 0;
  top: 70px;
  z-index: 9999999999999 !important;
  display: block;

  .mobile-menu-header {
    position: relative;
    padding-bottom: 30px;

    .close-mobile-menu-icon {
      position: absolute;
      top: 18px;
      right: 18px;
      width: 30px;
      height: 30px;
    }

    .menu-header-items {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 70px;
      .menu-header-item {
        cursor: pointer;
        padding: 14px 16px;
        transition: all ease-in-out 0.3s;
        border-bottom: 2px solid #fff;
        font-size: 20px;

        &.active {
          border-bottom: 2px solid #223459;
          font-weight: 700;
        }
      }
    }
  }

  .mobile-menu-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 200px);
    padding: 30px;

    .menu-body-block {
      .column-mobile {
        margin-bottom: 3rem;

        .col-heading-mobile {
          font-weight: 800;
          color: #142c1f;
          font-size: 17px;
          text-transform: uppercase;
          letter-spacing: 1px;
        }

        .menu-item-link {
          &:not(:last-child) {
            margin-bottom: 1rem;
          }
          a {
            // font-weight: 400;
            // font-size: 17px;
            color: #363636;
            text-decoration: none;
            font-family: -apple-system, system-ui, BlinkMacSystemFont,
              "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;

            &:hover {
              color: #199c86;
              text-decoration: underline;
            }
          }
        }
      }
    }

    .mobile-menu-banner {
      width: 100%;
      height: 150px;
      margin-bottom: 30px;
    }
  }
}

.after-links {
  a::after {
    content: "";
    width: auto;
    position: relative;
    height: 100%;
    border-left: 1px solid #d2d2d2;
    right: -8px;
  }
}

// ======================= Header End =============================

.pagination-nav {
  .pagination {
    display: flex;
    justify-content: end;
    align-items: center;
    list-style: none;
    padding: 0;
    li {
      display: inline-block;
      padding: 1.3rem;

      span {
        padding: 0;
        font-size: 2.2rem;
        width: 35px;
        height: 35px;
        border: none;

        text-decoration: none;
        color: #44554c;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover,
        &:active {
          // color: #2ecf96;
          background-color: transparent;
        }

        &.active-page {
          color: #ffffff;
          font-weight: 700;
          background: #2ecf96;
          box-shadow: 0px 15px 30px #02332a1a;
          border-radius: 20px;
          svg {
            // color: #44554C;
          }
        }
      }
      svg {
        // color: #A5B5AF;
        color: #44554c;
        cursor: pointer;
        width: 23px;
        height: 23px;
        // position: relative;
        // top: 1.3rem;
      }
    }
  }
}

.container {
  max-width: 95%;
  margin-inline: auto;

  @media (min-width: 992px) {
    max-width: 85%;
  }

  @media (min-width: 1200px) {
    max-width: 80%;
  }
}

.slick-arrow {
  color: #2ecf96;
  width: 44px !important;
  height: 44px !important;
  z-index: 999;

  &:hover {
    color: #2ecf96;
  }
}

.slick-track {
  // display: flex !important;
  // background: #223459;
}

.user-slider {
  .slick-slider {
    padding-bottom: 0 !important;
  }
  .slick-prev {
    left: -55px;
  }

  .slick-next {
    right: -55px;
  }

  .slick-slide {
    height: 0;
    &.slick-active {
      height: max-content;
    }
  }

  @media (max-width: 991.99px) {
    .slick-prev,
    .slick-next {
      top: unset !important;
      bottom: 0;
      // top: calc(100% + 2rem);
    }
    .slick-prev {
      left: calc(50% - 50px);
    }

    .slick-next {
      right: calc(50% - 50px);
    }
  }
}

.d-grid {
  display: grid !important;
}

.grid-view {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 2rem;

  &.cards-view {
    grid-template-columns: 1fr 1fr 1fr;
    @media (max-width: 768px) {
      gap: 1rem;
      grid-template-columns: 1fr 1fr;
    }
  }

  @media (min-width: 992px) and (max-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 768px) and (max-width: 991.99px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 767.99px) {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  // @media (max-width: 460px) {
  //   grid-template-columns: 1fr;
  // }
}

.dropdown {
  position: relative;
  width: fit-content;
  .dropdown-top,
  .dropdown-menu {
    padding: 1rem;
  }

  .dropdown-top {
    width: fit-content;
    gap: 1.4rem;
    cursor: pointer;

    .title {
      // font-size: 1.3rem;
    }
    .icon {
      svg {
        position: relative;
        top: 0.45rem;
      }
    }
    &.open {
      box-shadow: 1px -3px 12px #9c9a9a47;
      background-color: white;
    }

    @media screen and (max-width: 600px) {
      // .title {
      //   font-size: 1rem;
      // }
      // .icon {
      //   svg {
      //     top: 0.15rem;
      //   }
      // }
    }
  }

  .dropdown-menu {
    background-color: white;
    max-width: 400px;
    z-index: 999;
    width: 400px;
    box-shadow: 1px 4px 5px 0px #b0aeae4d;
    gap: 1rem;
    position: absolute;
    top: 3.5rem;
    left: 0;

    @media screen and (max-width: 600px) {
      max-width: 100%;
    }
  }
}

.chip-item {
  background: #2ecf96;
  border: 1px solid #2ecf96;
  border-radius: 22px;
  color: white;
  padding: 1rem;
  height: fit-content;
  width: fit-content;
  padding: 0.4rem 1rem;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    font-size: 0.8rem;
    padding: 0.5rem 0.7rem;
  }
}

.testimonial-slider {
  .slick-prev {
    left: calc(50% - 50px);
    top: 110%;
  }

  .slick-next {
    right: calc(50% - 50px);
    top: 110%;
  }
}

.btn-android,
.btn-ios {
  background: #000000;
  outline: none;
  border: none;
  border-radius: 8px;
  padding: 0.1rem 0.4rem;
  color: white;
  cursor: pointer;
  text-decoration: none;
  height: 35px;

  img {
    width: 1.2rem !important;
    margin-top: 0 !important;
    margin-right: 0.1rem;
  }

  > div {
    text-align: left;
    small {
      font-size: 10px;
    }
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 12px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 991.99px) {
    img {
      margin-bottom: 0 !important;
    }
  }
}

.navbar-lg {
  height: 500px !important;
}

// Calendaly

.calendly-popup {
  #page-region > div > div {
    margin: 0 !important;
  }
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -2px rgba(0, 0, 0, 0.1);
}

.generic-slider {
  .slick-list {
    margin: 0px -25px;
    padding-top: 75px;

    @media (max-width: 767.99px) {
      margin: 0;
    }

    .slick-slide {
      height: 0;
      &.slick-active {
        height: auto;
      }
    }
    .slick-item {
      position: relative;
      .sliderInner {
        margin: 0 25px;
        padding: 60px 50px 30px;
        background: #f0f4f3;
        border-radius: 20px;

        @media (max-width: 767px) {
          margin: 0 auto;
          width: 95%;
          padding: 60px 20px 28px;
        }
      }
    }
  }
  .slick-prev,
  .slick-next {
    width: 5rem;
    height: 5rem;
    top: -90px !important;
    @media (max-width: 767px) {
      top: unset !important;
      bottom: -50px;
    }

    color: #a5b5af !important;
    &:hover {
      color: #2ecf96 !important;
    }
  }
  .slick-prev {
    right: 65px !important;
    left: unset !important;
    @media (max-width: 767.99px) {
      right: unset !important;
      left: 50% !important;
      margin-left: -55px;
    }
  }
  .slick-next {
    right: 0;
    @media (max-width: 767.99px) {
      right: 50% !important;
      margin-right: -55px;
    }
  }
}

.desktop-show {
  display: block;
  @media (max-width: 600px) {
    display: none;
  }
}

.mobile-show {
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
}

.list-with-filters {
  display: grid;
  grid-template-columns: 260px 1fr;
  gap: 30px;

  @media screen and (max-width: 1100px) {
    grid-template-columns: 1fr;
    gap: 0;
    // .list-filters {
    //   display: none;
    // }
  }
}

.promoted-coaches {
  // width: calc(100% - 360px);
  // margin-left: auto;
  position: relative;
  height: 442.41px;
  .coaches {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  @media screen and (max-width: 991.99px) {
    height: auto;
    .coaches {
      position: unset;
    }
  }

  @media screen and (max-width: 1100px) {
    width: 100%;
  }
}

.promoted-coaches .slick-list {
  margin: 0;
  padding-top: 0;
}

.promoted-coaches .slick-slider {
  padding-bottom: 0 !important;
}

.item-w {
  width: 255.5px;

  @media screen and (max-width: 991.99px) {
    width: 100%;
  }
}

.scroll-snap-horizontal {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  gap: 2rem;

  .scroll-item {
    scroll-snap-align: start;
  }

  @media screen and (max-width: 991.99px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow-x: unset;
    scroll-snap-type: unset;
  }

  @media screen and (max-width: 767.99px) {
    gap: 1rem;
  }

  // @media screen and (max-width: 460px) {
  //   grid-template-columns: 1fr;
  // }
}

.green-card-desktop {
  @media screen and (max-width: 991.99px) {
    display: none;
  }
}
.green-card-mobile {
  margin-bottom: 2rem;

  @media screen and (max-width: 767.99px) {
    margin-bottom: 1rem;
    height: 250px;
  }

  @media screen and (min-width: 992px) {
    display: none;
  }
}

.filter-btn {
  background: #44554c;
  background: #44554c;
  outline: none;
  border: none;
  padding: 5px 10px;
  color: white;
  border-radius: 4px;
  font-size: 16px;
  width: 83px;
}

.listings-section {
  @media screen and (max-width: 1100px) {
    padding-top: var(--s-8) !important;
  }
}
.accordion-button::after {
  display: none;
}
.list-filters {
  .active-filters-title {
    font-size: 16px;
    line-height: 19px;
    color: #a5b5af;
    text-transform: uppercase;
    font-weight: 400;

    @media screen and (max-width: 1100px) {
      display: none;
    }
  }

  .filters-head {
    display: flex;
    align-items: center;
    gap: 16px;
    margin: 15px 0 30px;

    @media screen and (min-width: 1100px) {
      align-items: start;
      flex-direction: column;
      padding-bottom: 20px;
      border-bottom: 1px solid #dddddd;
    }
  }
  .active-filters {
    gap: 10px;

    .active-filter {
      width: fit-content;
      background: #e7faf3;
      border: 1px solid #2ecf96;
      border-radius: 17px;
      color: #2ecf96;
      font-size: 14px;
      line-height: 17px;
      padding: 6px 12px;

      svg {
        margin-left: 10px;
        cursor: pointer;
      }
    }

    @media screen and (max-width: 1099.99px) {
      margin: 0;
      padding-bottom: 0;
      padding-left: 16px;
      border-bottom: none;
      border-left: 1px solid #dddddd;
    }
  }

  .filters-desktop {
    @media screen and (max-width: 1099.99px) {
      display: none;
    }
  }
  .filter-dropdown {
    position: relative;

    @media screen and (min-width: 1100px) {
      display: none;
    }
  }
  .filter-dropdown-menu {
    width: calc(100vw - 40px);
    height: 400px;
    position: absolute;
    left: 0;
    top: 50px;
    background: rgb(255, 255, 255);
    border-radius: 10px;
    z-index: 2;
    padding: 30px;
    overflow-y: auto;
  }

  .accordion-item {
    border: none !important;

    &:not(:first-child) {
      border-top: 1px solid #dddddd !important;
      margin-top: 20px;
      padding-top: 20px;
    }

    h2,
    button {
      padding-left: 0 !important;
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
      font-size: 18px;
      line-height: 22px;
      color: #142c1f;
    }
  }
  .accordion-body {
    padding-top: 14px;
  }

  .form-check-input {
    width: 23px;
    height: 23px;
    border-radius: 0;
  }

  .checkbox-label {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-left: 1.2rem;
    color: #44554c !important;
    position: relative;
    top: -5px;
  }

  .was-validated .form-check-input:valid:checked,
  .form-check-input.is-valid:checked {
    background-color: #2ecf96;
  }
  .was-validated .form-check-input:valid,
  .form-check-input.is-valid {
    border-color: #2ecf96;
  }
  .form-check-input {
    box-shadow: none;
  }
  .form-check-input:checked {
    background-color: #2ecf96;
    border-color: #2ecf96;
  }

  .check-box-row {
    margin-bottom: 15px;
  }
}

.card-green {
  background: #1c9d87;
  border-radius: 8px;
  padding: 4.1875rem 3rem;
  position: relative;
  // min-height: 49rem;

  @media screen and (max-width: 991.99px) {
    background: linear-gradient(115deg, #2ecf96, #1c9d87);
  }

  h4 {
    color: #ffffff;
    font-size: 22px;
    line-height: 34px;
    margin-top: 10px;
    font-weight: 500;
  }

  .action {
    position: absolute;
    right: 25px;
    bottom: 25px;

    svg {
      color: white;
      width: 50px;
      height: 50px;
    }
  }
}

.border-dashed {
  border: 1px dashed #dddddd;
}

.slider-card {
  background: #f0f4f3;
  border-radius: 8px;
}

.chip {
  border-radius: 17px;
  padding: 5px 10px;
  color: white;
  font-weight: 500;
  width: fit-content;
}

.bg-black-chip {
  background: #142c1f;
}

.bg-green-chip {
  background-color: #2ecf96;
}

.start-nutrition {
}

.section-header {
  color: #142c1f;
  font-size: 40px;
  line-height: 60px;
}

.section-description {
  color: #a5b5af;
  font-size: 16px;
  line-height: 25px;
  margin-top: 29px;
}

@media screen and (max-width: 768px) {
  .section-header {
    font-size: 28px;
    line-height: 40px;
  }

  .section-description {
    font-size: 14px;
    line-height: 22px;
  }
}

.main-loader-wrapper {
  height: 500px;

  .spinner {
    height: 5rem;
    width: 5rem;
  }
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overflow-unset {
  overflow: unset !important;
}